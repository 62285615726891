import { Stack, styled, Typography } from '@mui/joy';
import { Colors, FontSizes } from '../../theme';
import HorizontalSpacer from '../HorizontalSpacer';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import useMobileMode from '../../hooks/useMobileMode';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

const CustomTextField = styled(TextField)({
  input: {
    '&.Mui-disabled': {
      color: 'rgb(0,0,0)',
      WebkitTextFillColor: 'rgb(0,0,0)',
    },
  },
});

const SummaryGigTime = () => {
  const location = useLocation();
  const mobileMode = useMobileMode();

  const [arrivalTime, setArrivalTime] = useState<Dayjs | null>(
    dayjs(location?.state?.startDateAndTime ?? DateTime.local().toISO()),
  );

  const [checkoutTime, setCheckoutTime] = useState<Dayjs | null>(
    dayjs(location?.state?.endDateAndTime ?? DateTime.local().toISO()),
  );

  const [isArrivalDateTimePickerOpen, setIsArrivalDateTimePickerOpen] = useState(false);
  const [isCheckoutDateTimePickerOpen, setIsCheckoutDateTimePickerOpen] = useState(false);

  const handleOpenArrivalDateTimePicker = () => setIsArrivalDateTimePickerOpen(true);
  const handleCloseArrivalDateTimePicker = () => setIsArrivalDateTimePickerOpen(false);

  const handleOpenCheckoutDateTimePicker = () => setIsCheckoutDateTimePickerOpen(true);
  const handleCloseCheckoutDateTimePicker = () => setIsCheckoutDateTimePickerOpen(false);

  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (location?.state?.startDateAndTime && location?.state?.endDateAndTime) {
      const startDateAndTime = DateTime.fromISO(location.state.startDateAndTime);
      const endDateAndTime = DateTime.fromISO(location.state.endDateAndTime);

      const updatedStartDateAndTime = DateTime.local().set({
        hour: startDateAndTime.hour,
        minute: startDateAndTime.minute,
        second: 0,
      });

      const updatedEndDateAndTime = DateTime.local().set({
        hour: endDateAndTime.hour,
        minute: endDateAndTime.minute,
        second: 0,
      });

      setArrivalTime(dayjs(updatedStartDateAndTime.toISO()));
      setCheckoutTime(dayjs(updatedEndDateAndTime.toISO()));

      setValue('arrivalDateAndTime', updatedStartDateAndTime.toISO(), {
        shouldValidate: true,
      });

      setValue('checkoutDateAndTime', updatedEndDateAndTime.toISO(), {
        shouldValidate: true,
      });
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        direction="column"
        sx={{
          backgroundColor: Colors.White,
          borderRadius: '16px',
          padding: '16px',
          marginTop: '16px',
        }}
      >
        <Stack direction="row">
          <Typography
            sx={{
              color: Colors.Black,
              ...FontSizes.Header4W700,
              marginBottom: '16px',
              marginTop: '4px',
            }}
          >
            Time
          </Typography>
          <HorizontalSpacer />
        </Stack>

        <Stack direction="row">
          <Stack direction="column" sx={{ marginRight: '32px' }}>
            {mobileMode ? (
              <MobileTimePicker
                label="Arrival time"
                value={arrivalTime}
                onChange={(newValue: dayjs.Dayjs | null) => {
                  setArrivalTime(newValue);
                  setValue('arrivalDateAndTime', newValue?.toISOString() ?? '', {
                    shouldValidate: true,
                  });
                }}
                open={isArrivalDateTimePickerOpen}
                onOpen={handleOpenArrivalDateTimePicker}
                onClose={handleCloseArrivalDateTimePicker}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    disabled
                    onClick={() => {
                      handleCloseCheckoutDateTimePicker();

                      handleOpenArrivalDateTimePicker();
                    }}
                  />
                )}
              />
            ) : (
              <DesktopTimePicker
                label="Arrival time"
                value={arrivalTime}
                onChange={(newValue: dayjs.Dayjs | null) => {
                  setArrivalTime(newValue);
                  setValue('arrivalDateAndTime', newValue?.toISOString() ?? '', {
                    shouldValidate: true,
                  });
                }}
                open={isArrivalDateTimePickerOpen}
                onOpen={handleOpenArrivalDateTimePicker}
                onClose={handleCloseArrivalDateTimePicker}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    disabled
                    onClick={() => {
                      handleCloseCheckoutDateTimePicker();

                      handleOpenArrivalDateTimePicker();
                    }}
                  />
                )}
              />
            )}
            <Typography sx={{ ...FontSizes.SmallW300, color: Colors.Red, marginTop: '8px' }}>
              {(errors?.arrivalDateAndTime?.message as string) ?? ''}
            </Typography>
          </Stack>
          <Stack direction="column">
            {mobileMode ? (
              <MobileTimePicker
                label="Checkout time"
                value={checkoutTime}
                onChange={(newValue) => {
                  setCheckoutTime(newValue);
                  setValue('checkoutDateAndTime', newValue?.toISOString(), {
                    shouldValidate: true,
                  });
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    disabled
                    onClick={() => {
                      handleCloseArrivalDateTimePicker();

                      handleOpenCheckoutDateTimePicker();
                    }}
                  />
                )}
                open={isCheckoutDateTimePickerOpen}
                onOpen={handleOpenCheckoutDateTimePicker}
                onClose={handleCloseCheckoutDateTimePicker}
              />
            ) : (
              <DesktopTimePicker
                label="Checkout time"
                value={checkoutTime}
                onChange={(newValue) => {
                  setCheckoutTime(newValue);
                  setValue('checkoutDateAndTime', newValue?.toISOString() ?? '', {
                    shouldValidate: true,
                  });
                }}
                open={isCheckoutDateTimePickerOpen}
                onOpen={handleOpenCheckoutDateTimePicker}
                onClose={handleCloseCheckoutDateTimePicker}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    disabled
                    onClick={() => {
                      handleCloseArrivalDateTimePicker();

                      handleOpenCheckoutDateTimePicker();
                    }}
                  />
                )}
              />
            )}
            <Typography sx={{ ...FontSizes.SmallW300, color: Colors.Red, marginTop: '8px' }}>
              {(errors?.checkoutDateAndTime?.message as string) ?? ''}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
};

export default SummaryGigTime;
