import { Box, Stack, Typography } from '@mui/joy';
import { Backdrop, Fade, Modal } from '@mui/material';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import { FontSizes } from '../../../../theme';
import { AdminGigBaseItem } from '../../../../types/interfaces';
import ApplicationItem from './ApplicationItem';
import useAnalyticsPageView from '../../../../hooks/useAnalyticsPageView';

const ApplicationsModal = ({
  id,
  applications,
  modalOpen,
  handleClose,
  setUpToDateGig,
  upToDateGig,
}: {
  id: AdminGigBaseItem['id'];
  applications: AdminGigBaseItem['applications'];
  modalOpen: boolean;
  handleClose: () => void;
  setUpToDateGig: React.Dispatch<React.SetStateAction<AdminGigBaseItem>>;
  upToDateGig: AdminGigBaseItem;
}) => {
  useAnalyticsPageView('Admin - Manage Applications Modal');
  const width = useWindowWidth();
  const height = useWindowHeight();

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width * 0.75,
    height: height * 0.75,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={modalOpen}>
        <Box sx={modalStyle}>
          <Typography id="transition-modal-title" sx={{ ...FontSizes.Header2W500 }}>
            Manage Applications
          </Typography>
          <Stack
            sx={(theme) => ({
              justifyContent: 'flex-start',
              alignItems: 'center',
              overflowY: 'scroll',
              marginRight: '30px',
              marginLeft: '30px',
              marginTop: '30px',
              height: height * 0.65,
            })}
            direction="column"
          >
            {applications.map((application) => (
              <ApplicationItem
                key={application.userId}
                workerId={application.userId}
                firstName={application.user.firstName}
                lastName={application.user.lastName}
                profileURL={application.user.profileURL}
                status={application.status}
                gigId={application.gigId}
                upToDateGig={upToDateGig}
                setUpToDateGig={setUpToDateGig}
              />
            ))}
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ApplicationsModal;
