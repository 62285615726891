import { Box, Stack, Typography } from '@mui/joy';
import { AdminGigBaseItem } from '../../../../types/interfaces';
import { DateTime } from 'luxon';
import HorizontalSpacer from '../../../../components/HorizontalSpacer';
import { Colors, FlexAlign, FontSizes } from '../../../../theme';
import { Collapse, Grid } from '@mui/material';
import { useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import SkillTag from '../../../../components/SkillTag';
import EditGigModal from '../EditGigModal';
import ApplicationsModal from '../ApplicationsModal';
import GigPlaceholder from '../../../../assets/images/gig-placeholder.png';

const expandStyle = {
  color: Colors.Blue,
  ...FontSizes.normalCaptionW700,
};

const editStyle = {
  color: Colors.Blue,
  ...FontSizes.normalCaptionW700,
};

const GigItem = ({ gig }: { gig: AdminGigBaseItem }) => {
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [areTravelTipsVisible, setAreTravelTipsVisible] = useState(false);
  const [areRequirementsVisible, setAreRequirementsVisible] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [applicationsModalOpen, setApplicationsModalOpen] = useState(false);

  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);

  const handleApplicationsModalOpen = () => {
    setApplicationsModalOpen(true);
  };
  const handleApplicationsModalClose = () => setApplicationsModalOpen(false);

  const [upToDateGig, setUpToDateGig] = useState<AdminGigBaseItem>(gig);

  const { user } = useAuth();

  const hasUserCreatedGig = user.id === upToDateGig.userId;
  const canEdit = hasUserCreatedGig || user.role === 'Admin';

  return (
    <Stack
      direction="column"
      sx={{
        width: 360,
        marginTop: '12px',
        overflowX: 'scroll',
        backgroundColor: Colors.GrayDark,
        borderRadius: '8px',
      }}
    >
      {editModalOpen && (
        <EditGigModal
          id={upToDateGig.id}
          name={upToDateGig.name}
          location={upToDateGig.location}
          travelTips={upToDateGig.travelTips}
          requirements={upToDateGig.requirements}
          city={upToDateGig.city}
          zipCode={upToDateGig.zipCode}
          status={upToDateGig.status}
          isFulltime={upToDateGig.isFulltime}
          featuredImage={upToDateGig.featuredImage}
          skills={upToDateGig.skills}
          startDateAndTime={upToDateGig.startDateAndTime}
          endDateAndTime={upToDateGig.endDateAndTime}
          hourlyRate={upToDateGig.hourlyRate}
          estimatedPrice={upToDateGig.estimatedPrice}
          description={upToDateGig.description}
          handleClose={handleEditModalClose}
          modalOpen={editModalOpen}
          setUpToDateGig={setUpToDateGig}
          upToDateGig={upToDateGig}
        />
      )}

      {applicationsModalOpen && (
        <ApplicationsModal
          id={upToDateGig.id}
          applications={upToDateGig.applications}
          modalOpen={applicationsModalOpen}
          handleClose={handleApplicationsModalClose}
          upToDateGig={upToDateGig}
          setUpToDateGig={setUpToDateGig}
        />
      )}

      <Box
        sx={{
          height: '260px',
          backgroundColor: Colors.GrayLight,
          width: '100%',
          ...FlexAlign.Center,
        }}
      >
        <img
          key={upToDateGig.featuredImage}
          src={upToDateGig.featuredImage || GigPlaceholder}
          height={'220px'}
          alt={'gig detail'}
          style={{
            objectFit: 'cover',
            borderRadius: '6px',
            marginBottom: '16px',
          }}
        />
      </Box>

      <Stack direction="column" sx={{ margin: '8px' }}>
        <Box>
          <Typography sx={{ color: Colors.Black, ...FontSizes.Header2W700 }}>
            {upToDateGig.name}
          </Typography>
        </Box>

        <Box>
          {hasUserCreatedGig ? (
            <Typography sx={{}}>{`Provided by: You`}</Typography>
          ) : (
            <Typography
              sx={{}}
            >{`Provider: ${upToDateGig.user.firstName} ${upToDateGig.user.lastName}`}</Typography>
          )}
        </Box>

        <Typography>{`${upToDateGig.location} - ${upToDateGig.city}`}</Typography>

        <Stack direction="column" sx={{}}>
          <Typography>
            {`${DateTime.fromISO(upToDateGig.startDateAndTime).toLocaleString(
              DateTime.DATETIME_MED,
            )} - ${DateTime.fromISO(upToDateGig.endDateAndTime).toLocaleString(
              DateTime.TIME_SIMPLE,
            )}`}
          </Typography>
        </Stack>

        <Box>
          <Typography>{`ZipCode: ${upToDateGig.zipCode}`}</Typography>
        </Box>

        <Box sx={{}} onClick={() => setIsDescriptionVisible(() => !isDescriptionVisible)}>
          <Typography sx={expandStyle}>{`${
            isDescriptionVisible ? 'Hide' : 'Show'
          } Description`}</Typography>
        </Box>
        <Collapse in={isDescriptionVisible}>
          <Typography>{upToDateGig.description}</Typography>
        </Collapse>

        <Box sx={{}} onClick={() => setAreTravelTipsVisible(() => !areTravelTipsVisible)}>
          <Typography sx={expandStyle}>{`${
            areTravelTipsVisible ? 'Hide' : 'Show'
          } Travel Tips`}</Typography>
        </Box>
        <Collapse in={areTravelTipsVisible}>
          <Typography>{upToDateGig.travelTips}</Typography>
        </Collapse>

        <Box sx={{}} onClick={() => setAreRequirementsVisible(() => !areRequirementsVisible)}>
          <Typography sx={expandStyle}>{`${
            areRequirementsVisible ? 'Hide' : 'Show'
          } Requirements`}</Typography>
        </Box>
        <Collapse in={areRequirementsVisible}>
          <Typography>{upToDateGig.requirements}</Typography>
        </Collapse>

        <Stack direction="row" sx={{}}>
          <Typography>{`Est. ${upToDateGig.estimatedPrice}$`}</Typography>
          <HorizontalSpacer />
          <Typography>{`${upToDateGig.hourlyRate}$/hour`}</Typography>
        </Stack>

        <Box>
          <Typography>{`Status: ${upToDateGig.status}`}</Typography>
        </Box>

        <Box>
          <Typography>{`Applications: ${upToDateGig.applications.length}`}</Typography>
        </Box>

        <Typography>Skills:</Typography>
        <Grid container>
          {upToDateGig.skills.map((skill) => (
            <Grid item sx={{ margin: '8px' }}>
              <SkillTag children={skill} isVerified={false} />
            </Grid>
          ))}
        </Grid>

        {canEdit && (
          <Box onClick={handleEditModalOpen}>
            <Typography sx={editStyle}>{`Edit`}</Typography>
          </Box>
        )}

        {canEdit && (
          <Box onClick={handleApplicationsModalOpen}>
            <Typography sx={editStyle}>{`Manage Applications`}</Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default GigItem;
