import { FormControl, FormHelperText, RadioGroup } from '@mui/joy';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioControllerProps } from '../../types/interfaces';

const RadioGroupController = ({ name, children, sx }: RadioControllerProps) => {
  const { control } = useFormContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <FormControl>
      <Controller
        rules={{ required: true }}
        control={control}
        name={name}
        render={({ field, fieldState: { invalid, error } }) => {
          setErrorMessage(error?.message || null);
          return (
            <RadioGroup
              {...field}
              sx={{
                ...sx,
                gap: 3,
                '--RadioGroup-gap': 0,
              }}
            >
              {children}
            </RadioGroup>
          );
        }}
      />
      <FormHelperText sx={{ color: 'var(--joy-palette-error-main)' }}>
        {!!errorMessage ? errorMessage : ''}
      </FormHelperText>
    </FormControl>
  );
};

export default RadioGroupController;
