import { Box, Stack } from '@mui/joy';
import ProfileSetPasswordComponent from '../../../../../../components/ProfileSetPassword';
import useAnalyticsPageView from '../../../../../../hooks/useAnalyticsPageView';
import useMobileMode from '../../../../../../hooks/useMobileMode';
import { Colors } from '../../../../../../theme';
import { ProfileMenuProps } from '../../../../../../types/interfaces';

const ProfileSetPassword = ({ label }: ProfileMenuProps) => {
  useAnalyticsPageView('Profile Tab - Set Password Tab');
  const mobileMode = useMobileMode();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: mobileMode ? '104px' : '0px',
        backgroundColor: Colors.GrayLight,
      }}
    >
      <Stack direction="column" sx={{ backgroundColor: Colors.GrayLight, width: '100%' }}>
        <ProfileSetPasswordComponent />
      </Stack>
    </Box>
  );
};

export default ProfileSetPassword;
