import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormLabel,
  Stack,
  Typography,
} from '@mui/joy';
import { FormControlLabel, FormHelperText } from '@mui/material';
import { Backdrop, Fade, Grid, Modal } from '@mui/material';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { editGig } from '../../../../api/gigs';
import { getSkills } from '../../../../api/skill';
import ActionableSearchInput from '../../../../components/ActionableSearchInput';
import DeletableSkillTag from '../../../../components/DeletableSkillTag';
import InputField from '../../../../components/InputField';
import { EditTextareaStyled } from '../../../../components/ProfileEdit';
import { rectangleStyle, reUploadBtn } from '../../../../config/styleConstants';
import { Colors, FontSizes } from '../../../../theme';
import {
  ActionableSearchInputSubmitMode,
  AdminGigBaseItem,
  City,
  EditGigFormData,
  Skill,
} from '../../../../types/interfaces';
import { EditGigSchema } from '../../../../validation';
import GigTime from '../../CreateGig/GigTime';
import FeaturedImage from '../../CreateGig/FeaturedImage/index';
import Select from 'react-select';
import { GigStatus, ToastMode } from '../../../../types/interfaces';
import triggerToast from '../../../../utils/triggerToast';
import useAnalyticsPageView from '../../../../hooks/useAnalyticsPageView';
import analytics from '../../../../utils/analytics';
import errorReporting from '../../../../utils/errorReporting';
import { getCities } from '../../../../api/cities';

const FormLabelStyle = {
  fontWeight: 300,
  lineHeight: '30px',
  fontSize: 12,
  color: Colors.AlmostBlack,
};

const EditGigModal = ({
  id,
  name,
  location,
  travelTips,
  requirements,
  city,
  zipCode,
  isFulltime,
  status,
  featuredImage,
  skills,
  startDateAndTime,
  endDateAndTime,
  hourlyRate,
  estimatedPrice,
  description,
  modalOpen,
  handleClose,
  setUpToDateGig,
  upToDateGig,
}: {
  id: AdminGigBaseItem['id'];
  name: AdminGigBaseItem['name'];
  location: AdminGigBaseItem['location'];
  travelTips: AdminGigBaseItem['travelTips'];
  requirements: AdminGigBaseItem['requirements'];
  city: AdminGigBaseItem['city'];
  zipCode: AdminGigBaseItem['zipCode'];
  isFulltime: AdminGigBaseItem['isFulltime'];
  status: AdminGigBaseItem['status'];
  featuredImage: AdminGigBaseItem['featuredImage'];
  skills: AdminGigBaseItem['skills'];
  startDateAndTime: AdminGigBaseItem['startDateAndTime'];
  endDateAndTime: AdminGigBaseItem['endDateAndTime'];
  hourlyRate: AdminGigBaseItem['hourlyRate'];
  estimatedPrice: AdminGigBaseItem['estimatedPrice'];
  description: AdminGigBaseItem['description'];
  modalOpen: boolean;
  handleClose: () => void;
  setUpToDateGig: React.Dispatch<React.SetStateAction<AdminGigBaseItem>>;
  upToDateGig: AdminGigBaseItem;
}) => {
  useAnalyticsPageView('Admin - Edit Gig Modal');
  const [error, setError] = useState<string>('');
  const resetError = () => setError('');
  const width = useWindowWidth();
  const height = useWindowHeight();
  const [existingFeaturedImage, setExistingFeaturedImage] = useState<string | null>(featuredImage);

  const [gigStatus, setGigStatus] = useState(status);

  const [skillsToUpdate, setSkillsToUpdate] = useState<Skill[]>([]);
  const [skillsLabels, setSkillsLabels] = useState<Skill['name'][]>([]);
  const [selectedSkillsLabels, setSelectedSkillsLabels] = useState<Skill['name'][]>(skills);
  const [tmpIsFulltime, setTmpIsFulltime] = useState(isFulltime);

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width * 0.75,
    height: height * 0.75,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    (async () => {
      const res = await getSkills();
      setSkillsToUpdate(res?.data?.skills);
      setSkillsLabels(res?.data?.skills?.map((skill: Skill) => skill.name));
    })();
  }, []);

  const [availableCities, setAvailableCities] = useState<City[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedAvailableCity, setSelectedAvailableCity] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await getCities();
        setAvailableCities(res?.data?.cities);
        setSelectedAvailableCity(city);
      } catch (error) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });
      }
    })();
  }, []);

  const methods = useForm<EditGigFormData>({
    defaultValues: {
      name,
      location,
      travelTips,
      requirements,
      city,
      zipCode,
      isFulltime,
      status,
      featuredImage: null,
      skills,
      startDateAndTime,
      endDateAndTime,
      hourlyRate,
      estimatedPrice,
      description,
    },
    resolver: yupResolver(EditGigSchema),
  });

  const addSelectedSkill = useCallback(
    ({ skillLabel }: { skillLabel: Skill['name'] }) => {
      const updatedSkills = [...selectedSkillsLabels, skillLabel];

      setSelectedSkillsLabels(updatedSkills);
      methods.setValue('skills', updatedSkills);
    },
    [selectedSkillsLabels, methods],
  );

  const removeSelectedSkill = useCallback(
    (skillLabel: string) => {
      const updatedSkills = selectedSkillsLabels.filter((item) => item !== skillLabel);

      setSelectedSkillsLabels(updatedSkills);
      methods.setValue('skills', updatedSkills);
    },
    [selectedSkillsLabels, methods],
  );

  const onSubmit = async (values: EditGigFormData) => {
    resetError();

    try {
      await methods.trigger();

      const formData = new FormData() as EditGigFormData;
      formData.append('name', values.name);
      formData.append('location', values.location);
      formData.append('requirements', values.requirements);
      formData.append('city', values.city);
      formData.append('zipCode', values?.zipCode ?? '');
      formData.append('isFulltime', tmpIsFulltime.toString());
      formData.append('status', gigStatus);
      if (values.featuredImage) {
        formData.append('featuredImage', values.featuredImage, values.featuredImage.name);
      }
      formData.append('startDateAndTime', values.startDateAndTime);
      formData.append('endDateAndTime', values.endDateAndTime);
      formData.append('hourlyRate', values.hourlyRate.toString());
      formData.append('estimatedPrice', values.estimatedPrice.toString());
      formData.append('description', values.description);
      formData.append('travelTips', values.travelTips);

      skillsToUpdate
        .filter((skill) => selectedSkillsLabels.includes(skill.name))
        .forEach((skill, index) => {
          formData.append(`skills[${index}]`, skill.id);
        });

      const res = await editGig({ formData, gigId: id });

      setUpToDateGig({
        ...upToDateGig,
        name: res.data.gig.name,
        location: res.data.gig.location,
        travelTips: res.data.gig.travelTips,
        requirements: res.data.gig.requirements,
        city: res.data.gig.city,
        zipCode: res.data.gig.zipCode,
        isFulltime: res.data.gig.isFulltime,
        featuredImage: res.data.gig.featuredImage,
        skills: res.data.gig.skills,
        startDateAndTime: res.data.gig.startDateAndTime,
        endDateAndTime: res.data.gig.endDateAndTime,
        hourlyRate: res.data.gig.hourlyRate,
        estimatedPrice: res.data.gig.estimatedPrice,
        description: res.data.gig.description,
      });

      handleClose();
      analytics.track('Admin - Gig Edited');
    } catch (error: any) {
      console.error(error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while editing your Gig. Please retry or contact support.',
      });
    }
  };

  const onError = () => {
    setError(
      'An error occurred while editing your Gig. Please try again later or contact support.',
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={modalOpen}>
        <Box sx={modalStyle}>
          <Typography id="transition-modal-title" sx={{ ...FontSizes.Header2W500 }}>
            Edit Gig
          </Typography>
          <Stack
            sx={(theme) => ({
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              overflowY: 'scroll',
              marginRight: '30px',
              marginLeft: '30px',
            })}
            direction="column"
          >
            <FormProvider {...methods}>
              <form>
                <Stack
                  direction="column"
                  sx={{
                    padding: '48px',
                    width: width * 0.45,
                    height: '100vh',
                  }}
                >
                  <Box sx={{ width: '100%', marginTop: '180px' }} />

                  {existingFeaturedImage ? (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{ position: 'relative' }}
                    >
                      <img
                        src={featuredImage as string}
                        width={rectangleStyle.width}
                        height={rectangleStyle.height}
                        style={{ ...rectangleStyle, objectFit: 'cover', objectPosition: 'top' }}
                        alt="featured"
                      />
                      <Button
                        sx={{ ...reUploadBtn }}
                        onClick={() => {
                          setExistingFeaturedImage(null);
                        }}
                      >
                        Change Image
                      </Button>
                    </Stack>
                  ) : (
                    <FeaturedImage />
                  )}

                  <InputField label="Enter Gig's Name" name="name" />

                  <InputField label="Enter Gig's Location" name="location" />

                  <Box sx={{ marginTop: '30px', marginBottom: '8px' }}>
                    <Select
                      value={{ value: selectedAvailableCity, label: selectedAvailableCity }}
                      onChange={(option) => {
                        if (option) {
                          methods.setValue('city', option.value as string);
                          setSelectedAvailableCity(option.value as string);
                        }
                      }}
                      options={
                        availableCities
                          ? availableCities.map((city) => ({ value: city.name, label: city.name }))
                          : []
                      }
                    />
                  </Box>

                  <InputField label="Enter Gig's Zipcode" name="zipCode" />

                  <Box sx={{ paddingLeft: '16px', marginTop: '24px', marginBottom: '24px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...methods.register('isFulltime')}
                          checked={tmpIsFulltime}
                          onChange={(e) => setTmpIsFulltime(e.target.checked)}
                          sx={{ marginRight: '16px' }}
                        />
                      }
                      label="Fulltime"
                    />
                  </Box>

                  <InputField label="Enter Gig's Hourly Rate - Enter a number" name="hourlyRate" />
                  <InputField
                    label="Enter Gig's Estimated Price - Enter a number"
                    name="estimatedPrice"
                  />

                  <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
                    <FormLabel>
                      <Typography sx={FormLabelStyle}>Description</Typography>
                    </FormLabel>
                    <FormControl size="sm" color="primary">
                      <EditTextareaStyled
                        minRows={2}
                        maxRows={4}
                        {...methods.register('description')}
                        placeholder={"Enter Gig's description"}
                        autoComplete="off"
                        defaultValue={''}
                        variant="plain"
                        sx={{ fontWeight: 400, color: Colors.Black, fontSize: '18px' }}
                      />

                      {methods.formState?.errors?.description?.message && (
                        <FormHelperText error>
                          {methods.formState?.errors?.description?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
                    <FormLabel>
                      <Typography sx={FormLabelStyle}>Travel Tips</Typography>
                    </FormLabel>
                    <FormControl size="sm" color="primary">
                      <EditTextareaStyled
                        minRows={2}
                        maxRows={4}
                        {...methods.register('travelTips')}
                        placeholder={"Enter Gig's Travel Tips"}
                        autoComplete="off"
                        defaultValue={''}
                        variant="plain"
                        sx={{ fontWeight: 400, color: Colors.Black, fontSize: '18px' }}
                      />

                      {methods.formState?.errors?.travelTips?.message && (
                        <FormHelperText error>
                          {methods.formState?.errors?.travelTips?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
                    <FormLabel>
                      <Typography sx={FormLabelStyle}>Requirements</Typography>
                    </FormLabel>
                    <FormControl size="sm" color="primary">
                      <EditTextareaStyled
                        minRows={2}
                        maxRows={4}
                        {...methods.register('requirements')}
                        placeholder={"Enter Gig's Requirements"}
                        autoComplete="off"
                        defaultValue={''}
                        variant="plain"
                        sx={{ fontWeight: 400, color: Colors.Black, fontSize: '18px' }}
                      />

                      {methods.formState?.errors?.requirements?.message && (
                        <FormHelperText error>
                          {methods.formState?.errors?.requirements?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  <GigTime />

                  <FormLabel>
                    <Typography sx={FormLabelStyle}>Gig Status</Typography>
                  </FormLabel>
                  <Select
                    value={{ value: gigStatus, label: gigStatus }}
                    onChange={(option) => {
                      if (option) {
                        setGigStatus(option.value);
                      }
                    }}
                    options={[
                      { value: GigStatus.Assigned, label: GigStatus.Assigned },
                      { value: GigStatus.Unassigned, label: GigStatus.Unassigned },
                      { value: GigStatus.Cancelled, label: GigStatus.Cancelled },
                      { value: GigStatus.Completed, label: GigStatus.Completed },
                    ]}
                  />

                  <Box sx={{ minHeight: '240px', marginTop: '30px' }}>
                    <ActionableSearchInput
                      onClick={(skillLabel: string) => {
                        methods.clearErrors('skills');
                        addSelectedSkill({ skillLabel });
                      }}
                      buttonLabel="Add"
                      submitMode={ActionableSearchInputSubmitMode.AutoCleanOnSubmit}
                      placeholder="Add a skill"
                      autoCompleteOptions={skillsLabels}
                      autoCompleteSelectedOptions={selectedSkillsLabels}
                      validateOnSubmit={(text) => {
                        if (!skillsLabels.includes(text)) {
                          return 'The skill must be chosen from the skills list';
                        }

                        return null;
                      }}
                    />

                    {methods.formState?.errors?.skills?.message && (
                      <FormHelperText error>
                        {methods.formState?.errors?.skills?.message}
                      </FormHelperText>
                    )}

                    <Grid container sx={{ marginTop: 3 }} direction="row">
                      {selectedSkillsLabels.map((skillLabel: Skill['name']) => (
                        <Grid
                          item
                          key={skillLabel}
                          sx={{ marginRight: '8px', marginBottom: '12px' }}
                        >
                          <DeletableSkillTag onDeleteClick={removeSelectedSkill}>
                            {skillLabel}
                          </DeletableSkillTag>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>

                  {error && (
                    <Typography
                      sx={{ ...FontSizes.SmallW300, color: Colors.Red, marginTop: '16px' }}
                    >
                      {
                        'An error occurred while editing your Gig. Please try again later or contact support.'
                      }
                    </Typography>
                  )}

                  <Button
                    sx={(theme) => ({
                      border: `2px solid ${Colors.Blue}`,
                      marginBottom: '72px',
                      marginTop: '24px',
                      width: '100%',
                    })}
                    type="button"
                    variant="outlined"
                    onClick={methods.handleSubmit(onSubmit, onError)}
                    disabled={methods?.formState?.isSubmitting}
                  >
                    {methods?.formState?.isSubmitting ? (
                      <CircularProgress variant="solid" color="primary" size="sm" />
                    ) : (
                      'Edit Gig'
                    )}
                  </Button>

                  <Typography sx={{ marginBottom: '80px' }}>.</Typography>
                </Stack>
              </form>
            </FormProvider>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditGigModal;
