import { Box, Stack, Typography } from '@mui/joy';
import { Colors, FlexAlign, FontSizes } from '../../theme';
import { SkillTagProps } from '../../types/interfaces';
import { Verified } from '../../assets/svg/index';

const SkillTag = ({ children, isVerified = false }: SkillTagProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: Colors.Blue,
        paddingLeft: '4px',
        paddingRight: '4px',
        ...FlexAlign.Center,
      }}
    >
      {isVerified ? (
        <Box sx={{ marginRight: '4px', ...FlexAlign.Center }}>
          <Verified />
        </Box>
      ) : null}

      <Typography
        sx={{
          pointerEvents: 'none',
          color: Colors.White,
          ...FontSizes.Header4W700,
          height: '20px',
          ...FlexAlign.Center,
          float: 'left',
        }}
      >
        {children}
      </Typography>
    </Stack>
  );
};

export default SkillTag;
