export const backBtnStyles = {
  all: 'unset',
  position: 'absolute',
  left: 15,
  top: 15,
  '&:hover': {
    backgroundColor: 'transparent',
    '& svg': {
      '& path': {
        stroke: '#173DE5',
      },
      '& rect': {
        stroke: '#173DE5',
      },
    },
  },
};

export const circleStyle = { width: 190, height: 190, borderRadius: 100 };
export const rectangleStyle = { width: 250, height: 155, borderRadius: 16 };
export const reUploadBtn = {
  position: 'absolute',
  bottom: '-12px',
  fontSize: 12,
  minHeight: 'auto',
  padding: '8px 12px',
};
