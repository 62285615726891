import { Helmet } from 'react-helmet';
import useAnalyticsPageView from '../hooks/useAnalyticsPageView';

const NotFound = () => {
  useAnalyticsPageView('Not Found');
  return (
    <div>
      <Helmet>
        <title>{'Frontline | Not Found'}</title>
      </Helmet>
      <h1>Not Found:404</h1>
    </div>
  );
};

export default NotFound;
