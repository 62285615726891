import mixpanel, { Config, Dict, RequestOptions, Callback } from 'mixpanel-browser';

const analytics = {
  init: (token: string, config?: Partial<Config>) => {
    if (process.env.NODE_ENV === 'test') return;
    if (process.env.REACT_APP_ENVIRONMENT === 'local') return;

    mixpanel.init(token, config);
  },
  track: (
    event_name: string,
    properties?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback,
  ) => {
    if (process.env.NODE_ENV === 'test') return;
    if (process.env.REACT_APP_ENVIRONMENT === 'local') return;

    mixpanel.track(event_name, properties, optionsOrCallback, callback);
  },
};

export default analytics;
