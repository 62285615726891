import { Box, Stack, Typography } from '@mui/joy';
import { memo } from 'react';
import Step from '../../../../../components/Step';
import UnlockMoreGigsHeader from '../../../../../components/UnlockMoreGigsHeader';
import { Colors, FontSizes } from '../../../../../theme';
import useMobileMode from '../../../../../hooks/useMobileMode';
import { useNavigate } from 'react-router-dom';
import useAnalyticsPageView from '../../../../../hooks/useAnalyticsPageView';

const UnlockMoreGigsSection = ({
  goBack,
  isPaymentMethodAdded,
  isBasicGigTrainingCompleted,
}: {
  goBack: () => void;
  isPaymentMethodAdded: boolean;
  isBasicGigTrainingCompleted: boolean;
}) => {
  useAnalyticsPageView('Home Tab - Unlock More Gigs');
  const mobileMode = useMobileMode();
  const navigate = useNavigate();

  const horizontalPadding = mobileMode
    ? { paddingLeft: '16px', paddingRight: '16px' }
    : { paddingLeft: '48px', paddingRight: '48px' };

  const numberOfCompletedSteps = [isPaymentMethodAdded, isBasicGigTrainingCompleted].filter(
    (flag) => flag,
  ).length;

  return (
    <Stack direction="column" sx={{ backgroundColor: Colors.GrayLight, display: 'flex', flex: 1 }}>
      <UnlockMoreGigsHeader
        goBack={goBack}
        backgroundColor={mobileMode ? Colors.Green : Colors.White}
        numberOfCompletedSteps={numberOfCompletedSteps}
      />

      <Stack direction="column" sx={{ ...horizontalPadding, marginTop: '80px' }}>
        <Typography
          sx={{
            paddingTop: '24px',
            ...FontSizes.Header2W700,
          }}
        >
          Frontliners can get more job opportunities by following these steps:
        </Typography>

        <Box sx={{ marginTop: '16px' }}>
          <Step
            onClick={function (): void {
              navigate('/dashboard/payment');
            }}
            completed={isPaymentMethodAdded}
            title={'Add payment method'}
            headline={'Step 1'}
            description={'Help us to understand what jobs you prefer or match with your skills'}
            timeToCompletionInMinutes={5}
          />
        </Box>

        <Box sx={{ marginTop: '16px', marginBottom: '80px' }}>
          <Step
            onClick={function (): void {
              navigate('/dashboard/profile/gig-academy');
            }}
            completed={isBasicGigTrainingCompleted}
            title={'Learn the Gig Basic skills'}
            headline={'Step 2'}
            description={
              'Review the gig basics content to be eligible for your first gig. Estimated completion time is 15 minutes.'
            }
            timeToCompletionInMinutes={15}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default memo(UnlockMoreGigsSection);
