import { Box, Stack, Typography } from '@mui/joy';
import { DashboardTabItem, WebNavigationBarProps } from '../../types/interfaces';
import { Colors, FlexAlign, FontSizes } from '../../theme';
import { LogoIcon } from '../../assets/svg/index';
import WebNavigatorItem from './WebNavigatorItem';
import { WEB_NAVIGATION_BAR_WIDTH } from '../../config/constants';
import useIsAnonymous from '../../hooks/useIsAnonymous';

const handleItemClick = ({
  onClick,
  label,
}: {
  onClick: DashboardTabItem['onClick'];
  label: DashboardTabItem['label'];
}) => {
  onClick(label);
};

const WebNavigationBar = ({
  navItems,
  currentTabLabel,
  isAnonymous = false,
}: WebNavigationBarProps) => {
  const isAnonymousFreshlyDetected = useIsAnonymous();

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.Black,
        width: WEB_NAVIGATION_BAR_WIDTH,
        height: '100vh',
      }}
    >
      <Box
        sx={{
          width: '180px',
          height: '180px',
          ...FlexAlign.Center,
          backgroundColor: Colors.Blue,
          marginBottom: '24px',
        }}
      >
        <LogoIcon />
      </Box>

      <Stack direction="column" sx={isAnonymous ? { display: 'flex', flex: 1 } : {}}>
        {isAnonymous && isAnonymousFreshlyDetected === true && (
          <Stack
            direction="column"
            sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Stack
              direction="column"
              sx={{
                backgroundColor: Colors.LightBlack,
                width: 153,
                height: 187,
                marginBottom: '13.5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  color: Colors.White,
                  fontWeight: 700,
                  fontSize: '15px',
                  lineHeight: '22px',
                  width: '90px',
                  textAlign: 'center',
                  marginBottom: '4px',
                  fontFamily: 'Montserrat, sans-serif',
                }}
              >
                Login to get paid
              </Typography>

              <Typography
                sx={{
                  color: Colors.White,
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  width: '121px',
                  textAlign: 'center',
                  fontFamily: 'Montserrat, sans-serif',
                }}
              >
                Find your next green gig and start your green career
              </Typography>

              <Box
                onClick={() => window.open('/login', '_blank')}
                sx={{
                  width: '121px',
                  height: '48px',
                  backgroundColor: Colors.Blue,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '12px',
                }}
              >
                <Typography
                  sx={{
                    color: Colors.White,
                    fontWeight: 700,
                    fontSize: '15px',
                    lineHeight: '22px',
                    pointerEvents: 'none',
                    fontFamily: 'Montserrat, sans-serif',
                  }}
                >
                  Login Now
                </Typography>
              </Box>
            </Stack>
          </Stack>
        )}

        {isAnonymous && isAnonymousFreshlyDetected === false && (
          <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Box onClick={() => window.location.reload()}>
              <Typography
                sx={{
                  color: Colors.GrayLight,
                  ...FontSizes.BolderCaptionW500,
                  pointerEvents: 'none',
                  paddingRight: '8px',
                  paddingLeft: '8px',
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  border: `2px solid ${Colors.GrayLight}`,
                }}
              >
                Home
              </Typography>
            </Box>
          </Box>
        )}

        {isAnonymous === false &&
          navItems.map((item) => (
            <WebNavigatorItem
              label={item.label}
              onClick={() => handleItemClick({ label: item.label, onClick: item.onClick })}
              Icon={item.Icon}
              key={item.label}
              isFocused={item.label === currentTabLabel}
            />
          ))}
      </Stack>
    </Stack>
  );
};

export default WebNavigationBar;
