import { Box, Typography } from '@mui/joy';
import { useCallback } from 'react';
import { Close } from '../../assets/svg';
import { Colors, FontSizes } from '../../theme';
import { DeletableSkillTagProps } from '../../types/interfaces';

const DeletableSkillTag = ({ children, onDeleteClick }: DeletableSkillTagProps) => {
  const handleDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      onDeleteClick(children);
    },
    [onDeleteClick, children],
  );

  return (
    <Box
      sx={{
        backgroundColor: Colors.Blue,
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: '14px',
        paddingLeft: '16px',
      }}
      onClick={handleDeleteClick}
    >
      <Typography
        sx={{
          pointerEvents: 'none',
          color: Colors.White,
          ...FontSizes.ButtonSmallW700,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          float: 'left',
          marginRight: '12px',
        }}
      >
        {children}
      </Typography>

      <Close />
    </Box>
  );
};

export default DeletableSkillTag;
