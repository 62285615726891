import { useEffect } from 'react';

const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  dataAttributesToExclude: string[] = [],
  callback: () => void,
) => {
  useEffect(() => {
    const handleOutsideEvent = (event: MouseEvent | TouchEvent) => {
      let targetElement = event.target as HTMLElement;

      // Traverse up the DOM tree to check if we reach the document body or find the element with the given data-* attribute (by default a boolean check)
      for (let i = 0; i < dataAttributesToExclude.length; i++) {
        while (targetElement && targetElement !== document.body) {
          if (targetElement.dataset.skipOutsideClickCallback) {
            return;
          }
          targetElement = targetElement.parentNode as HTMLElement;
        }
      }

      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleOutsideEvent);
    document.addEventListener('touchstart', handleOutsideEvent);
    return () => {
      document.removeEventListener('mousedown', handleOutsideEvent);
      document.removeEventListener('touchstart', handleOutsideEvent);
    };
  }, [ref, callback, dataAttributesToExclude]);
};

export default useOutsideClick;
