import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { PublicRouteProps } from '../../types/interfaces';

/**
 * Component that redirects the users to the `/dashboard/home/feed` if
 * they are already logged in.
 * @param {React.ReactNode} children
 * @returns JSX.Element
 */
export const PublicRoute = ({ children }: PublicRouteProps) => {
  const { user } = useAuth();
  if (user?.token) {
    return <Navigate to={'/dashboard/home/feed'} replace />;
  }
  return <>{children}</>;
};

export default PublicRoute;
