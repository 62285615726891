import { Button, Stack, Typography } from '@mui/joy';
import { GigDetailsFooterBlockAssignedGigProps, ToastMode } from '../../types/interfaces';
import { Colors, FontSizes } from '../../theme';
import React, { useCallback, useState } from 'react';
import useMobileMode from '../../hooks/useMobileMode';
import { deleteGigApplication } from '../../api/gigs';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import triggerToast from '../../utils/triggerToast';
import analytics from '../../utils/analytics';
import errorReporting from '../../utils/errorReporting';

const GigDetailsFooterBlockAssignedGig = ({
  onReportClick,
  gigId,
  setGig,
}: GigDetailsFooterBlockAssignedGigProps) => {
  const mobileMode = useMobileMode();
  const mobileAwareBorderRadius = mobileMode ? '0px' : '16px';
  const mobileAwareHorizontalMargin = mobileMode ? '8px 0 0 0' : '16px 48px 0 48px';

  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCancelDialog = () => setIsDialogOpen(false);
  const handleOpenDialog = () => setIsDialogOpen(true);

  const onDeleteApplicationClick = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const response = await deleteGigApplication({
        gigId: gigId as string,
      });

      setGig(response.data.updatedGig);
      analytics.track('Withdraw From Assigned Gig');
    } catch (error: any) {
      console.error(error);
      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while withdrawing from this gig. Please retry or contact support.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, gigId, setGig]);

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.White,
        padding: '16px',
        borderRadius: mobileAwareBorderRadius,
        margin: mobileAwareHorizontalMargin,
      }}
    >
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={isDialogOpen}
      >
        <DialogTitle>Withdraw from Gig</DialogTitle>
        <DialogContent>
          Are you sure you want to withdraw from this Gig after your application has been accepted?
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCancelDialog}
            sx={{ backgroundColor: Colors.Blue, borderRadius: '8px', marginRight: '20px' }}
          >
            No
          </Button>

          <Button
            onClick={onDeleteApplicationClick}
            sx={{ backgroundColor: Colors.Blue, borderRadius: '8px', marginRight: '10px' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Typography
        sx={{
          color: Colors.Black,
          ...FontSizes.BodyRegularW300,
          marginBottom: '24px',
          marginTop: '12px',
        }}
      >
        Please keep in mind that to report this gig you need the pictures before and after the work
        you have done.
      </Typography>

      <Button
        sx={(theme) => ({
          border: `2px solid ${theme.vars.palette.primary[500]}`,
          marginBottom: '24px',
          width: '100%',
        })}
        type="button"
        variant="outlined"
        onClick={onReportClick}
      >
        Submit a Gig Report
      </Button>

      <Button
        sx={(theme) => ({
          border: `2px solid ${theme.vars.palette.danger[500]}`,
          marginBottom: '24px',
          width: '100%',
          color: theme.vars.palette.danger[500],
        })}
        type="button"
        variant="outlined"
        onClick={handleOpenDialog}
      >
        Withdraw from Gig
      </Button>
    </Stack>
  );
};

export default React.memo(GigDetailsFooterBlockAssignedGig);
