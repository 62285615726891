import { Box, Button, CircularProgress, FormHelperText, Stack, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Img } from 'react-image';
import { subTitle } from '..';
import { CameraIcon } from '../../../assets/svg';
import UploadButton from '../../../components/UploadButton';
import { circleStyle, reUploadBtn } from '../../../config/styleConstants';

const UploadPhoto = () => {
  const [preview, setPreview] = useState<string>();
  const { getValues, setValue, formState, watch } = useFormContext();
  const { photo } = getValues();

  useEffect(() => {
    let objectUrl = '';
    if (photo) {
      objectUrl = URL.createObjectURL(photo);
      setPreview(objectUrl);
    }
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [photo]);

  watch();
  return (
    <>
      <Typography my={3} textColor="#6A6A68" {...subTitle}>
        We need a current photo of you. Do this as soon as possible to help us serve you better
      </Typography>

      {preview ? (
        <Stack justifyContent="center" alignItems="center" sx={{ position: 'relative' }}>
          <Img
            src={preview}
            height={circleStyle.height}
            width={circleStyle.width}
            style={{ ...circleStyle, objectFit: 'cover', objectPosition: 'top' }}
            alt="profile img"
            loader={
              <Box
                sx={{
                  width: circleStyle.width,
                  height: circleStyle.height,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress size={'sm'} />
              </Box>
            }
          />
          <Button
            sx={reUploadBtn}
            onClick={() => {
              setPreview('');
              setValue('photo', undefined, { shouldValidate: true });
            }}
          >
            Re-upload
          </Button>
        </Stack>
      ) : (
        <UploadButton name="photo" buttonText="Upload" icon={<CameraIcon />} formKey={'photo'} />
      )}
      <Stack justifyContent="center" alignItems="center">
        <FormHelperText
          sx={{
            color: 'var(--joy-palette-error-main)',
            mt: 2,
            justifyContent: 'circle',
          }}
        >
          {formState?.errors?.photo?.message ? `${formState?.errors?.photo?.message}` : ''}
        </FormHelperText>
      </Stack>
    </>
  );
};

export default UploadPhoto;
