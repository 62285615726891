import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const setAuthToken = (token?: string) => {
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    const _token = window.localStorage.getItem('token');
    instance.defaults.headers.common['Authorization'] = `Bearer ${_token}`;
  }
};

setAuthToken();
export default instance;
