import { Box, Stack, Typography } from '@mui/joy';
import { HEADER_HEIGHT } from '../../config/constants';
import useMobileMode from '../../hooks/useMobileMode';
import { Colors, FontSizes } from '../../theme';
import HorizontalSpacer from '../HorizontalSpacer';

const Header = ({
  label,
  handleRightButtonClick,
  RightButton,
}: {
  label: string;
  handleRightButtonClick?: () => Promise<void>;
  RightButton?: () => JSX.Element;
}) => {
  const mobileMode = useMobileMode();

  const horizontalPadding = mobileMode
    ? { paddingLeft: '16px', paddingRight: '16px' }
    : { paddingLeft: '48px', paddingRight: '48px' };

  return (
    <Stack
      direction="column"
      sx={{
        top: 0,
        backgroundColor: Colors.White,
        width: '100%',
        height: HEADER_HEIGHT,
        position: 'fixed',
        zIndex: 2,
      }}
    >
      <Stack direction="row">
        <Typography
          sx={{
            ...FontSizes.Header1W700,
            color: Colors.Black,
            marginTop: '48px',
            marginBottom: '20px',
            ...horizontalPadding,
          }}
        >
          {label}
        </Typography>

        <HorizontalSpacer />
        <Box
          sx={{
            marginTop: '60px',
            marginBottom: '20px',
            ...horizontalPadding,
          }}
          onClick={handleRightButtonClick}
        >
          {RightButton && <RightButton />}
        </Box>
      </Stack>
    </Stack>
  );
};

export default Header;
