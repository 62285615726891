import React from 'react';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Link, Stack, IconButton } from '@mui/joy';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import InputField from '../../../components/InputField';
import { emailAndPasswordValidation } from '../../../validation';
import { loginWithEmail } from '../../../api/auth';
import { setAuthToken } from '../../../api';
import useAuth from '../../../hooks/useAuth';
import WarningIcon from '@mui/icons-material/Warning';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isAxiosError } from 'axios';
import { TIME_INTERVAL_FOR_MESSAGE } from '../../../config/constants';
import useShowAlert from '../../../hooks/useShowAlert';
import CustomAlert from '../../../components/CustomAlert';
import triggerToast from '../../../utils/triggerToast';
import { JwtTokenPayload, ToastMode, UserRole } from '../../../types/interfaces';
import useAnalyticsPageView from '../../../hooks/useAnalyticsPageView';
import analytics from '../../../utils/analytics';
import errorReporting from '../../../utils/errorReporting';
import jwt from 'jwt-decode';

const defaultValues = {
  email: '',
  password: '',
};

const Index = () => {
  useAnalyticsPageView('Sign In By Email');
  const navigation = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { showAlert, setShowAlert } = useShowAlert(TIME_INTERVAL_FOR_MESSAGE);
  const { setUser } = useAuth();
  const methods = useForm({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(emailAndPasswordValidation),
    mode: 'onChange',
  });

  const { handleSubmit } = methods;

  /**
   * This method calls login API
   * @returns Promise
   */

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const res = await loginWithEmail({
        value: data?.email,
        password: data?.password,
      });
      window.localStorage.setItem('token', res?.data?.token);
      setAuthToken(res?.data?.token);
      if (res?.data?.token) {
        setUser({
          token: res?.data?.token || '',
        });
      }

      analytics.track('Sign In By Email');

      try {
        const decodedToken = jwt<JwtTokenPayload>(res?.data?.token as string);

        if (
          decodedToken &&
          (decodedToken.user.role === UserRole.Admin ||
            decodedToken.user.role === UserRole.Provider)
        ) {
          navigation('/admin');
        } else {
          navigation('/dashboard/home/feed');
        }
      } catch (error: any) {
        errorReporting.captureException(error, {
          level: 'error',
        });
        navigation('/dashboard/home/feed');
      }
    } catch (error) {
      errorReporting.captureException(error, {
        level: 'warning',
      });
      if (isAxiosError(error)) {
        setErrorMsg(error.response?.data?.message);
        setShowAlert(true);

        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while signing in. Please retry or contact support.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack mt="57px" mb={7} gap={2} justifyContent="center">
          <InputField label="Enter your email" name="email" autoCapitalize="off" />
          <InputField label="Password" name="password" type="password" />
          <Link
            onClick={() => navigation('/forget-password')}
            ml="auto"
            component="p"
            fontSize="1rem"
            lineHeight="24px"
            fontWeight="300"
          >
            Forgot password
          </Link>
        </Stack>{' '}
        {showAlert && (
          <CustomAlert
            title="Oops!"
            message={errorMsg!}
            startDecorator={<WarningIcon sx={{ mt: '2px', mx: '4px' }} fontSize="small" />}
            variant="soft"
            color="danger"
            endDecorator={
              <IconButton
                onClick={() => {
                  setShowAlert(false);
                }}
                variant="soft"
                size="sm"
                color="danger"
              >
                <CloseRoundedIcon />
              </IconButton>
            }
          />
        )}
        <Button
          type="submit"
          sx={(theme) => ({
            border: `2px solid ${theme.vars.palette.primary[500]}`,
            width: '100%',
          })}
          variant="outlined"
        >
          {loading ? <CircularProgress variant="solid" color="primary" size="sm" /> : 'Sign In'}
        </Button>
      </form>
    </FormProvider>
  );
};

export default Index;
