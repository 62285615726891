import { Box, CircularProgress, Stack } from '@mui/joy';
import { uniqBy } from 'lodash';
import { memo, useEffect, useState } from 'react';
import GigPreviewItem from '../GigPreviewItem';
import LoadMoreButton from '../LoadMoreButton';
import ThinGrayDarkLine from '../ThinGrayDarkLine';
import useMobileMode from '../../hooks/useMobileMode';
import { Colors } from '../../theme';
import { GigBaseItem, GigPreviewItemContext, IPaginator, ToastMode } from '../../types/interfaces';
import { getAllBookmarks } from '../../api/user';
import { useNavigate } from 'react-router-dom';
import GigPlaceholder from '../../assets/images/gig-placeholder.png';
import { useWindowHeight } from '@react-hook/window-size';
import { MOBILE_NAV_BAR_HEIGHT, MOBILE_PROFILE_HEADER_HEIGHT } from '../../config/constants';
import triggerToast from '../../utils/triggerToast';
import errorReporting from '../../utils/errorReporting';

const ProfileFeedSection = () => {
  const mobileMode = useMobileMode();
  const navigate = useNavigate();
  const [gigs, setGigs] = useState<GigBaseItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [paginator, setPaginator] = useState<IPaginator>();
  const [currentPage, setCurrentPage] = useState(1);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        gigs?.length ? setBtnLoading(true) : setLoading(true);
        const res = await getAllBookmarks({ page: currentPage });

        if (Array.isArray(res?.data?.gigs)) {
          setGigs(uniqBy([...(gigs as GigBaseItem[]), ...res?.data?.gigs], 'id'));
          setPaginator(res.data?.paginator);
        }
      } catch (error: any) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });

        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while getting your bookmarked gigs. Please retry or contact support.',
        });
      } finally {
        setLoading(false);
        setBtnLoading(false);
      }
    })();
  }, [currentPage]);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const horizontalPadding = mobileMode
    ? { paddingLeft: '16px', paddingRight: '16px' }
    : { paddingLeft: '88px', paddingRight: '88px' };

  const height = useWindowHeight();

  return (
    <Box
      sx={{
        ...horizontalPadding,
        backgroundColor: Colors.GrayLight,
        marginTop: mobileMode ? undefined : '40px',
        height: mobileMode
          ? height - MOBILE_NAV_BAR_HEIGHT - MOBILE_PROFILE_HEADER_HEIGHT
          : height - 40,
        overflowY: 'scroll',
      }}
    >
      {loading ? (
        <Box sx={{ marginTop: '8px', display: 'grid', placeItems: 'center' }}>
          <CircularProgress variant="solid" color="primary" size="sm" />
        </Box>
      ) : null}

      {gigs.map((gig, index) => (
        <Box key={gig.id}>
          <GigPreviewItem
            key={gig.id}
            isExternal={gig.isExternal}
            city={gig.city}
            id={gig.id}
            name={gig.name}
            imageUrl={gig.featuredImage || GigPlaceholder}
            location={gig.location}
            hourlyRate={gig.hourlyRate}
            status={gig.status}
            clientRating={undefined}
            estimatedPrice={gig.estimatedPrice}
            ISO8601DateTimeStart={gig.startDateAndTime}
            ISO8601DateTimeEnd={gig.endDateAndTime}
            bookmarked={gig.bookmarked}
            componentContext={GigPreviewItemContext.Feed}
            handleClick={() => {
              navigate(`/gigs/${gig.id}`);
            }}
            applicationStatus={gig.applicationStatus}
            zipCode={gig.zipCode}
            isFulltime={gig.isFulltime}
          />
          {index !== gigs.length - 1 && <ThinGrayDarkLine />}
        </Box>
      ))}

      <LoadMoreButton next={paginator?.next} onClick={handleLoadMore} loading={btnLoading} />
      {mobileMode && <Stack sx={{ marginBottom: '50px' }} />}
    </Box>
  );
};

export default memo(ProfileFeedSection);
