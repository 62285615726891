import { Box, IconButton, Stack } from '@mui/joy';
import { Skill, ToastMode } from '../../types/interfaces';
import { getSkills } from '../../api/skill';
import { Colors, FontSizes } from '../../theme';
import useMobileMode from '../../hooks/useMobileMode';
import { Button } from '@mui/material';
import Autocomplete from '@mui/joy/Autocomplete';
import { CrossIcon, SearchIcon } from '../../assets/svg';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { addUserSkills, deleteSkill, getUserSkills } from '../../api/user';
import { useWindowHeight } from '@react-hook/window-size';
import { MOBILE_NAV_BAR_HEIGHT, MOBILE_PROFILE_HEADER_HEIGHT } from '../../config/constants';
import triggerToast from '../../utils/triggerToast';
import analytics from '../../utils/analytics';
import errorReporting from '../../utils/errorReporting';

const ProfileMySkillsComponent = () => {
  const { user } = useAuth();
  const [userSkills, setUserSkills] = useState(user?.skills ? user?.skills : []);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const resetCurrentPage = () => setCurrentPage(1);
  const [loading, setLoading] = useState(false);
  const [refetching, setRefetching] = useState(false);

  /**
   * @description When the selected skills change, the paginator
   * nad the current page would be outdated as it would refer
   * to a db result that now has different filters
   */
  const paginatorAwareSetSelectedSkills = (value: Skill[]) => {
    resetCurrentPage();
    setSelectedSkills(value);
  };

  const fetchUserSkills = async () => {
    try {
      const response = await getUserSkills();
      setUserSkills(response?.data?.skills);
    } catch (error: any) {
      console.log('error', error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while getting the list of your skills. Please retry or contact support.',
      });
    }
  };

  const fetchSkillList = async () => {
    try {
      setLoading(true);
      const res = await getSkills(currentPage);
      const _filteredSkills = res?.data?.skills?.filter(
        (skill: Skill) =>
          !Object.keys(userSkills?.find((userSkill) => userSkill.id === skill.id) || {})?.length,
      );
      setSkills(_filteredSkills);
    } catch (error: any) {
      console.log('error', error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage: 'Something went wrong. Please retry or contact support.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchSkillList();
    })();
  }, [refetching]);

  /**
   * @description This method filters gigs by skills
   */
  const handleSkillsAdd = async () => {
    try {
      const skillsPayload = selectedSkills.map((skill) => ({
        skillId: skill.id,
        userId: user.id,
      }));
      await addUserSkills({ skills: skillsPayload });
      await fetchUserSkills();
      setSelectedSkills([]);
      setRefetching(!refetching);
      analytics.track('Skill Added To Profile');
    } catch (error: any) {
      console.log('error', error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while adding this skill. Please retry or contact support.',
      });
    }
  };

  const handleSkillDelete = async (skillId: string) => {
    try {
      await deleteSkill({
        skillId: skillId,
      });
      const _userSkills = userSkills?.filter((userSkill) => userSkill.id !== skillId);
      setUserSkills(_userSkills);
      await fetchUserSkills();
      setRefetching(!refetching);
      analytics.track('Skill Deleted From Profile');
    } catch (error: any) {
      console.log('error', error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while deleting this skill. Please retry or contact support.',
      });
    }
  };

  const isMobileMode = useMobileMode();
  const horizontalPadding = isMobileMode
    ? { paddingLeft: '0px', paddingRight: '0px' }
    : { paddingLeft: '88px', paddingRight: '88px' };

  const mobileMode = useMobileMode();
  const height = useWindowHeight();

  return (
    <Box
      sx={{
        ...horizontalPadding,
        backgroundColor: Colors.GrayLight,
        marginTop: mobileMode ? undefined : '40px',
      }}
    >
      <Stack
        direction="column"
        sx={{
          display: 'flex',
          width: '100%',
          height: mobileMode
            ? height - MOBILE_NAV_BAR_HEIGHT - MOBILE_PROFILE_HEADER_HEIGHT
            : height - 40,
          overflowY: 'scroll',
        }}
      >
        <Stack direction="row" mt={4} gap={2} sx={{}}>
          <Autocomplete
            startDecorator={<SearchIcon />}
            multiple
            freeSolo
            limitTags={6}
            placeholder="Add new skill"
            options={skills}
            loading={loading}
            loadingText="Please wait.."
            onChange={(_, value, r) => {
              if (r !== 'createOption') {
                paginatorAwareSetSelectedSkills(value as Skill[]);
              }
            }}
            getOptionLabel={(option: any) => option.name}
            value={selectedSkills}
            sx={{
              borderColor: Colors.Blue,
              borderRadius: '6px',
              marginRight: '16px',
              marginLeft: '16px',
              width: '100%',
            }}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{
            marginTop: '12px',
            marginBottom: '12px',
            marginRight: '16px',
            marginLeft: '16px',
          }}
        >
          <Button
            variant="contained"
            onClick={handleSkillsAdd}
            disabled={selectedSkills.length === 0}
            style={{
              minHeight: '32px',
              height: '40px',
              textTransform: 'none',
            }}
            sx={{
              color: Colors.White,
              borderRadius: '4px',
              backgroundColor: Colors.DarkBlue,
              width: '100%',
            }}
          >
            Add
          </Button>
        </Stack>

        <Box
          sx={{
            marginTop: isMobileMode ? '0px' : '16px',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            marginBottom: '16px',
          }}
        >
          {userSkills?.map((item) => {
            return (
              <IconButton
                key={item?.id}
                sx={{
                  ...FontSizes.Header3W700,
                  color: Colors.White,
                  backgroundColor: Colors.Blue,
                  marginTop: '16px',
                  marginLeft: isMobileMode ? '16px' : '8px',
                  marginRight: isMobileMode ? '' : '8px',
                  borderRadius: '0px',
                  padding: '0px 16px',
                  gap: 3,
                  '&:hover': {
                    backgroundColor: Colors.Blue,
                  },
                }}
              >
                {item?.skill}
                <span
                  onClick={() => {
                    handleSkillDelete(item?.id);
                  }}
                >
                  <CrossIcon />
                </span>
              </IconButton>
            );
          })}
        </Box>
      </Stack>
    </Box>
  );
};

export default ProfileMySkillsComponent;
