import { Box, Typography } from '@mui/joy';
import { Colors, FontSizes } from '../../theme';
import { LightningIcon } from '../../assets/svg';

const QuickHireLabel = () => {
  return (
    <Box
      sx={{
        backgroundColor: Colors.Yellow,
        height: '19px',
        paddingRight: '4px',
        paddingLeft: '4px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <LightningIcon />

      <Typography
        sx={{
          //height: '19px',
          ...FontSizes.Header4W700,
          verticalAlign: 'middle',
        }}
      >
        Quick Hire!
      </Typography>
    </Box>
  );
};

export default QuickHireLabel;
