import { Box, Radio, Stack } from '@mui/joy';
import { GigPayRangeFilterBodyProps } from '../../types/interfaces';
import SubmitButton from '../SubmitButton';
import GigFilterBodyHeader from '../GigFilterBodyHeader';
import Slider from '@mui/material/Slider';
import { Colors } from '../../theme';
import {
  MaxHourlyPayRange,
  MaxYearlyPayRange,
  PayRangeYearlyResetPayload,
} from '../../pages/Dashboard/tabs/FindGigs';
import { useEffect, useState } from 'react';

const GigPayRangeFilterBody = ({
  filterName,
  filterContent,
  setFilterContent,
  onReset,
  onBack,
  containerStyle,
}: GigPayRangeFilterBodyProps) => {
  const [tmpFilterContent, setTmpFilterContent] = useState(filterContent);

  useEffect(() => {
    setTmpFilterContent(filterContent);
  }, [filterContent]);

  return (
    <Stack sx={{ ...styles.container, ...containerStyle }}>
      <GigFilterBodyHeader
        filterName={filterName}
        onBack={onBack}
        onReset={() => {
          onReset();
          onBack();
        }}
      />

      <Box
        sx={styles.radioButtonContainer}
        onClick={() => {
          onReset();
        }}
      >
        <Radio
          value="hourly"
          label="Hourly"
          checked={tmpFilterContent.mode === 'hourly'}
          sx={styles.radioText}
        />
      </Box>

      {tmpFilterContent.mode === 'hourly' && (
        <>
          <Box sx={styles.sliderContainer}>
            <Slider
              min={0}
              max={MaxHourlyPayRange}
              getAriaLabel={() => 'Pay Range'}
              value={[tmpFilterContent.min, tmpFilterContent.max]}
              onChange={(event, newValue) => {
                setTmpFilterContent({
                  mode: 'hourly',
                  min: (newValue as number[])[0],
                  max: (newValue as number[])[1],
                });
              }}
              valueLabelDisplay="auto"
              sx={styles.slides}
            />
          </Box>

          <Stack direction="row" sx={styles.payRangeLabelsContainer}>
            <Box sx={styles.rangeLabel}>${tmpFilterContent.min}</Box>
            <Box sx={{ ...styles.rangeLabel, marginLeft: 'auto' }}>${tmpFilterContent.max}</Box>
          </Stack>
        </>
      )}

      <Box
        sx={styles.radioButtonContainer}
        onClick={() => {
          setTmpFilterContent(PayRangeYearlyResetPayload);
        }}
      >
        <Radio
          value="yearly"
          label="Yearly"
          checked={tmpFilterContent.mode === 'yearly'}
          sx={styles.radioText}
        />
      </Box>

      {tmpFilterContent.mode === 'yearly' && (
        <>
          <Box sx={styles.sliderContainer}>
            <Slider
              step={100}
              min={0}
              max={MaxYearlyPayRange}
              getAriaLabel={() => 'Pay Range'}
              value={[tmpFilterContent.min, tmpFilterContent.max]}
              onChange={(event, newValue) => {
                setTmpFilterContent({
                  mode: 'yearly',
                  min: (newValue as number[])[0],
                  max: (newValue as number[])[1],
                });
              }}
              valueLabelDisplay="auto"
              sx={styles.slides}
            />
          </Box>

          <Stack direction="row" sx={styles.payRangeLabelsContainer}>
            <Box sx={styles.rangeLabel}>${tmpFilterContent.min}</Box>
            <Box sx={{ ...styles.rangeLabel, marginLeft: 'auto' }}>${tmpFilterContent.max}</Box>
          </Stack>
        </>
      )}

      <SubmitButton
        label={'Apply'}
        isLoading={false}
        onClick={() => {
          setFilterContent(tmpFilterContent);
          onBack();
        }}
      />
    </Stack>
  );
};

export default GigPayRangeFilterBody;

const styles = {
  container: {
    display: 'flex',
    flex: 1,
  },
  sliderContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  slides: {
    width: '90%',
  },
  rangeLabel: {
    border: '1px solid',
    borderRadius: '6px',
    borderColor: Colors.GrayDark,
    color: Colors.Black,
    fontFamily: 'Montserrat, sans-serif',
    weight: 600,
    fontSize: '14px',
    height: '33px',
    width: '76px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  payRangeLabelsContainer: {
    marginTop: '12px',
  },
  radioButtonContainer: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  radioText: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    fontWeight: 300,
  },
};
