import { Box, Button, Stack, Typography } from '@mui/joy';
import {
  GigDetailsFooterBlockUnassignedGigProps,
  GigStatus,
  ToastMode,
} from '../../types/interfaces';
import { Colors, FontSizes } from '../../theme';
import React, { useCallback, useState } from 'react';
import { CheckboxChecked, CheckboxUnchecked } from '../../assets/svg';
import useMobileMode from '../../hooks/useMobileMode';
import { applyToGig } from '../../api/gigs';
import triggerToast from '../../utils/triggerToast';
import analytics from '../../utils/analytics';
import errorReporting from '../../utils/errorReporting';

const GigDetailsFooterBlockUnassignedGig = ({
  withdrawPolicyUrl,
  frontlineTermsOfUseUrl,
  biometricDataPolicyUrl,
  biometricDataConsentFormUrl,
  gigStatus,
  gigId,
  setGig,
}: GigDetailsFooterBlockUnassignedGigProps) => {
  const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const mobileMode = useMobileMode();
  const mobileAwareBorderRadius = mobileMode ? '0px' : '16px';
  const mobileAwareHorizontalMargin = mobileMode ? '8px 0 0 0' : '16px 48px 0 48px';

  const [isLoading, setIsLoading] = useState(false);

  const onApplyClick = useCallback(async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      setError(null);

      const response = await applyToGig({
        gigId,
        acceptApplicationTerms: true,
      });

      setGig(response.data.updatedGig);

      analytics.track('Apply To Gig');
    } catch (error: any) {
      console.error(error);
      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while applying to this gig. Please retry or contact support.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, gigId, setGig]);

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.White,
        padding: '16px',
        borderRadius: mobileAwareBorderRadius,
        margin: mobileAwareHorizontalMargin,
      }}
    >
      <Typography
        sx={{
          color: Colors.Black,
          ...FontSizes.BodyRegularW300,
          marginBottom: '24px',
          marginTop: '12px',
        }}
      >
        Learn about Frontliner{' '}
        <a href={withdrawPolicyUrl} style={{ color: Colors.Blue }}>
          withdraw policy
        </a>
      </Typography>

      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'flex-start', marginBottom: '16px' }}
      >
        <Box
          onClick={() => {
            setAreTermsAccepted(!areTermsAccepted);
          }}
        >
          {areTermsAccepted ? <CheckboxChecked /> : <CheckboxUnchecked />}
        </Box>
        <Typography
          sx={{
            ...FontSizes.BodyRegularW300,
            color: Colors.Black,
            marginLeft: '6px',
            height: '24px',
          }}
        >
          I accept all the Terms and Conditions listed below
        </Typography>
      </Stack>

      <Typography sx={{ ...FontSizes.MediumW600, color: Colors.Red, marginBottom: '12px' }}>
        {error}
      </Typography>

      <Button
        sx={(theme) => ({
          border: `2px solid ${theme.vars.palette.primary[500]}`,
          marginBottom: '24px',
          width: '100%',
        })}
        type="button"
        variant="outlined"
        onClick={onApplyClick}
        loading={isLoading}
        disabled={
          gigStatus === GigStatus.Completed ||
          gigStatus === GigStatus.Cancelled ||
          !areTermsAccepted
        }
      >
        Apply
      </Button>

      <Typography sx={{ ...FontSizes.BodyRegularW300, marginBottom: '36px' }}>
        By accepting this job, I agree to Frontline{' '}
        <a href={frontlineTermsOfUseUrl} style={{ color: Colors.Blue }}>
          Terms of Use
        </a>
        , I also agree to Frontline's{' '}
        <a href={biometricDataPolicyUrl} style={{ color: Colors.Blue }}>
          Biometric Data Policy
        </a>
        , and{' '}
        <a href={biometricDataConsentFormUrl} style={{ color: Colors.Blue }}>
          Biometric Data Consent Form
        </a>
        , and consent to the collection, retention and use of my biometric data as described in
        those documents.
      </Typography>
    </Stack>
  );
};

export default React.memo(GigDetailsFooterBlockUnassignedGig);
