import { Radio, Typography } from '@mui/joy';
import { subTitle } from '..';
import RadioGroupController from '../../../components/RadioGroupController';
import useAuth from '../../../hooks/useAuth';

const HearAboutUs = () => {
  const { user } = useAuth();
  return (
    <>
      <Typography mt={3} textColor="#6A6A68" {...subTitle}>
        Hi {user?.firstName}, glad to have you on Frontline Gig. Answer these questions to serve you
        better
      </Typography>
      <Typography mt={3} fontSize={28} lineHeight="40px" fontWeight="bold">
        How did you hear <br />
        about us?
      </Typography>

      <RadioGroupController
        name="referralSource"
        sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', mt: 3 }}
      >
        <Radio value="craig’sList" label="Craig’s list" />
        <Radio value="friend" label="From a friend" />
        <Radio value="instagram" label="Instagram" />
        <Radio value="other" label="Other" />
      </RadioGroupController>
    </>
  );
};

export default HearAboutUs;
