import { Box, Button, Stack, Typography } from '@mui/joy';
import { Colors, FontSizes } from '../../theme';
import InputField from '../InputField';
import { SummaryGigWorkPicsProps } from '../../types/interfaces';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UploadIcon } from '../../assets/svg';
import UploadButton from '../UploadButton';

const SummaryGigWorkPics = ({
  pictureFieldFormName,
  commentFieldFormName,
  title,
}: SummaryGigWorkPicsProps) => {
  const [preview, setPreview] = useState<any>();
  const {
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();
  const formValues = getValues();

  const picture = formValues[pictureFieldFormName];

  useEffect(() => {
    let objectUrl = '';
    if (picture) {
      objectUrl = URL.createObjectURL(picture);
      setPreview(objectUrl);
    }
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [picture, pictureFieldFormName]);

  watch();

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.White,
        borderRadius: '16px',
        padding: '16px',
        marginTop: '16px',
      }}
    >
      <Stack
        direction="column"
        sx={{
          maxWidth: '310px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: Colors.Black,
              ...FontSizes.Header4W700,
              marginBottom: '16px',
              marginTop: '4px',
            }}
          >
            {title}
          </Typography>
        </Stack>

        {preview ? (
          <Stack
            direction="column"
            sx={{
              width: '100%',
            }}
          >
            <img
              src={preview}
              width={'100%'}
              height={'200px'}
              alt={pictureFieldFormName}
              style={{
                objectFit: 'cover',
                borderRadius: '6px',
                marginBottom: '16px',
                maxHeight: '200px',
              }}
            />

            <Button
              sx={{ width: '150px', marginBottom: '20px' }}
              onClick={() => setPreview(undefined)}
            >
              Re-upload
            </Button>
          </Stack>
        ) : (
          <Box sx={{ width: '100%' }}>
            <UploadButton
              variant="rectangle"
              name={pictureFieldFormName}
              buttonText="Upload image"
              containerStyles={{ m: 0 }}
              icon={<UploadIcon />}
              formKey={pictureFieldFormName}
            />
            <Typography sx={{ ...FontSizes.SmallW300, color: Colors.Red, marginTop: '8px' }}>
              {(errors[pictureFieldFormName]?.message as string) ?? ''}
            </Typography>
          </Box>
        )}
      </Stack>

      <Stack direction="column" sx={{ marginTop: '8px' }}>
        <InputField label="Add your comment here" name={commentFieldFormName} />
      </Stack>
    </Stack>
  );
};

export default SummaryGigWorkPics;
