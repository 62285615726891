import { Stack, Typography, Box } from '@mui/joy';
import { useCallback } from 'react';
import { ChevronIcon } from '../../assets/svg/index';
import { FontSizes } from '../../theme';
import { UnlockMoreGigsEntryProps } from '../../types/interfaces';

const Bar = () => {
  return (
    <Stack direction="row" sx={{ width: '100%' }} spacing={'2px'}>
      {['1', '2', '3'].map((item) => (
        <Box
          key={item}
          sx={{
            display: 'flex',
            flex: 1,
            height: 8,
            backgroundColor: 'white',
            opacity: 0.5,
          }}
        />
      ))}
    </Stack>
  );
};

const UnlockMoreGigsEntry = ({ onClick }: UnlockMoreGigsEntryProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      onClick();
    },
    [onClick],
  );

  return (
    <Stack
      onClick={handleClick}
      sx={{
        backgroundColor: '#82DA9D',
        height: '120px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderRadius: '16px',
        paddingLeft: '24px',
        paddingRight: '24px',
      }}
    >
      <Stack
        direction="row"
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            flex: 1,
            color: '#000000',
            ...FontSizes.Header2W700,
            marginBottom: '8px',
            paddingRight: '20px',
          }}
        >
          Unlock more Gigs
        </Typography>

        <Box>
          <ChevronIcon />
        </Box>
      </Stack>

      <Bar />

      <Typography
        sx={{
          color: '#000000',
          ...FontSizes.Header4W700,
          marginTop: '12px',
        }}
      >
        In 2 simple steps
      </Typography>
    </Stack>
  );
};

export default UnlockMoreGigsEntry;
