import { Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';
import InputField from '../../../components/InputField';

const StepFive = () => {
  const { getValues } = useFormContext();

  return (
    <>
      <Typography mt={3} component="h4" fontSize="1.75rem" lineHeight="40px" fontWeight="700">
        {getValues('firstName')}, let’s set up your login details
      </Typography>
      <Stack mt="27px" mb="46px" gap={2} justifyContent="center">
        <InputField label="Enter your email" name="email" autoCapitalize="off" />
        <InputField label="Password" type="password" name="password" />
      </Stack>
    </>
  );
};

export default StepFive;
