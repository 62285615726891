import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { BrowserTracing } from '@sentry/tracing';

const errorReporting = {
  init: () => {
    if (process.env.NODE_ENV === 'test') return;
    if (process.env.REACT_APP_ENVIRONMENT === 'local') return;

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  },
  captureException: (exception: any, captureContext?: CaptureContext) => {
    if (process.env.NODE_ENV === 'test') return;
    if (process.env.REACT_APP_ENVIRONMENT === 'local') return;

    Sentry.captureException(exception, captureContext);
  },
};

export default errorReporting;
