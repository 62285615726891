import { Box, Stack, Typography } from '@mui/joy';
import { ClockStatsIcon, SkillStatsIcon, VerifiedIcon } from '../../assets/svg';
import useMobileMode from '../../hooks/useMobileMode';
import { Colors, FontSizes } from '../../theme';
import ProfileAvatarImage from '../../assets/images/profile-header-avatar.png';
import useAuth from '../../hooks/useAuth';
import { useEffect, useState } from 'react';
import zipcodes from 'zipcodes';

const SkillsSection = () => {
  const [limit, setLimit] = useState(5);
  const { user } = useAuth();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {user?.skills?.slice(0, limit)?.map(({ id, skill, status }) => {
          return (
            <Typography
              key={id}
              sx={{
                ...FontSizes.Header4W700,
                color: Colors.White,
                marginBottom: '10px',
                backgroundColor: Colors.Blue,
                width: 'fit-content',
                padding: '2px 4px 2px 4px',
                display: 'flex',
              }}
            >
              {status === 'Approved' && (
                <Box mr={1}>
                  <VerifiedIcon />
                </Box>
              )}{' '}
              {skill}
            </Typography>
          );
        })}
        {user?.skills && user?.skills?.length > limit && (
          <Typography
            sx={{
              ...FontSizes.Header4W700,
              color: Colors.Blue,
              marginBottom: '4px',
              backgroundColor: Colors.GrayLight,
              width: 'fit-content',
              padding: '2px 4px 2px 4px',
            }}
            onClick={() => {
              if (user?.skills) {
                setLimit(user?.skills?.length);
              }
            }}
          >
            More
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const ProfileMyViewComponent = () => {
  const mobileMode = useMobileMode();
  const { user, refetchWithoutLoadingUI } = useAuth();
  const mobileAwareBorderRadius = mobileMode ? '10px' : '16px';
  const mobileAwareHorizontalMargin = mobileMode ? '0px 16px 11px 16px' : '16px 48px 0 48px';

  const containerStyle = {
    borderRadius: mobileAwareBorderRadius,
    margin: mobileAwareHorizontalMargin,
    padding: '24px',
    backgroundColor: Colors.White,
  };

  useEffect(() => {
    refetchWithoutLoadingUI();
  }, []);

  return (
    <>
      {mobileMode && (
        <Box
          sx={{
            flexDirection: 'column',
            display: 'flex',
            marginBottom: '19px',
            alignItems: 'center',
            '& img': {
              borderRadius: 100,
              width: 120,
              height: 120,
              border: '5px solid #fff',
              objectFit: 'cover',
            },
          }}
        >
          <img
            src={user?.profileURL || ProfileAvatarImage}
            alt="profile"
            style={{ marginTop: mobileMode ? '120px' : '0px', objectFit: 'cover' }}
          />
          <Typography
            sx={{
              color: Colors.Black,
              ...FontSizes.Header2W700,
              pointerEvents: 'none',
              marginRight: '15px',
            }}
          >
            {user?.firstName}
          </Typography>

          <Typography
            sx={{
              color: Colors.Black,
              ...FontSizes.BodyLargeW300,
              pointerEvents: 'none',
            }}
          >
            {zipcodes.lookup(user.zipCode)?.city || ''}, {zipcodes.lookup(user.zipCode)?.state}
          </Typography>
        </Box>
      )}

      <Stack
        direction="column"
        sx={{
          ...containerStyle,
          marginTop: mobileMode ? undefined : '40px',
        }}
      >
        <Typography
          sx={{
            ...FontSizes.Header4W700,
            color: 'black',
            marginBottom: '16px',
          }}
        >
          Lifetime stats
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <SkillStatsIcon />
              <Typography
                sx={{
                  ...FontSizes.Header3W700,
                  color: Colors.Green,
                  marginBottom: '4px',
                }}
              >
                {user?.jobs}
              </Typography>
            </Box>

            <Typography
              sx={{
                ...FontSizes.SmallW300,
                color: 'black',
                marginBottom: '4px',
              }}
            >
              Jobs
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <ClockStatsIcon />
              <Typography
                sx={{
                  ...FontSizes.Header3W700,
                  color: Colors.Green,
                  marginBottom: '4px',
                }}
              >
                {user?.hours}
              </Typography>
            </Box>

            <Typography
              sx={{
                ...FontSizes.SmallW300,
                color: 'black',
                marginBottom: '4px',
              }}
            >
              Hours
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Stack
        direction="column"
        sx={{
          ...containerStyle,
        }}
      >
        <Typography
          sx={{
            ...FontSizes.Header4W700,
            color: 'black',
            marginBottom: '16px',
          }}
        >
          About
        </Typography>

        <Typography
          sx={{
            ...FontSizes.BodyLargeW300,
            color: 'black',
            marginBottom: '0px',
          }}
        >
          {user.bio}
        </Typography>
      </Stack>

      <Stack
        direction="column"
        sx={{
          ...containerStyle,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            sx={{
              ...FontSizes.Header4W700,
              color: 'black',
              marginBottom: '16px',
            }}
          >
            Skills
          </Typography>
        </Box>

        <SkillsSection />
      </Stack>
      {mobileMode && <Stack sx={{ marginBottom: '60px' }}></Stack>}
    </>
  );
};

export default ProfileMyViewComponent;
