import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, IconButton } from '@mui/joy';
import { isAxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getSkills } from '../../api/skill';
import { onBoarding } from '../../api/user';
import { BackIcon } from '../../assets/svg';
import { backBtnStyles } from '../../config/styleConstants';
import useAuth from '../../hooks/useAuth';
import AuthLayout from '../../layout/authLayout';
import { Skill } from '../../types/interfaces';
import { OnBoardingSchema } from '../../validation';
import GigLocation from './steps/GigLocation';
import HearAboutUs from './steps/HearAboutUs';
import Skills from './steps/Skills';
import SmartPhone from './steps/SmartPhone';
import UploadPhoto from './steps/UploadPhoto';
import BasicGigTraining from './steps/BasicGigTraining';
import errorReporting from '../../utils/errorReporting';

export const subTitle = { fontSize: 20, lineHeight: '32px', fontWeight: 'bold' };

const defaultValues = {
  referralSource: '',
  hasWorkingCamera: '',
  willArriveByCar: '',
  photo: undefined,
  skills: [] as string[],
};

const Onboarding = () => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const [skillsLabels, setSkillsLabels] = useState<Skill['name'][]>([]);
  const [selectedSkillsLabels, setSelectedSkillsLabels] = useState<Skill['name'][]>([]);

  const addSelectedSkill = useCallback(
    ({ skillLabel }: { skillLabel: Skill['name'] }) => {
      setSelectedSkillsLabels([...selectedSkillsLabels, skillLabel]);
    },
    [selectedSkillsLabels],
  );

  const removeSelectedSkill = useCallback(
    (skillLabel: string) => {
      setSelectedSkillsLabels(selectedSkillsLabels.filter((item) => item !== skillLabel));
    },
    [selectedSkillsLabels],
  );

  useEffect(() => {
    (async () => {
      const res = await getSkills();
      setSkills(res?.data?.skills);
      setSkillsLabels(res?.data?.skills?.map((skill: Skill) => skill.name));
    })();
  }, []);

  const stepComponents = [
    <HearAboutUs />,
    <SmartPhone />,
    <GigLocation />,
    <UploadPhoto />,
    <BasicGigTraining />,
    <Skills
      addSelectedSkill={addSelectedSkill}
      skillsLabels={skillsLabels}
      selectedSkillsLabels={selectedSkillsLabels}
      removeSelectedSkill={removeSelectedSkill}
    />,
  ];

  const [activeStep, setActiveStep] = useState<number>(0);
  const { setUser, user } = useAuth();

  const methods = useForm({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(OnBoardingSchema[activeStep]),
    mode: 'onChange',
  });

  const { trigger, handleSubmit, watch, formState, setError } = methods;
  watch();
  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = async (values: any) => {
    try {
      await trigger();

      if (selectedSkillsLabels.length === 0) {
        setError('skills', {
          type: 'manual',
          message: 'Please select at least one skill',
        });
      }

      const formData = new FormData();
      formData.append('profileURL', values?.photo, values?.photo.name);
      formData.append('referralSource', values?.referralSource);
      formData.append('hasWorkingCamera', values?.hasWorkingCamera);
      formData.append('willArriveByCar', values?.willArriveByCar);
      formData.append('isBasicGigTrainingCompleted', 'true');

      skills
        .filter((skill) => selectedSkillsLabels.includes(skill.name))
        .forEach((skill, index) => {
          formData.append(`skills[${index}][skillId]`, skill.id);
          formData.append(`skills[${index}][userId]`, user.id as string);
        });

      await onBoarding(formData);

      setUser({
        ...user,
        isOnBoardingComplete: true,
      });
    } catch (error) {
      errorReporting.captureException(error, {
        level: 'error',
      });
      if (isAxiosError(error)) {
        console.log(error.response?.data?.message);
      }
    }
  };

  return (
    <AuthLayout>
      {activeStep !== 0 && (
        <IconButton sx={backBtnStyles} onClick={handleBack}>
          <BackIcon />
        </IconButton>
      )}

      <FormProvider {...methods}>
        <form>
          {stepComponents[activeStep]}

          {activeStep === stepComponents.length - 1 ? (
            <Button
              sx={(theme) => ({
                border: `2px solid ${theme.vars.palette.primary[500]}`,
                marginTop: 4,
                width: '100%',
              })}
              type="button"
              disabled={formState.isSubmitting}
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
            >
              {formState.isSubmitting ? (
                <CircularProgress variant="solid" color="primary" size="sm" />
              ) : (
                'Submit'
              )}
            </Button>
          ) : (
            <Button
              sx={(theme) => ({
                border: `2px solid ${theme.vars.palette.primary[500]}`,
                marginTop: 4,
                width: '100%',
              })}
              type="button"
              variant="outlined"
              onClick={handleNext}
              disabled={formState.isSubmitting}
            >
              Next
            </Button>
          )}
        </form>
      </FormProvider>
    </AuthLayout>
  );
};

export default Onboarding;
