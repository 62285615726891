import { Stack, styled, Typography } from '@mui/joy';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import { Colors, FontSizes } from '../../../../theme';
import { DateTimePicker } from '@mui/x-date-pickers';

const CustomTextField = styled(TextField)({
  input: {
    '&.Mui-disabled': {
      color: 'rgb(0,0,0)',
      WebkitTextFillColor: 'rgb(0,0,0)',
    },
  },
});

const SummaryGigTime = () => {
  const {
    setValue,
    formState: { errors, defaultValues },
  } = useFormContext();

  const [startDateTime, setStartDateTime] = useState<Dayjs | null>(
    dayjs(defaultValues?.startDateAndTime),
  );
  const [endDateTime, setEndDateTime] = useState<Dayjs | null>(
    dayjs(defaultValues?.endDateAndTime),
  );

  const [isStartDateTimePickerOpen, setIsStartDateTimePickerOpen] = useState(false);
  const [isEndDateTimePickerOpen, setIsEndDateTimePickerOpen] = useState(false);

  const handleOpenStartDateTimePicker = () => setIsStartDateTimePickerOpen(true);
  const handleCloseStartDateTimePicker = () => setIsStartDateTimePickerOpen(false);

  const handleOpenEndDateTimePicker = () => setIsEndDateTimePickerOpen(true);
  const handleCloseEndDateTimePicker = () => setIsEndDateTimePickerOpen(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        direction="column"
        sx={{
          backgroundColor: Colors.White,
          borderRadius: '16px',
          padding: '16px',
          marginTop: '16px',
        }}
      >
        <Stack direction="column">
          <Stack direction="column" sx={{ marginRight: '32px', marginBottom: '16px' }}>
            <DateTimePicker
              label="Start Date/Time"
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  disabled
                  onClick={() => {
                    handleCloseEndDateTimePicker();

                    handleOpenStartDateTimePicker();
                  }}
                />
              )}
              value={startDateTime}
              open={isStartDateTimePickerOpen}
              onOpen={handleOpenStartDateTimePicker}
              onClose={handleCloseStartDateTimePicker}
              onChange={(newValue: dayjs.Dayjs | null) => {
                setStartDateTime(newValue);
                setValue('startDateAndTime', newValue?.toISOString() ?? '', {
                  shouldValidate: true,
                });
              }}
            />

            <Typography sx={{ ...FontSizes.SmallW300, color: Colors.Red, marginTop: '8px' }}>
              {(errors?.startDateAndTime?.message as string) ?? ''}
            </Typography>
          </Stack>
          <Stack direction="column">
            <DateTimePicker
              label="End Date/Time"
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  disabled
                  onClick={() => {
                    handleCloseStartDateTimePicker();

                    handleOpenEndDateTimePicker();
                  }}
                />
              )}
              value={endDateTime}
              open={isEndDateTimePickerOpen}
              onOpen={handleOpenEndDateTimePicker}
              onClose={handleCloseEndDateTimePicker}
              onChange={(newValue) => {
                setEndDateTime(newValue);
                setValue('endDateAndTime', newValue?.toISOString() ?? '', {
                  shouldValidate: true,
                });
              }}
            />

            <Typography sx={{ ...FontSizes.SmallW300, color: Colors.Red, marginTop: '8px' }}>
              {(errors?.endDateAndTime?.message as string) ?? ''}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
};

export default SummaryGigTime;
