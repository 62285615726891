import { Box, CircularProgress, Stack, Typography } from '@mui/joy';
import { Button } from '@mui/material';

import { Colors, FontSizes } from '../../../theme';
import { City, ToastMode } from '../../../types/interfaces';
import { useWindowWidth } from '@react-hook/window-size';
import triggerToast from '../../../utils/triggerToast';
import useAnalyticsPageView from '../../../hooks/useAnalyticsPageView';
import analytics from '../../../utils/analytics';
import CityStateDropdown from '../../../components/CityStateDropdown';
import errorReporting from '../../../utils/errorReporting';
import { useEffect, useState } from 'react';
import getCityStateTemplate from '../../../utils/getCityStateTemplate';
import {
  addCity as addCityApiCall,
  deleteCity as deleteCityApiCall,
  getCities,
} from '../../../api/cities';

const EditAvailableCities = () => {
  useAnalyticsPageView('Admin - Edit Available Cities');
  const [error, setError] = useState<string>('');
  const resetError = () => setError('');
  const width = useWindowWidth();
  const [availableCities, setAvailableCities] = useState<City[]>([]);
  const [tmpCityName, setTmpCityName] = useState<string | null>(null);
  const resetTmpCityName = () => setTmpCityName(null);
  const [isAddingCity, setIsAddingCity] = useState(false);
  const [deleteCityIdConfirm, setDeleteCityIdConfirm] = useState<string | null>(null);
  const resetDeleteCityIdConfirm = () => setDeleteCityIdConfirm(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await getCities();
        setAvailableCities(res?.data?.cities);
      } catch (error) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });
      }
    })();
  }, []);

  const addCity = async () => {
    resetError();

    try {
      setIsAddingCity(true);

      await addCityApiCall({ name: tmpCityName as string });
      await analytics.track('Admin - City Added');

      resetTmpCityName();

      try {
        const res = await getCities();
        setAvailableCities(res?.data?.cities);
      } catch (error) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });
      }

      // do the request here
    } catch (error: any) {
      console.error(error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while adding a city. Please retry or contact support.',
      });
    } finally {
      setIsAddingCity(false);
    }
  };

  const deleteCity = async ({ id }: { id: string }) => {
    resetError();

    try {
      await deleteCityApiCall({ id });
      await analytics.track('Admin - City Deleted');

      resetTmpCityName();

      try {
        const res = await getCities();
        setAvailableCities(res?.data?.cities);
      } catch (error) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });
      }

      // do the request here
    } catch (error: any) {
      console.error(error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while deleting a city. Please retry or contact support.',
      });
    }
  };

  return (
    <Stack
      sx={(theme) => ({
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'scroll',
        marginRight: '30px',
        marginLeft: '30px',
      })}
      direction="column"
    >
      <Stack
        direction="column"
        sx={{
          padding: '48px',
          width: width * 0.45,
          height: '100vh',
        }}
      >
        <Typography
          sx={{ fontWeight: 'bold', fontSize: '20px', marginTop: '80px', marginBottom: '40px' }}
        >
          Edit Available Cities
        </Typography>

        {availableCities.map((city) => (
          <Stack
            direction="row"
            sx={{
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              borderBottom: '1px solid grey',
            }}
          >
            <Typography>{city.name}</Typography>

            <Box sx={{ display: 'flex', flex: 1 }}></Box>

            {deleteCityIdConfirm === city.id ? (
              <Stack direction="row">
                <Box onClick={() => deleteCity({ id: city.id })}>
                  <Typography
                    sx={{
                      color: 'red',
                      marginTop: '4px',
                      marginBottom: '4px',
                      marginRight: '8px',
                      fontWeight: 'bold',
                    }}
                  >
                    Confirm
                  </Typography>
                </Box>
                <Box onClick={resetDeleteCityIdConfirm}>
                  <Typography
                    sx={{
                      color: 'green',
                      marginTop: '4px',
                      marginBottom: '4px',
                      marginRight: '8px',
                      fontWeight: 'bold',
                    }}
                  >
                    Cancel
                  </Typography>
                </Box>
              </Stack>
            ) : (
              <Box onClick={() => setDeleteCityIdConfirm(city.id)}>
                <Typography
                  sx={{
                    color: 'red',
                    marginTop: '4px',
                    marginBottom: '4px',
                    marginRight: '8px',
                    fontWeight: 'bold',
                  }}
                >
                  Delete
                </Typography>
              </Box>
            )}
          </Stack>
        ))}

        <Typography sx={{ fontWeight: 'bold', marginTop: '60px' }}>Or add new city </Typography>
        <Box sx={{ width: '100%', height: '8px', backgroundColor: Colors.GrayDark }}></Box>

        <CityStateDropdown
          onCityStateSelected={({ city, state }) => {
            setTmpCityName(getCityStateTemplate({ city, state }));
          }}
        />

        {error && (
          <Typography sx={{ ...FontSizes.SmallW300, color: Colors.Red, marginTop: '16px' }}>
            {
              'An error occurred while creating your Gig. Please try again later or contact support.'
            }
          </Typography>
        )}

        <Button
          sx={(theme) => ({
            border: `2px solid ${Colors.Blue}`,
            marginBottom: '72px',
            marginTop: '24px',
            width: '100%',
          })}
          type="button"
          variant="outlined"
          onClick={addCity}
          disabled={tmpCityName === null}
        >
          {isAddingCity ? <CircularProgress variant="solid" color="primary" size="sm" /> : 'Add'}
        </Button>

        <Typography sx={{ marginBottom: '80px' }}>.</Typography>
      </Stack>
    </Stack>
  );
};

export default EditAvailableCities;
