import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  CircularProgress,
  Input,
  IconButton,
  Textarea,
} from '@mui/joy';
import useMobileMode from '../../hooks/useMobileMode';
import { Colors, FontSizes } from '../../theme';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText } from '@mui/material';
import { editProfileSchema } from '../../validation';
import { useState } from 'react';
import styled from '@emotion/styled';
import useAuth from '../../hooks/useAuth';
import ProfileAvatarImage from '../../assets/images/profile-header-avatar.png';
import { editProfile } from '../../api/user';
import { PlusSymbolIcon } from '../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { MOBILE_NAV_BAR_HEIGHT, MOBILE_PROFILE_HEADER_HEIGHT } from '../../config/constants';
import { useWindowHeight } from '@react-hook/window-size';
import triggerToast from '../../utils/triggerToast';
import { ToastMode } from '../../types/interfaces';
import analytics from '../../utils/analytics';
import errorReporting from '../../utils/errorReporting';

export const EditInputStyled = styled(Input)({
  ...FontSizes.normalCaptionW700,
  color: Colors.Black,
  marginBottom: '12px',
  backgroundColor: 'transparent',
  border: 'none',
  paddingInline: 'inherit',
  '& .Joy-disabled': {
    color: 'gray',
  },

  '&:before': {
    all: 'unset',
  },
});

export const EditTextareaStyled = styled(Textarea)({
  ...FontSizes.normalCaptionW700,
  color: Colors.Black,
  marginBottom: '12px',
  backgroundColor: 'transparent',
  border: 'none',
  paddingInline: 'inherit',
  '& .Joy-disabled': {
    color: 'gray',
  },

  '&:before': {
    all: 'unset',
  },
});

type IFormData = yup.InferType<typeof editProfileSchema>;

const ProfileEditComponent = () => {
  const mobileMode = useMobileMode();
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState<File>();
  const { user, refetch } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IFormData>({
    resolver: yupResolver(editProfileSchema),
  });
  const navigate = useNavigate();
  const onSubmit = async (data: IFormData) => {
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });
      if (avatar?.name) {
        formData.append('profileURL', avatar, avatar?.name);
      }
      await editProfile(formData);
      setLoading(true);
      refetch();

      navigate('/dashboard/profile');

      analytics.track('Profile Edited');
    } catch (error: any) {
      console.log('error', error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while editing your profile. Please retry or contact support.',
      });
    } finally {
      setLoading(false);
    }
  };
  const isProfileIconStyle = mobileMode
    ? {
        position: 'absolute',
        right: '10px',
        top: '30px',
        zIndex: '1',
      }
    : {
        position: 'absolute',
        right: '50px',
        top: '30px',
        zIndex: '0',
      };

  const height = useWindowHeight();

  return (
    <>
      <Box sx={{ ...isProfileIconStyle, zIndex: 8 }}>
        <Box
          sx={{
            position: 'relative',
            '& img': {
              borderRadius: 100,
              width: 120,
              height: 120,
              border: ' 5px solid #fff',
              objectFit: 'cover',
            },
          }}
        >
          {avatar ? (
            <img src={URL.createObjectURL(avatar)} alt="profile" style={{ objectFit: 'cover' }} />
          ) : (
            <img
              src={user?.profileURL || ProfileAvatarImage}
              alt="profile"
              style={{ objectFit: 'cover' }}
            />
          )}

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            sx={{
              all: 'unset',
              display: 'grid',
              placeItems: 'center',
              position: 'absolute',
              width: 24,
              height: 24,
              backgroundColor: `${Colors.Green} !important`,
              borderRadius: 0,
              mx: 'auto',
              right: 7,
              bottom: 5,
              cursor: 'pointer',
            }}
          >
            <input
              hidden
              accept="image/*"
              onChange={(event: any) => {
                if (event?.target?.files) {
                  setAvatar(event?.target?.files[0]);
                }
              }}
              type="file"
            />
            <PlusSymbolIcon />
          </IconButton>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: mobileMode ? '16px' : '88px',
            paddingRight: mobileMode ? '16px' : '88px',
            position: 'relative',
            marginTop: mobileMode ? undefined : '40px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: mobileMode
                ? height - MOBILE_NAV_BAR_HEIGHT - MOBILE_PROFILE_HEADER_HEIGHT - 40
                : height - 40,
              overflowY: 'scroll',
            }}
          >
            <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
              <FormControl size="sm" color="primary">
                <FormLabel
                  sx={{
                    ...FontSizes.smallCaptionW700,
                    color: Colors.Black,
                    marginBottom: '4px',
                    marginTop: '14px',
                  }}
                >
                  First Name
                </FormLabel>
                <EditInputStyled
                  {...register('firstName')}
                  placeholder={'First Name'}
                  autoComplete="off"
                  defaultValue={user?.firstName}
                  variant="plain"
                />
                {errors.firstName?.message && (
                  <FormHelperText error>{errors.firstName?.message}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
              <FormControl size="sm" color="primary">
                <FormLabel
                  sx={{
                    ...FontSizes.smallCaptionW700,
                    color: Colors.Black,
                    marginBottom: '4px',
                    marginTop: '14px',
                  }}
                >
                  Middle Name
                </FormLabel>
                <EditInputStyled
                  {...register('middleName')}
                  placeholder={'Middle Name'}
                  autoComplete="off"
                  defaultValue={user?.middleName}
                  variant="plain"
                />
                {errors.middleName?.message && (
                  <FormHelperText error>{errors.middleName?.message}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
              <FormControl size="sm" color="primary">
                <FormLabel
                  sx={{
                    ...FontSizes.smallCaptionW700,
                    color: Colors.Black,
                    marginBottom: '4px',
                    marginTop: '14px',
                  }}
                >
                  Last Name
                </FormLabel>
                <EditInputStyled
                  {...register('lastName')}
                  placeholder={'Last Name'}
                  autoComplete="off"
                  defaultValue={user?.lastName}
                  variant="plain"
                />
                {errors.lastName?.message && (
                  <FormHelperText error>{errors.lastName?.message}</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
              <FormControl size="sm" color="primary">
                <FormLabel
                  sx={{
                    ...FontSizes.smallCaptionW700,
                    color: Colors.Black,
                    marginBottom: '4px',
                    marginTop: '14px',
                  }}
                >
                  Email
                </FormLabel>
                <EditInputStyled
                  type="email"
                  autoComplete="off"
                  disabled
                  defaultValue={user?.email}
                  variant="plain"
                />
              </FormControl>
            </Box>
            <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
              <FormControl size="sm" color="primary">
                <FormLabel
                  sx={{
                    ...FontSizes.smallCaptionW700,
                    color: Colors.Black,
                    marginBottom: '4px',
                    marginTop: '14px',
                  }}
                >
                  Phone
                </FormLabel>
                <EditInputStyled
                  autoComplete="off"
                  disabled
                  defaultValue={user?.phoneNumber}
                  variant="plain"
                />
              </FormControl>
            </Box>
            <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
              <FormControl size="sm" color="primary">
                <FormLabel
                  sx={{
                    ...FontSizes.smallCaptionW700,
                    color: Colors.Black,
                    marginBottom: '4px',
                    marginTop: '14px',
                  }}
                >
                  Zipcode
                </FormLabel>
                <EditInputStyled
                  {...register('zipCode')}
                  autoComplete="off"
                  defaultValue={user?.zipCode}
                  variant="plain"
                />
                {errors.zipCode?.message && (
                  <FormHelperText error>{errors.zipCode?.message}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
              <FormControl size="sm" color="primary">
                <FormLabel
                  sx={{
                    ...FontSizes.smallCaptionW700,
                    color: Colors.Black,
                    marginBottom: '4px',
                    marginTop: '14px',
                  }}
                >
                  Bio
                </FormLabel>
                <EditTextareaStyled
                  minRows={2}
                  maxRows={4}
                  {...register('bio')}
                  placeholder={'Bio'}
                  autoComplete="off"
                  defaultValue={user?.bio}
                  variant="plain"
                />
                {errors?.bio?.message && (
                  <FormHelperText error>{errors?.bio?.message}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Button
              color="primary"
              disabled={loading || isSubmitting}
              type="submit"
              fullWidth
              sx={(theme) => ({
                border: `2px solid ${theme.vars.palette.primary[500]}`,
                width: '100%',
              })}
              variant="outlined"
            >
              {loading || isSubmitting ? (
                <CircularProgress variant="solid" color="primary" size="sm" />
              ) : (
                'Confirm'
              )}
            </Button>
          </Box>
        </Stack>
      </form>
    </>
  );
};

export default ProfileEditComponent;
