import { Box, Stack } from '@mui/joy';
import { ONBOARDING_TRAINING_VIDEO_URL } from '../../../../../../config/constants';
import useAnalyticsPageView from '../../../../../../hooks/useAnalyticsPageView';
import { Colors } from '../../../../../../theme';
import { ProfileMenuProps } from '../../../../../../types/interfaces';

const ProfileGigAcademy = ({ label }: ProfileMenuProps) => {
  useAnalyticsPageView('Profile Tab - GigAcademy Tab');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: Colors.GrayLight,
      }}
    >
      <Stack
        alignItems={'center'}
        justifyContent="center"
        sx={{ backgroundColor: Colors.GrayLight, width: '100%', height: '100%' }}
      >
        <iframe
          src={ONBOARDING_TRAINING_VIDEO_URL}
          frameBorder="0"
          width={'100%'}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={false}
          title="Frontline Gig Training"
          style={{
            maxWidth: '80%',
            aspectRatio: '16/9',
            backgroundColor: Colors.GrayDark,
          }}
        />
      </Stack>
    </Box>
  );
};

export default ProfileGigAcademy;
