import { styled } from '@mui/joy';
import { TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Colors } from '../../theme';
import { InputFieldProps } from '../../types/interfaces';

export const InputStyled = styled(TextField)({
  '& .MuiFormLabel-root': {
    fontWeight: 300,
    lineHeight: '30px',
    fontSize: 16,
    color: Colors.AlmostBlack,

    '& .focused': {
      color: Colors.Black,
    },
  },
  '& .MuiInputBase-root .MuiInput-root:after': {
    borderColor: Colors.Blue,
    borderWidth: '1px',
  },

  '& .MuiInputBase-input': {
    paddingBottom: '15px',
  },
});

const InputField = ({ label, type = 'text', name, autoCapitalize = 'on' }: InputFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { invalid, error } }) => (
        <InputStyled
          {...field}
          label={label}
          type={type}
          helperText={invalid ? error?.message : ''}
          error={invalid}
          variant="standard"
          fullWidth
          autoComplete="off"
          inputProps={{
            autoCapitalize,
          }}
        />
      )}
    />
  );
};

export default InputField;
