import { Radio, Typography } from '@mui/joy';
import { subTitle } from '..';
import RadioGroupController from '../../../components/RadioGroupController';

const GigLocation = () => {
  return (
    <>
      <Typography mt={3} textColor="#6A6A68" {...subTitle}>
        We have a few questions to help us serve you better
      </Typography>
      <Typography mt={3} fontSize={28} lineHeight="40px" fontWeight="bold">
        Will you drive a car to the gig location?
      </Typography>

      <RadioGroupController name="willArriveByCar" sx={{ mt: 3 }}>
        <Radio value="Yes" label="Yes" />
        <Radio value="No, I take public transportation" label="No, I take public transportation" />
        <Radio
          value="No, I have another way to get there"
          label="No, I have another way to get there"
        />
      </RadioGroupController>
    </>
  );
};

export default GigLocation;
