import { Box, Stack, Typography, CircularProgress } from '@mui/joy';
import { Grid } from '@mui/material';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { adminGetAllGigs } from '../../../api/gigs';
import LoadMoreButton from '../../../components/LoadMoreButton';
import { Colors, FlexAlign } from '../../../theme';
import { GigStatus, IPaginator, AdminGigBaseItem, ToastMode } from '../../../types/interfaces';
import triggerToast from '../../../utils/triggerToast';
import GigItem from './GigItem';
import useAnalyticsPageView from '../../../hooks/useAnalyticsPageView';
import errorReporting from '../../../utils/errorReporting';

const ListAllGigs = ({ ownedOnly }: { ownedOnly: boolean }) => {
  useAnalyticsPageView('Admin - List All Gigs');
  const [selectedStatus, setSelectedStatus] = useState<GigStatus>(GigStatus.Unassigned);
  const [gigs, setGigs] = useState<AdminGigBaseItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [paginator, setPaginator] = useState<IPaginator>();
  const [currentPage, setCurrentPage] = useState(1);
  const [btnLoading, setBtnLoading] = useState(false);
  const [triggerTabReload, setTriggerTabReload] = useState(false);

  const resetGigs = () => setGigs([]);
  const resetCurrentPage = () => setCurrentPage(1);
  const resetPaginator = () => setPaginator(undefined);

  useEffect(() => {
    resetGigs();
    resetCurrentPage();
    resetPaginator();
    setTriggerTabReload(() => !triggerTabReload);
  }, [selectedStatus]);

  useEffect(() => {
    (async () => {
      try {
        gigs?.length ? setBtnLoading(true) : setLoading(true);
        const res = await adminGetAllGigs({
          status: selectedStatus,
          page: currentPage,
          ownedOnly,
        });

        if (Array.isArray(res?.data?.gigs)) {
          setGigs(uniqBy([...(gigs as AdminGigBaseItem[]), ...res?.data?.gigs], 'id'));
          setPaginator(res.data?.paginator);
        }
      } catch (error: any) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });

        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while getting the list of gigs. Please retry or contact support.',
        });
      } finally {
        setLoading(false);
        setBtnLoading(false);
      }
    })();
  }, [currentPage, triggerTabReload]);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <Stack
      sx={(theme) => ({
        height: '100vh',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: 'scroll',
      })}
      direction="column"
    >
      <Stack
        direction="row"
        sx={{
          marginTop: '40px',
          marginBottom: '40px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <Box
          sx={{
            backgroundColor: selectedStatus === GigStatus.Unassigned ? Colors.GrayDark : undefined,
            borderRadius: '6px',
          }}
          onClick={() => setSelectedStatus(GigStatus.Unassigned)}
        >
          <Typography sx={{ margin: '8px' }}>Unassigned</Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: selectedStatus === GigStatus.Assigned ? Colors.GrayDark : undefined,
            borderRadius: '6px',
          }}
          onClick={() => setSelectedStatus(GigStatus.Assigned)}
        >
          <Typography sx={{ margin: '8px' }}>Assigned</Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: selectedStatus === GigStatus.Completed ? Colors.GrayDark : undefined,
            borderRadius: '6px',
          }}
          onClick={() => setSelectedStatus(GigStatus.Completed)}
        >
          <Typography sx={{ margin: '8px' }}>Completed</Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: selectedStatus === GigStatus.Cancelled ? Colors.GrayDark : undefined,
            borderRadius: '6px',
          }}
          onClick={() => setSelectedStatus(GigStatus.Cancelled)}
        >
          <Typography sx={{ margin: '8px' }}>Cancelled</Typography>
        </Box>
      </Stack>

      {loading === true && (
        <Box sx={{ width: '100%', ...FlexAlign.Center }}>
          <CircularProgress size="sm" />
        </Box>
      )}

      {loading === false && (
        <Grid container sx={{}}>
          {[...gigs].map((item) => (
            <Grid item sx={{ margin: '8px' }}>
              <GigItem gig={item} />
            </Grid>
          ))}

          <LoadMoreButton next={paginator?.next} onClick={handleLoadMore} loading={btnLoading} />
        </Grid>
      )}
    </Stack>
  );
};

export default ListAllGigs;
