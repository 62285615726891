import React from 'react';
import { Box, FormHelperText, Typography } from '@mui/joy';
import { Grid } from '@mui/material';
import { subTitle } from '..';
import ActionableSearchInput from '../../../components/ActionableSearchInput';
import {
  ActionableSearchInputSubmitMode,
  OnboardingStepSkillsProps,
  Skill,
} from '../../../types/interfaces';
import DeletableSkillTag from '../../../components/DeletableSkillTag/index';
import { useFormContext } from 'react-hook-form';

const Skills = ({
  addSelectedSkill,
  skillsLabels,
  selectedSkillsLabels,
  removeSelectedSkill,
}: OnboardingStepSkillsProps) => {
  const onSubmit = (skillLabel: string) => {
    addSelectedSkill({ skillLabel });
  };

  const { formState } = useFormContext();

  return (
    <>
      <Typography mt={3} textColor="#6A6A68" {...subTitle} mb={3}>
        Let us know your skills
      </Typography>

      <Box sx={{ minHeight: '240px' }}>
        <ActionableSearchInput
          onClick={onSubmit}
          buttonLabel="Add"
          submitMode={ActionableSearchInputSubmitMode.AutoCleanOnSubmit}
          placeholder="Add a skill"
          autoCompleteOptions={skillsLabels}
          autoCompleteSelectedOptions={selectedSkillsLabels}
          validateOnSubmit={(text) => {
            if (!skillsLabels.includes(text)) {
              return 'The skill must be chosen from the skills list';
            }

            return null;
          }}
        />

        <Grid container sx={{ marginTop: 3 }} direction="row">
          {selectedSkillsLabels.map((skillLabel: Skill['name']) => (
            <Grid item key={skillLabel} sx={{ marginRight: '8px', marginBottom: '12px' }}>
              <DeletableSkillTag onDeleteClick={removeSelectedSkill}>
                {skillLabel}
              </DeletableSkillTag>
            </Grid>
          ))}
        </Grid>
      </Box>

      <FormHelperText
        sx={{
          color: 'var(--joy-palette-error-main)',
          mt: 2,
          justifyContent: 'circle',
        }}
      >
        {(formState?.errors?.skills?.message as string)
          ? `${formState?.errors?.skills?.message}`
          : ''}
      </FormHelperText>
    </>
  );
};

export default Skills;
