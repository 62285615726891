import { Box, Stack } from '@mui/joy';
import { createContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MySkillsIcon,
  ProfileGigWishListIcon,
  ProfileGigAcademyIcon,
  ProfilePasswordIcon,
  ProfileHelpIcon,
  ProfileLegalIcon,
} from '../../../../assets/svg';
import MobileProfileNavigationBar from '../../../../components/MobileProfileNavigationBar';
import WebProfileNavigationBar from '../../../../components/WebProfileNavigationBar';
import {
  PRIVACY_AND_DATA_POLICY_DOCUMENT_LINK,
  FRONTLINE_HELP_LINK,
} from '../../../../config/constants';
import useMobileMode from '../../../../hooks/useMobileMode';
import { ProfileMenuItemProps } from '../../../../types/interfaces';
import ProfileGigAcademy from './tabs/GigAcademy';

import ProfileMyGigWishlist from './tabs/MyGigWishlist';
import ProfileMySkills from './tabs/MySkills';
import ProfileEdit from './tabs/ProfileEdit';
import ProfileView from './tabs/ProfileView';
import ProfileSetPassword from './tabs/SetPassword';
import { useWindowHeight } from '@react-hook/window-size';
import { EditProfile, ViewProfile } from '../../../../assets/svg/index';
import { MOBILE_NAV_BAR_HEIGHT } from '../../../../config/constants';

export const TabContext = createContext<string>('Profile');

export default function Profile({ currentTabLabel }: { currentTabLabel: string }) {
  const navigation = useNavigate();

  const navItems: ProfileMenuItemProps[] = [
    {
      label: 'My Profile',
      href: '/dashboard/profile',
      onClick: () => navigation('/dashboard/profile'),
      Icon: ViewProfile,
    },
    {
      label: 'Edit Profile',
      href: '/dashboard/profile/profile-edit',
      onClick: () => navigation('/dashboard/profile/profile-edit'),
      Icon: EditProfile,
    },
    {
      label: 'My Skills',
      href: '/dashboard/profile/my-skills',
      onClick: () => navigation('/dashboard/profile/my-skills'),
      Icon: MySkillsIcon,
    },
    {
      label: 'My Gig Wishlist',
      href: '/dashboard/profile/my-gig-wishlist',
      onClick: () => navigation('/dashboard/profile/my-gig-wishlist'),
      Icon: ProfileGigWishListIcon,
    },

    {
      label: 'Gig Academy',
      href: '/dashboard/profile/gig-academy',
      onClick: () => navigation('/dashboard/profile/gig-academy'),
      Icon: ProfileGigAcademyIcon,
    },
    {
      label: 'Set password',
      href: '/dashboard/profile/set-password',
      onClick: () => navigation('/dashboard/profile/set-password'),
      Icon: ProfilePasswordIcon,
    },
    {
      label: 'Help',
      href: '/dashboard/profile/help',
      onClick: () => window.open(FRONTLINE_HELP_LINK, '_blank'),
      Icon: ProfileHelpIcon,
    },
    {
      label: 'Legal',
      href: '/dashboard/profile/legal',
      onClick: () => window.open(PRIVACY_AND_DATA_POLICY_DOCUMENT_LINK, '_blank'),
      Icon: ProfileLegalIcon,
    },
  ];

  const mobileMode = useMobileMode();
  const location = useLocation();
  const height = useWindowHeight();

  return (
    <Stack
      direction={mobileMode ? 'column-reverse' : 'row'}
      sx={{ width: '100%', height: mobileMode ? height - MOBILE_NAV_BAR_HEIGHT : height }}
    >
      {mobileMode ? (
        <MobileProfileNavigationBar
          navItems={navItems}
          currentTabLabel={currentTabLabel}
          label={
            location.pathname === '/dashboard/profile/my-gig-wishlist'
              ? 'Gigs Wishlist'
              : location.pathname === '/dashboard/profile/my-skills'
              ? 'My Skills'
              : location.pathname === '/dashboard/profile/profile-edit'
              ? 'Edit Profile'
              : location.pathname === '/dashboard/profile/set-password'
              ? 'Set Password'
              : location.pathname === '/dashboard/profile'
              ? 'My Profile'
              : location.pathname === '/dashboard/profile/gig-academy'
              ? 'Gig Academy'
              : ''
          }
        />
      ) : (
        <WebProfileNavigationBar navItems={navItems} currentTabLabel={currentTabLabel} />
      )}

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-start',
        }}
      >
        {location.pathname === '/dashboard/profile/profile-view' ||
          (location.pathname === '/dashboard/profile' && <ProfileView label={'My Profile'} />)}
        {location.pathname === '/dashboard/profile/profile-edit' && (
          <ProfileEdit label={'Edit Profile'} />
        )}
        {location.pathname === '/dashboard/profile/my-skills' && (
          <ProfileMySkills label={'My Skills'} />
        )}
        {location.pathname === '/dashboard/profile/my-gig-wishlist' && (
          <ProfileMyGigWishlist label={'Gigs Wishlist'} />
        )}
        {location.pathname === '/dashboard/profile/gig-academy' && (
          <ProfileGigAcademy label={'Gig Academy'} />
        )}
        {location.pathname === '/dashboard/profile/set-password' && (
          <ProfileSetPassword label={'Set password'} />
        )}
      </Box>
    </Stack>
  );
}
