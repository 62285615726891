import { Box, Stack, Typography } from '@mui/joy';
import { ProfileMenuItemProps, WebProfileNavigationBarProps } from '../../types/interfaces';
import { Colors, FlexAlign, FontSizes } from '../../theme';
import { ProfileAvatarIcon } from '../../assets/svg/index';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import WebProfileNavigatorItem from './WebProfileNavigatorItem';
import zipcodes from 'zipcodes';
import analytics from '../../utils/analytics';

const handleItemClick = ({
  onClick,
  label,
}: {
  onClick: ProfileMenuItemProps['onClick'];
  label: ProfileMenuItemProps['label'];
}) => {
  onClick(label);
};

const WebProfileNavigationBar = ({ navItems, currentTabLabel }: WebProfileNavigationBarProps) => {
  const { user, logout } = useAuth();
  const location = useLocation();

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.LightBlack,
        width: '265px',
        paddingTop: '40px',
      }}
    >
      <Box
        sx={{
          flexDirection: 'column',
          width: '180px',
          height: '180px',
          ...FlexAlign.JustifyStartAlignStart,
          marginBottom: '24px',
          paddingLeft: '23px',
        }}
      >
        <Box
          sx={{
            '& img': {
              borderRadius: 100,
              width: 120,
              height: 120,
              border: ' 5px solid #fff',
              objectFit: 'cover',
            },
          }}
        >
          {user?.profileURL ? (
            <img src={user?.profileURL} alt="profile" style={{ objectFit: 'cover' }} />
          ) : (
            <ProfileAvatarIcon />
          )}
        </Box>
        <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: '15px' }}>
          <Typography
            sx={{
              color: Colors.White,
              ...FontSizes.Header4W700,
              pointerEvents: 'none',
              marginRight: '15px',
            }}
          >
            {user?.firstName} {user?.lastName}
          </Typography>
        </Box>

        <Typography
          sx={{
            color: Colors.White,
            ...FontSizes.SmallW300,
            pointerEvents: 'none',
          }}
        >
          {zipcodes.lookup(user.zipCode)?.city || ''}, {zipcodes.lookup(user.zipCode)?.state}
        </Typography>
      </Box>

      <Stack direction="column" mt={'37px'}>
        {navItems.map((item) => {
          return (
            <WebProfileNavigatorItem
              label={item.label}
              onClick={() => handleItemClick({ label: item.label, onClick: item.onClick })}
              Icon={item.Icon}
              key={item.label}
              isFocused={item?.href === location.pathname}
            />
          );
        })}
      </Stack>

      <Box
        sx={{ marginBottom: '20px', cursor: 'pointer' }}
        onClick={() => {
          analytics.track('Logout');
          logout();
        }}
      >
        <Typography sx={{ color: Colors.Red, ...FontSizes.Header2W700, marginLeft: '24px' }}>
          Logout
        </Typography>
      </Box>
    </Stack>
  );
};

export default WebProfileNavigationBar;
