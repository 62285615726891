import { Box, Stack, Typography } from '@mui/joy';
import { GigDetailsRequirementsProps } from '../../types/interfaces';
import { Colors, FontSizes } from '../../theme';
import React from 'react';
import parse from 'html-react-parser';
import useMobileMode from '../../hooks/useMobileMode';
import * as DOMPurify from 'dompurify';

const GigDetailsRequirements = ({ requirements }: GigDetailsRequirementsProps) => {
  const mobileMode = useMobileMode();
  const mobileAwareBorderRadius = mobileMode ? '0px' : '16px';
  const mobileAwareHorizontalMargin = mobileMode ? '8px 0 0 0' : '16px 48px 0 48px';

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.White,
        padding: '16px',
        borderRadius: mobileAwareBorderRadius,
        margin: mobileAwareHorizontalMargin,
      }}
    >
      <Typography
        sx={{
          color: Colors.Black,
          ...FontSizes.Header4W700,
          marginBottom: '4px',
          marginTop: '4px',
        }}
      >
        Requirements
      </Typography>

      <Box
        sx={{
          color: Colors.Black,
          ...FontSizes.BodyRegularW300,
        }}
      >
        {parse(
          DOMPurify.sanitize(requirements, {
            ADD_ATTR: ['target'],
            ADD_TAGS: ['a'],
          }),
        )}
      </Box>
    </Stack>
  );
};

export default React.memo(GigDetailsRequirements);
