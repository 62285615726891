import { Stack, Typography, Box } from '@mui/joy';
import { GigJobTypeFilterBodyProps } from '../../types/interfaces';
import { CheckboxChecked, CheckboxUnchecked } from '../../assets/svg';
import GigFilterBodyHeader from '../GigFilterBodyHeader';
import SubmitButton from '../SubmitButton';
import { useEffect, useState } from 'react';

const GigJobTypeFilterBody = ({
  filterName,
  filterContent,
  containerStyle,
  setFilterContent,
  onReset,
  onBack,
}: GigJobTypeFilterBodyProps) => {
  const [tmpFilterContent, setTmpFilterContent] = useState(filterContent);

  useEffect(() => {
    setTmpFilterContent(filterContent);
  }, [filterContent]);

  return (
    <Stack sx={{ ...styles.container, ...containerStyle }}>
      <GigFilterBodyHeader
        filterName={filterName}
        onBack={onBack}
        onReset={() => {
          onReset();
          onBack();
        }}
      />

      <Stack
        direction="row"
        sx={styles.jobTypeContainer}
        onClick={() =>
          setTmpFilterContent((prevState) => ({ ...prevState, partTime: !prevState.partTime }))
        }
      >
        {tmpFilterContent.partTime ? (
          <Box sx={styles.checkboxContainer}>
            <CheckboxChecked />
          </Box>
        ) : (
          <Box sx={styles.checkboxContainer}>
            <CheckboxUnchecked />
          </Box>
        )}
        <Typography sx={styles.jobTypeText}>{'Part Time'}</Typography>
      </Stack>

      <Stack
        direction="row"
        sx={styles.jobTypeContainer}
        onClick={() =>
          setTmpFilterContent((prevState) => ({ ...prevState, fullTime: !prevState.fullTime }))
        }
      >
        {tmpFilterContent.fullTime ? (
          <Box sx={styles.checkboxContainer}>
            <CheckboxChecked />
          </Box>
        ) : (
          <Box sx={styles.checkboxContainer}>
            <CheckboxUnchecked />
          </Box>
        )}
        <Typography sx={styles.jobTypeText}>{'Full Time'}</Typography>
      </Stack>

      <SubmitButton
        label="Apply"
        onClick={() => {
          if (tmpFilterContent.fullTime === false && tmpFilterContent.partTime === false) {
            alert('Please select at least one option');
            return;
          }

          setFilterContent(tmpFilterContent);
          onBack();
        }}
        isLoading={false}
      />
    </Stack>
  );
};

export default GigJobTypeFilterBody;

const styles = {
  checkboxContainer: {
    width: '36px',
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  jobTypeContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '12px',
  },
  jobTypeText: {
    fontSize: '16px',
    fontWeight: 300,
    marginBottom: '4px',
    fontFamily: 'Montserrat, sans-serif',
  },
  chevronContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
  },
};
