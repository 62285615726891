import { Box, CircularProgress, Stack, Typography, Button } from '@mui/joy';
import { useWindowHeight } from '@react-hook/window-size';
import { useCallback, useEffect, useState } from 'react';
import {
  getAllPaymentMethods,
  createPaymentMethod,
  deletePaymentMethod,
} from '../../../../../api/paymentMethods';
import useMobileMode from '../../../../../hooks/useMobileMode';
import { Colors, FlexAlign } from '../../../../../theme';
import { PaymentMethod, ToastMode } from '../../../../../types/interfaces';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { FontSizes } from '../../../../../theme/index';
import triggerToast from '../../../../../utils/triggerToast';
import analytics from '../../../../../utils/analytics';
import errorReporting from '../../../../../utils/errorReporting';

const CashApp = () => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [loading, setLoading] = useState(false);
  const [triggerReload, setTriggerReload] = useState(false);

  const [isAddingNewCashAppId, setIsAddingNewCashAppId] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [newCashAppId, setNewCashAppId] = useState('');

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [paymentMethodIdToDelete, setPaymentMethodIdToDelete] = useState('');

  const mobileMode = useMobileMode();

  const height = useWindowHeight();

  const horizontalPadding = mobileMode
    ? { paddingLeft: '16px', paddingRight: '16px' }
    : { paddingLeft: '48px', paddingRight: '48px' };

  const handleDeletePaymentMethodClick = useCallback(async () => {
    try {
      await deletePaymentMethod({ paymentMethodId: paymentMethodIdToDelete });
      await setTriggerReload(!triggerReload);
      analytics.track('Cash App Id Deleted');
    } catch (error: any) {
      console.error(error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while deleting the CashApp ID. Please retry or contact support.',
      });
    } finally {
      setIsDeleteDialogOpen(false);
      setPaymentMethodIdToDelete('');
    }
  }, [paymentMethodIdToDelete, triggerReload]);

  const handleCancelDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleAddNewCashAppId = useCallback(async () => {
    if (newCashAppId.length === 0) {
      alert('Please enter a CashApp ID.');
      return;
    }

    try {
      setAddLoading(true);

      const res = await createPaymentMethod({ cashAppId: newCashAppId });

      if (res?.status === 201) {
        setTriggerReload(!triggerReload);
      }
      analytics.track('Cash App Id Added');
    } catch (error: any) {
      console.error(error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while adding your CashApp ID. Please retry or contact support.',
      });
    } finally {
      setAddLoading(false);
      setIsAddingNewCashAppId(!isAddingNewCashAppId);
    }
  }, [isAddingNewCashAppId, newCashAppId, triggerReload]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const res = await getAllPaymentMethods();

        setPaymentMethods(res?.data?.payments);
      } catch (error: any) {
        console.error(error);

        errorReporting.captureException(error, {
          level: 'error',
        });

        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while loading your payment methods. Please retry or contact support.',
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [triggerReload]);

  return (
    <Stack
      direction="column"
      sx={{
        ...horizontalPadding,
        paddingTop: '20px',
        overflowY: 'scroll',
        height: height - 180 - 100,
        paddingBottom: '32px',
        marginTop: '180px',
      }}
    >
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={isDeleteDialogOpen}
      >
        <DialogTitle>Withdraw from Gig</DialogTitle>
        <DialogContent>Are you sure you want to delete this payment method?</DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCancelDialog}
            sx={{ backgroundColor: Colors.Blue, borderRadius: '8px', marginRight: '20px' }}
          >
            No
          </Button>

          <Button
            onClick={handleDeletePaymentMethodClick}
            sx={{ backgroundColor: Colors.Blue, borderRadius: '8px', marginRight: '10px' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Box sx={{ width: '100%', ...FlexAlign.Center }}>
          <CircularProgress size={'sm'} />
        </Box>
      )}

      <Typography
        sx={{ width: '100%', textAlign: 'left', ...FontSizes.Header3W700, marginBottom: '8px' }}
      >
        Cash App ID
      </Typography>

      {paymentMethods &&
        paymentMethods?.map((item, index) => (
          <Stack direction="row" sx={{ width: '100%', ...FlexAlign.Center }} key={item.id}>
            <Typography
              sx={{ width: '100%', backgroundColor: Colors.GrayDark, textAlign: 'center' }}
            >
              {item.cashAppId}
            </Typography>
            <Button
              variant="plain"
              color="danger"
              onClick={() => {
                setPaymentMethodIdToDelete(item.id);
                setIsDeleteDialogOpen(true);
              }}
            >
              Delete
            </Button>
          </Stack>
        ))}

      {isAddingNewCashAppId && (
        <Stack direction="row">
          <TextField
            sx={{ width: '100%' }}
            placeholder={'Enter your Cash App ID'}
            value={newCashAppId}
            onChange={(e) => {
              setNewCashAppId(e.target.value);
            }}
            inputProps={{
              autoCapitalize: 'off',
            }}
          />
          <Button
            variant="plain"
            color="primary"
            onClick={handleAddNewCashAppId}
            loading={addLoading}
            sx={{ height: '55px' }}
          >
            Save
          </Button>
        </Stack>
      )}

      <Typography
        sx={{ width: '100%', textAlign: 'left', ...FontSizes.BodyRegularW300, marginTop: '24px' }}
      >
        The CashApp ID is a string similar to the following: <b>$abc123</b>
      </Typography>

      {isAddingNewCashAppId === false && paymentMethods?.length === 0 && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setNewCashAppId('');
            setIsAddingNewCashAppId(!isAddingNewCashAppId);
          }}
        >
          Set CashAppID
        </Button>
      )}
    </Stack>
  );
};

export default CashApp;
