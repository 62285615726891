import { Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';
import PhoneInput from '../../../components/phoneInput';

const StepThree = () => {
  const { getValues } = useFormContext();

  return (
    <>
      <Typography mt={3} component="h4" fontSize="1.75rem" lineHeight="40px" fontWeight="700">
        Hi {getValues('firstName')}, what's your phone number?
      </Typography>
      <Stack mt="57px" mb="100px" gap={2} justifyContent="center">
        <PhoneInput name="phoneNumber" />
      </Stack>
    </>
  );
};

export default StepThree;
