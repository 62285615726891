import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { NavFind, NavHome, NavMyGigs, NavPayment, NavProfile } from '../../assets/svg';
import WebNavigationBar from '../../components/WebNavigationBar/index';
import { createContext } from 'react';
import { DashboardTabItem, DashboardTabLabel } from '../../types/interfaces';
import MobileNavigationBar from '../../components/MobileNavigationBar';
import Home from './tabs/Home';
import FindGigs from './tabs/FindGigs';
import MyGigs from './tabs/MyGigs';
import Payment from './tabs/Payment';
import Profile from './tabs/Profile';
import useMobileMode from '../../hooks/useMobileMode';
import { useNavigate } from 'react-router-dom';

export const TabContext = createContext<DashboardTabLabel>('Home');

export default function Dashboard({
  currentTabLabel,
  isAnonymous = false,
}: {
  currentTabLabel: DashboardTabLabel;
  isAnonymous?: boolean;
}) {
  const navigation = useNavigate();

  const navItems: DashboardTabItem[] = [
    {
      label: 'Home',
      onClick: () => navigation('/dashboard/home/feed'),
      Icon: NavHome,
    },
    {
      label: 'Find Gigs',
      onClick: () => navigation('/dashboard/find-gigs'),
      Icon: NavFind,
    },
    {
      label: 'My Gigs',
      onClick: () => navigation('/dashboard/my-gigs/active'),
      Icon: NavMyGigs,
    },
    {
      label: 'Payment',
      onClick: () => navigation('/dashboard/payment'),
      Icon: NavPayment,
    },
    {
      label: 'Profile',
      onClick: () => navigation('/dashboard/profile'),
      Icon: NavProfile,
    },
  ];

  const mobileMode = useMobileMode();

  return (
    <Box sx={{ display: 'flex' }}>
      <Stack direction={mobileMode ? 'column-reverse' : 'row'} sx={{ display: 'flex', flex: 1 }}>
        {mobileMode ? (
          <MobileNavigationBar
            navItems={navItems}
            isBottomNotchPresent={false}
            currentTabLabel={currentTabLabel}
            isAnonymous={isAnonymous}
          />
        ) : (
          <WebNavigationBar
            navItems={navItems}
            currentTabLabel={currentTabLabel}
            isAnonymous={isAnonymous}
          />
        )}

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: currentTabLabel === 'Profile' ? 'flex-start' : 'center',
          }}
        >
          {currentTabLabel === 'Home' && <Home />}
          {currentTabLabel === 'Find Gigs' && <FindGigs />}
          {currentTabLabel === 'My Gigs' && <MyGigs />}
          {currentTabLabel === 'Payment' && <Payment />}
          {currentTabLabel === 'Profile' && <Profile currentTabLabel={'Profile'} />}
        </Box>
      </Stack>
    </Box>
  );
}
