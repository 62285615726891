import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analytics from '../utils/analytics';

const useAnalyticsPageView = (pageName: string) => {
  const location = useLocation();

  useEffect(() => {
    analytics.track('Page Viewed', {
      page_name: pageName,
      path: location.pathname,
      referrer: document.referrer,
    });
  }, [location, pageName]);
};

export default useAnalyticsPageView;
