import { Box, Stack, Typography } from '@mui/joy';
import { GigFilterListItemProps } from '../../types/interfaces';
import { Colors, FontSizes } from '../../theme';
import { ChevronIcon } from '../../assets/svg';

const GigFilterListItem = ({
  filterName,
  filterContent,
  onClick,
  containerStyle,
}: GigFilterListItemProps) => {
  return (
    <Stack sx={{ ...styles.container, ...containerStyle }} onClick={onClick}>
      <Stack direction="row" sx={styles.innerContainer}>
        <Stack sx={styles.contentContainer}>
          <Typography sx={styles.filterName}>{filterName}</Typography>

          <Box sx={{ margin: 'auto', marginLeft: 0 }}>
            <Typography sx={styles.filterContent}>
              {filterContent ? filterContent : 'Filter not set'}
            </Typography>
          </Box>
        </Stack>
        <Box sx={styles.chevronContainer}>
          <ChevronIcon />
        </Box>
      </Stack>
      <Box sx={styles.bottomBar}></Box>
    </Stack>
  );
};

export default GigFilterListItem;

const styles = {
  container: {
    display: 'flex',
    flex: 1,
  },
  chevronContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
  },
  innerContainer: {
    display: 'flex',
    flex: 1,
  },
  filterName: {
    ...FontSizes.MediumW600,
    marginBottom: '8px',
  },
  filterContent: {
    color: Colors.AlmostBlack,
    ...FontSizes.SmallerW600,
    borderRadius: 9999,
    height: '28px',
    lineHeight: '28px',
    backgroundColor: Colors.GrayLight,
    textAlign: 'center',
    verticalAlign: 'center',
    paddingRight: '16px',
    paddingLeft: '16px',
  },
  bottomBar: {
    width: '100%',
    height: '1px',
    marginTop: '16px',
    backgroundColor: Colors.GrayDark,
  },
};
