import axios from '.';
import { SKILLS_API_PREFIX } from '../config/constants';

/**
 * This method hits `/skills` API.
 * @returns Promise with the skills list
 */
export const getSkills = async (currentPage: number = 1) => {
  return axios.get(`/${SKILLS_API_PREFIX}/`, {
    params: { page: currentPage },
  });
};
