import { Link, Stack, Typography, IconButton, Box } from '@mui/joy';
import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import WarningIcon from '@mui/icons-material/Warning';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { sendSmsVerificationCode } from '../../../api/auth';
import {
  DEFAULT_TIMEOUT_SECONDS,
  TIME_INTERVAL_FOR_MESSAGE,
  TIME_INTERVAL_FOR_SECONDS,
} from '../../../config/constants';
import useShowAlert from '../../../hooks/useShowAlert';
import CustomAlert from '../../../components/CustomAlert';
import errorReporting from '../../../utils/errorReporting';

const StepFour = ({ setStepCount }: { setStepCount: Function }) => {
  const { getValues, control } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { showAlert, setShowAlert } = useShowAlert(TIME_INTERVAL_FOR_MESSAGE);
  const [seconds, setSeconds] = useState<number>(DEFAULT_TIMEOUT_SECONDS);

  /**
   * Method that sends the call to `send-sms-verification-code` API
   * @returns Promise
   */
  const resendSmsVerificationCode = async () => {
    try {
      setLoading(true);
      await sendSmsVerificationCode({
        value: getValues('phoneNumber'),
        verifyAccount: false,
      });
      setSeconds(DEFAULT_TIMEOUT_SECONDS);
    } catch (error: unknown) {
      errorReporting.captureException(error, {
        level: 'error',
      });
      if (isAxiosError(error)) {
        setErrorMsg(error.response?.data?.message);
        setShowAlert(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const myInterval = setTimeout(() => {
      if (seconds && seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, TIME_INTERVAL_FOR_SECONDS);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      <Typography mt={3} component="h4" fontSize="1.75rem" lineHeight="40px" fontWeight="700">
        Enter your code
      </Typography>
      <Typography
        mt="12px"
        sx={{ color: '#888EA1' }}
        component="p"
        fontSize="1rem"
        lineHeight="24px"
        fontWeight="300"
      >
        Enter the code we sent to {getValues('phoneNumber')}
      </Typography>

      <Link
        onClick={() => {
          setStepCount(4);
        }}
        mt="5px"
        component="p"
        fontSize="1rem"
        lineHeight="24px"
        fontWeight="300"
      >
        Change number
      </Link>
      <Stack mt="52px" direction="row" gap={'12px'} justifyContent="center">
        <Controller
          render={({ field }) => (
            <OtpInput
              {...field}
              isInputNum
              focusStyle={{
                boxShadow: '0px 0px 16px rgba(24, 73, 245, 0.15)',
                backgroundColor: '#fff',
                outline: 'none',
              }}
              inputStyle="OtpInput"
              containerStyle="OtpInputContainer"
              numInputs={6}
            />
          )}
          name="code"
          control={control}
        />
      </Stack>
      <Box textAlign="center">
        <Link
          mt="20px"
          mb="50px"
          fontSize="1rem"
          lineHeight="24px"
          fontWeight="700"
          justifyContent="center"
          disabled={loading || !!seconds}
          onClick={resendSmsVerificationCode}
          sx={{
            '&.Joy-disabled': {
              color: 'rgba(var(--joy-palette-primary-mainChannel) / 1)',
            },
          }}
        >
          {loading ? 'Sending...' : `Request New Code ${seconds ? `(${seconds})s` : ''}`}
        </Link>
      </Box>

      {showAlert && (
        <CustomAlert
          title="Oops!"
          message={errorMsg!}
          startDecorator={<WarningIcon sx={{ mt: '2px', mx: '4px' }} fontSize="small" />}
          variant="soft"
          color="danger"
          endDecorator={
            <IconButton
              onClick={() => {
                setShowAlert(false);
              }}
              variant="soft"
              size="sm"
              color="danger"
            >
              <CloseRoundedIcon />
            </IconButton>
          }
        />
      )}
    </>
  );
};

export default StepFour;
