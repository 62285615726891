import { Box, Stack } from '@mui/joy';
import ProfileFeedSection from '../../../../../../components/ProfileFeedSection';
import useAnalyticsPageView from '../../../../../../hooks/useAnalyticsPageView';
import useMobileMode from '../../../../../../hooks/useMobileMode';
import { Colors } from '../../../../../../theme';
import { ProfileMenuProps } from '../../../../../../types/interfaces';

const ProfileMyGigWishlist = ({ label }: ProfileMenuProps) => {
  useAnalyticsPageView('Profile Tab - My Gig Wishlist Tab');
  const mobileMode = useMobileMode();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Stack
        direction="column"
        sx={{
          backgroundColor: Colors.GrayLight,
          width: '100%',
          flex: 1,
          marginTop: mobileMode ? '100px' : '0px',
        }}
      >
        <ProfileFeedSection />
      </Stack>
    </Box>
  );
};

export default ProfileMyGigWishlist;
