import { Stack, Typography } from '@mui/joy';
import React, { ChangeEvent, useState } from 'react';
import citiesByState from '../../assets/data/citiesByState';
import { Colors } from '../../theme';
import styled from 'styled-components';

const states = Object.keys(citiesByState);

const StyledOption = styled.option`
  color: ${Colors.AlmostBlack};
  font-family: ;
`;

function CityStateDropdown({
  onCityStateSelected,
}: {
  onCityStateSelected: ({ city, state }: { city: string; state: string }) => void;
}) {
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  function handleStateChange(event: ChangeEvent<HTMLSelectElement>) {
    const newState = event.target.value;
    setSelectedState(newState);
    setSelectedCity('');
  }

  function handleCityChange(event: ChangeEvent<HTMLSelectElement>) {
    const newCity = event.target.value;
    setSelectedCity(newCity);

    onCityStateSelected({ city: newCity, state: selectedState });
  }

  return (
    <Stack
      direction="column"
      sx={{ marginTop: '32px', marginBottom: '16px', color: Colors.AlmostBlack }}
    >
      <select value={selectedState} onChange={handleStateChange}>
        <StyledOption value="">Select a state</StyledOption>
        {states.map((state) => (
          <StyledOption key={state} value={state}>
            {state}
          </StyledOption>
        ))}
      </select>

      <br />
      {selectedState && (
        <select value={selectedCity} onChange={handleCityChange}>
          <StyledOption value="">Select a city</StyledOption>
          {citiesByState[selectedState].map((city) => (
            <StyledOption key={city} value={city}>
              {city}
            </StyledOption>
          ))}
        </select>
      )}
      {selectedCity && (
        <Typography sx={{ marginTop: '16px', color: Colors.AlmostBlack }}>
          City: {selectedCity}, {selectedState}
        </Typography>
      )}
    </Stack>
  );
}

export default CityStateDropdown;
