import { Stack, Typography, Box } from '@mui/joy';
import { useCallback } from 'react';
import { ArrowLeft } from '../../assets/svg';
import useMobileMode from '../../hooks/useMobileMode';
import { Colors, FontSizes } from '../../theme';
import { GenericHeaderProps } from '../../types/interfaces';

const GenericHeader = ({ goBack, header }: GenericHeaderProps) => {
  const mobileMode = useMobileMode();

  const handleGoBackClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      goBack();
    },
    [goBack],
  );

  const paddingHorizontal = mobileMode ? '0 24px 16px 24px' : '0 48px 16px 48px';

  return (
    <Stack
      sx={{
        backgroundColor: mobileMode ? Colors.Green : Colors.White,
        height: '80px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        padding: paddingHorizontal,
      }}
    >
      <Stack
        direction="row"
        sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ height: '30px', width: '30px' }} onClick={handleGoBackClick}>
          <ArrowLeft />
        </Box>
        <Typography
          sx={{
            display: 'flex',
            flex: 1,
            color: Colors.Black,
            ...FontSizes.Header2W700,
            marginBottom: '8px',
            paddingRight: '20px',
          }}
        >
          {header}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default GenericHeader;
