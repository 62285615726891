import { Button, Stack, Typography } from '@mui/joy';
import { Controller, useFormContext } from 'react-hook-form';
import { circleStyle, rectangleStyle } from '../../config/styleConstants';
import { UploadButtonProps } from '../../types/interfaces';

const UploadButton = ({
  name,
  buttonText,
  icon,
  variant = 'circle',
  containerStyles,
  formKey,
}: UploadButtonProps) => {
  const { control, setValue, trigger } = useFormContext();

  const getVariantStyles = (variant: string) => {
    return variant === 'circle' ? circleStyle : rectangleStyle;
  };

  return (
    <>
      <Button
        component="label"
        sx={{
          ...getVariantStyles(variant),
          display: 'flex',
          mx: 'auto',
          border: '1px dashed #173DE5',
          backgroundColor: '#E0DEDA',
          boxSizing: 'border-box',
          '&:hover': {
            backgroundColor: '#173DE5',
            borderColor: '#E0DEDA',
            '& p': {
              color: '#fff',
            },
            '& svg path': {
              stroke: '#fff',
            },
          },
          ...containerStyles,
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          {icon}
          <Typography mt={0.5} color="primary" fontSize={13} lineHeight="22px" fontWeight={700}>
            {buttonText}
          </Typography>
        </Stack>
        <Controller
          rules={{ required: true }}
          control={control}
          name={name}
          render={({ field: { value, ...rest }, fieldState: { invalid, error } }) => {
            return (
              <input
                {...rest}
                type="file"
                hidden
                onChange={async (e) => {
                  if (e?.target?.files && e?.target?.files[0]) {
                    setValue(formKey, e.target.files[0]);
                    await trigger(formKey);
                  }
                }}
                accept="image/png, image/gif, image/jpeg, image/svg"
              />
            );
          }}
        />
      </Button>
    </>
  );
};

export default UploadButton;
