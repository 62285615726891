import { Stack } from '@mui/joy';
import { Colors } from '../../../../theme';
import CashApp from './CashApp';
import Header from '../../../../components/Header';
import useAnalyticsPageView from '../../../../hooks/useAnalyticsPageView';

const Payment = () => {
  useAnalyticsPageView('Payment Tab');
  return (
    <Stack
      sx={{
        display: 'flex',
        flex: 1,
        backgroundColor: Colors.GrayLight,
      }}
    >
      <Header label="Payment Settings" />
      <CashApp />
    </Stack>
  );
};

export default Payment;
