import { Box, Button, FormControl, FormLabel, Stack, CircularProgress } from '@mui/joy';
import useMobileMode from '../../hooks/useMobileMode';
import { Colors, FontSizes } from '../../theme';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText } from '@mui/material';
import { changePasswordSchema } from '../../validation';
import { resetPassword } from '../../api/auth';
import { useState } from 'react';
import { EditInputStyled } from '../ProfileEdit';
import { useNavigate } from 'react-router-dom';
import triggerToast from '../../utils/triggerToast';
import { ToastMode } from '../../types/interfaces';
import analytics from '../../utils/analytics';
import errorReporting from '../../utils/errorReporting';

type FormData = yup.InferType<typeof changePasswordSchema>;

const ProfileSetPasswordComponent = () => {
  const mobileMode = useMobileMode();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(changePasswordSchema),
  });
  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      await resetPassword({
        oldPassword: data?.oldPassword,
        newPassword: data?.newPassword,
      });
      reset({
        oldPassword: '',
        newPassword: '',
        passwordConfirmation: '',
      });

      navigate('/dashboard/profile');
      analytics.track('Password Edited');
    } catch (error: any) {
      console.log('error', error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while setting your password. Please retry or contact support.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: mobileMode ? '16px' : '88px',
          paddingRight: mobileMode ? '16px' : '88px',
          position: 'relative',
          marginTop: mobileMode ? undefined : '40px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
            <FormControl size="sm" color="primary">
              <FormLabel
                sx={{
                  ...FontSizes.smallCaptionW700,
                  color: Colors.Black,
                  marginBottom: '4px',
                  marginTop: '14px',
                }}
              >
                Old Password
              </FormLabel>
              <EditInputStyled
                {...register('oldPassword')}
                placeholder={'**********'}
                type="password"
                autoComplete="off"
                defaultValue={''}
                variant="plain"
              />
              {errors.oldPassword?.message && (
                <FormHelperText error>{errors.oldPassword?.message}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
            <FormControl size="sm" color="primary">
              <FormLabel
                sx={{
                  ...FontSizes.smallCaptionW700,
                  color: Colors.Black,
                  marginBottom: '4px',
                  marginTop: '14px',
                }}
              >
                New Password
              </FormLabel>
              <EditInputStyled
                {...register('newPassword')}
                placeholder={'**********'}
                type="password"
                autoComplete="off"
                defaultValue={''}
                variant="plain"
              />
              {errors.newPassword?.message && (
                <FormHelperText error>{errors.newPassword?.message}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
            <FormControl size="sm" color="primary">
              <FormLabel
                sx={{
                  ...FontSizes.smallCaptionW700,
                  color: Colors.Black,
                  marginBottom: '4px',
                  marginTop: '14px',
                }}
              >
                Confirm Password
              </FormLabel>
              <EditInputStyled
                {...register('passwordConfirmation')}
                placeholder={'**********'}
                type="password"
                autoComplete="off"
                defaultValue={''}
                variant="plain"
              />
              {errors.passwordConfirmation?.message && (
                <FormHelperText error>{errors.passwordConfirmation?.message}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Button
            color="primary"
            disabled={loading || isSubmitting}
            type="submit"
            fullWidth
            sx={(theme) => ({
              border: `2px solid ${theme.vars.palette.primary[500]}`,
              width: '100%',
            })}
            variant="outlined"
          >
            {loading || isSubmitting ? (
              <CircularProgress variant="solid" color="primary" size="sm" />
            ) : (
              'Confirm'
            )}
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default ProfileSetPasswordComponent;
