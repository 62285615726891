import { DateTime } from 'luxon';
import * as yup from 'yup';
import { GigStatus } from '../types/interfaces';
import { matchIsValidTel } from 'mui-tel-input';
import {
  GIG_IMAGE_FILE_SIZE,
  PROFILE_PHOTO_FILE_SIZE,
  SUPPORTED_FORMATS,
} from '../config/constants';

export const phoneNumberValidation = yup.object({
  phoneNumber: yup
    .mixed()
    .required('Phone number is required')
    .test('phoneNumber', 'Enter a valid phone number', (value) => {
      return matchIsValidTel(value);
    }),
});

export const changePasswordSchema = yup
  .object({
    oldPassword: yup
      .string()
      .required('No password provided')
      .min(8, 'Password is too short - should be 8 chars minimum')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters'),
    newPassword: yup
      .string()
      .required('No password provided')
      .min(8, 'Password is too short - should be 8 chars minimum')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  })
  .required();

export const editProfileSchema = yup
  .object({
    firstName: yup
      .string()
      .trim()
      .required('First name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field')
      .min(3, 'First name is too short')
      .max(20, 'First name is too long'),
    middleName: yup.string().trim().max(20, 'Middle name is too long').nullable(),
    lastName: yup
      .string()
      .trim()
      .required('Last name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field')
      .min(3, 'Last name is too short')
      .max(20, 'Last name is too long'),
    bio: yup.string().required('Bio is required'),
    zipCode: yup
      .string()
      .required()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(5, 'Must be exactly 5 digits')
      .max(5, 'Must be exactly 5 digits'),
  })
  .required();

export const emailAndPasswordValidation = yup.object({
  email: yup.string().email().required('Email is required'),
  password: yup
    .string()
    .required('No password provided')
    .min(8, 'Password is too short - should be 8 chars minimum')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters'),
});

export const updatePassword = yup.object({
  password: yup
    .string()
    .required('No password provided')
    .min(8, 'Password is too short - should be 8 chars minimum')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const forgotPasswordWithEmail = yup.object({
  value: yup.string().email().required('Email is required'),
});

export const forgotPasswordWithPhone = yup.object({
  value: yup
    .mixed()
    .required('Phone number is required')
    .test('phoneNumber', 'Enter a valid phone number', (value) => {
      return matchIsValidTel(value);
    }),
});

export const SignUpValidationSchema = [
  yup.object(),
  //validation for step 1
  yup.object({
    firstName: yup
      .string()
      .trim()
      .required('First name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field')
      .min(3, 'First name is too short')
      .max(20, 'First name is too long'),
    middleName: yup.string().trim().max(20, 'Middle name is too long').nullable(),
    lastName: yup
      .string()
      .trim()
      .required('Last name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field')
      .min(3, 'Last name is too short')
      .max(20, 'Last name is too long'),
  }),

  //validation for step 4
  emailAndPasswordValidation,
  //validation for step 5
  yup.object({
    zipCode: yup
      .number()
      .positive()
      .required('Zip code is required')
      .min(1, 'Zip code must be greater than or equal to 1'),
  }),
  //validation for step 2
  phoneNumberValidation,
  //validation for step 3
  yup.object(),
];

/// *** Onboarding ***

const referralSourceOnboardingSchema = yup.object({
  referralSource: yup.string().required('Required'),
});

const hasWorkingCameraOnboardingSchema = yup.object({
  hasWorkingCamera: yup.string().required('Required'),
});

const willArriveByCarOnboardingSchema = yup.object({
  willArriveByCar: yup.string().required('Required'),
});

const photoOnboardingSchema = yup.object().shape({
  photo: yup
    .mixed()
    .test('file', 'Required', (value) => value && value?.size && value?.type)
    .test(
      'fileSize',
      'File size is too large',
      (value) => value && value.size <= PROFILE_PHOTO_FILE_SIZE,
    )
    .test('fileType', 'Unsupported File Format', (value) =>
      SUPPORTED_FORMATS.includes(value?.type || ''),
    )
    .required('A file is required'),
});

const genericNoOpSchema = yup.object().shape({
  nothing: yup.bool().default(false),
});

const skillsOnboardingSchema = yup.object().shape({
  skills: yup.array(),
});

export const OnBoardingSchema = [
  referralSourceOnboardingSchema,
  hasWorkingCameraOnboardingSchema,
  willArriveByCarOnboardingSchema,
  photoOnboardingSchema,
  genericNoOpSchema,
  skillsOnboardingSchema,
];

export const GigReportSchema = yup.object({
  gigId: yup
    .string()
    .required('Something went wrong. Please go back to the main section and reopen this page'),
  location: yup
    .string()
    .required('Location is required. Please turn location on in your browser settings.'),

  arrivalDateAndTime: yup.string().required(),
  checkoutDateAndTime: yup
    .mixed()
    .test('afterArrival', 'Checkout must be after arrival', (value, context) => {
      const arrivalDateAndTime = DateTime.fromISO(context.parent.arrivalDateAndTime);
      const checkoutDateAndTime = DateTime.fromISO(value);

      if (arrivalDateAndTime < checkoutDateAndTime) {
        return true;
      } else {
        return false;
      }
    }),

  beforeWorkPic: yup.mixed().test('required', 'The before work picture is required', (value) => {
    return value ? true : false;
  }),
  afterWorkPic: yup.mixed().test('required', 'The after work picture is required', (value) => {
    return value ? true : false;
  }),
  beforeWorkComment: yup.string().required('Before work comment is required'),
  afterWorkComment: yup.string().required('After work comment is required'),
});

export const CreateGigSchema = yup.object({
  name: yup.string().required('The name is mandatory.'),
  location: yup.string().required('The location is mandatory.'),
  travelTips: yup.string().required('The travel tips are mandatory.'),
  requirements: yup.string().required('The requirements are mandatory.'),
  city: yup.string().required('The city mandatory.'),
  zipCode: yup.string().required('The zip code is mandatory'),
  isFulltime: yup.boolean().default(false),
  featuredImage: yup
    .mixed()
    .nullable()
    .test('fileSize', 'File size is too large', (value) => {
      if (value === null) return true;
      else {
        if (value.size <= GIG_IMAGE_FILE_SIZE) return true;
        else return false;
      }
    })
    .test('fileType', 'Unsupported File Format', (value) => {
      if (value === null) return true;
      else {
        if (SUPPORTED_FORMATS.includes(value?.type || '')) return true;
        else return false;
      }
    }),
  skills: yup.array().min(1, 'Please select at least one skill'),

  startDateAndTime: yup
    .mixed()
    .test('inTheFuture', 'Start Date/Time must be in the future', (value) => {
      const startDateTime = DateTime.fromISO(value);

      if (startDateTime > DateTime.local()) {
        return true;
      } else {
        return false;
      }
    }),
  endDateAndTime: yup
    .mixed()
    .test('inTheFuture', 'End Date/Time must be in the future', (value) => {
      const endDateTime = DateTime.fromISO(value);

      if (endDateTime > DateTime.local()) {
        return true;
      } else {
        return false;
      }
    })
    .test('afterStart', 'End Date/Time must be after Start Date/Time', (value, context) => {
      const startDateTime = DateTime.fromISO(context.parent.startDateAndTime);
      const endDateTime = DateTime.fromISO(value);

      if (startDateTime < endDateTime) {
        return true;
      } else {
        return false;
      }
    }),

  hourlyRate: yup.number().required('The hourly rate is required').moreThan(-1),
  estimatedPrice: yup.number().required('The estimated price is required').moreThan(-1),
  description: yup.string().required('The description is required'),
});

export const EditGigSchema = yup.object({
  name: yup.string().required('The name is mandatory.'),
  location: yup.string().required('The location is mandatory.'),
  travelTips: yup.string().required('The travel tips are mandatory.'),
  requirements: yup.string().required('The requirements are mandatory.'),
  city: yup.string().required('The city mandatory.'),
  zipCode: yup.string().required('The zip code is mandatory'),
  isFulltime: yup.boolean().default(false),
  status: yup
    .string()
    .oneOf([GigStatus.Assigned, GigStatus.Unassigned, GigStatus.Cancelled, GigStatus.Completed]),
  featuredImage: yup
    .mixed()
    .nullable()
    .test('fileSize', 'File size is too large', (value) => {
      if (value === null) return true;
      else {
        if (value.size <= GIG_IMAGE_FILE_SIZE) return true;
        else return false;
      }
    })
    .test('fileType', 'Unsupported File Format', (value) => {
      if (value === null) return true;
      else {
        if (SUPPORTED_FORMATS.includes(value?.type || '')) return true;
        else return false;
      }
    }),
  skills: yup.array().min(1, 'Please select at least one skill'),

  startDateAndTime: yup
    .mixed()
    .test('inTheFuture', 'Start Date/Time must be in the future', (value) => {
      const startDateTime = DateTime.fromISO(value);

      if (startDateTime > DateTime.local()) {
        return true;
      } else {
        return false;
      }
    }),
  endDateAndTime: yup
    .mixed()
    .test('inTheFuture', 'End Date/Time must be in the future', (value) => {
      const endDateTime = DateTime.fromISO(value);

      if (endDateTime > DateTime.local()) {
        return true;
      } else {
        return false;
      }
    })
    .test('afterStart', 'End Date/Time must be after Start Date/Time', (value, context) => {
      const startDateTime = DateTime.fromISO(context.parent.startDateAndTime);
      const endDateTime = DateTime.fromISO(value);

      if (startDateTime < endDateTime) {
        return true;
      } else {
        return false;
      }
    }),

  hourlyRate: yup.number().required('The hourly rate is required').moreThan(-1),
  estimatedPrice: yup.number().required('The estimated price is required').moreThan(-1),
  description: yup.string().required('The description is required'),
});
