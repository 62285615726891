import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/joy';
import { MobileNavigationBarProps } from '../../types/interfaces';
import { Colors, FlexAlign, FontSizes } from '../../theme';

import { DashboardTabItem } from '../../types/interfaces';
import { useWindowWidth } from '@react-hook/window-size';
import { MOBILE_NAV_BAR_HEIGHT } from '../../config/constants';
import useIsAnonymous from '../../hooks/useIsAnonymous';

const handleItemClick = ({
  onClick,
  label,
}: {
  onClick: DashboardTabItem['onClick'];
  label: DashboardTabItem['label'];
}) => {
  onClick(label);
};

const Item = ({ label, onClick, Icon, isFocused }: DashboardTabItem & { isFocused: boolean }) => {
  return (
    <Stack
      direction="column"
      onClick={() => onClick(label)}
      sx={{ ...FlexAlign.JustifyStartAlignCenter }}
    >
      <Icon color={isFocused ? Colors.Green : Colors.White50} />

      <Typography
        sx={{
          color: isFocused ? Colors.Green : Colors.White50,
          ...FontSizes.SmallerW600,
          pointerEvents: 'none',
          marginTop: '1px',
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

const MobileNavigationBar = ({
  navItems,
  isBottomNotchPresent,
  currentTabLabel,
  isAnonymous = false,
}: MobileNavigationBarProps) => {
  const NavBarHeight = isBottomNotchPresent ? '82px' : MOBILE_NAV_BAR_HEIGHT;
  const isAnonymousFreshlyDetected = useIsAnonymous();

  const width = useWindowWidth();

  let height = NavBarHeight;
  let backgroundColor = Colors.Black;

  if (isAnonymous && isAnonymousFreshlyDetected === true) {
    height = '160px';
    backgroundColor = 'transparent';
  }

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor,
        height,
        justifyContent: 'space-between',
        paddingTop: '6px',
        bottom: 0,
        position: 'fixed',
        width,
        zIndex: 10,
      }}
    >
      {isAnonymous && isAnonymousFreshlyDetected === true && (
        <Stack
          direction="column"
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            backgroundColor: Colors.GrayLight,
            paddingBottom: '24px',
          }}
        >
          <Button
            sx={(theme) => ({
              border: `2px solid ${theme.vars.palette.primary[500]}`,
              backgroundColor: Colors.Blue,
              width: '94%',
              color: Colors.White,
            })}
            variant="outlined"
            onClick={() => window.open('/login', '_blank')}
          >
            Login Now
          </Button>

          <Stack
            direction="row"
            sx={{
              width: '94%',
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ flex: 1, height: '1px', backgroundColor: Colors.GrayDarker60 }} />
            <Typography
              sx={{
                color: Colors.GrayDarker,
                fontSize: '12px',
                fontWeight: 500,
                textAlign: 'center',
                fontFamily: 'Montserrat, sans-serif',
                marginLeft: '16px',
                marginRight: '16px',
              }}
            >
              don't have an account?
            </Typography>
            <Box sx={{ flex: 1, height: '1px', backgroundColor: Colors.GrayDarker60 }} />
          </Stack>

          <Button
            sx={(theme) => ({
              border: `2px solid ${theme.vars.palette.primary[500]}`,
              width: '94%',
            })}
            variant="outlined"
            onClick={() => window.open('/login', '_blank')}
          >
            Join Today
          </Button>
        </Stack>
      )}

      {isAnonymous && isAnonymousFreshlyDetected === false && (
        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Box onClick={() => window.location.reload()}>
            <Typography
              sx={{
                color: Colors.GrayLight,
                ...FontSizes.BolderCaptionW500,
                pointerEvents: 'none',
                paddingRight: '8px',
                paddingLeft: '8px',
                paddingTop: '4px',
                paddingBottom: '4px',
                border: `2px solid ${Colors.GrayLight}`,
              }}
            >
              Home
            </Typography>
          </Box>
        </Box>
      )}

      {isAnonymous === false &&
        navItems.map((item) => (
          <Box sx={{ paddingRight: '8px', paddingLeft: '8px' }} key={item.label}>
            <Item
              label={item.label}
              onClick={() => handleItemClick({ label: item.label, onClick: item.onClick })}
              Icon={item.Icon}
              key={item.label}
              isFocused={item.label === currentTabLabel}
            />
          </Box>
        ))}
    </Stack>
  );
};

export default MobileNavigationBar;
