import { FormHelperText } from '@mui/joy';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PhoneInputProps } from '../../types/interfaces';

import { MuiTelInput } from 'mui-tel-input';

export const dropdownStyle = {
  margin: '5px 0 10px -1px',
  backgroundColor: '#F5F5F0',
  width: 'max-content',
  borderRadius: 0,
};

export const inputStyle = {
  border: 'none',
  color: '#6a6a68',
  width: '100%',
  borderRadius: 0,
  backgroundColor: 'transparent',
  borderBottom:
    '1px solid rgba(var(--joy-palette-common-onBackgroundChannel) / var(--joy-opacity-inputUnderline))',
};

export const inputStyleError = {
  border: 'none',
  color: 'var(--joy-palette-error-main)',
  width: '100%',
  borderRadius: 0,
  backgroundColor: 'transparent',
  borderBottom: '1px solid var(--joy-palette-error-main)',
};

const PhoneInput = ({ name }: PhoneInputProps) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field: { ref, ...rest }, fieldState: { invalid, error } }) => {
          return (
            <>
              <MuiTelInput
                {...rest}
                error={!!error}
                flagSize={'medium'}
                forceCallingCode
                defaultCountry={'US'}
              />
              <FormHelperText sx={{ color: 'var(--joy-palette-error-main)' }}>
                {error?.message}
              </FormHelperText>
            </>
          );
        }}
      />
    </>
  );
};

export default PhoneInput;
