import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Stack, Typography } from '@mui/joy';
import { isAxiosError } from 'axios';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { forgetPassword } from '../../../api/auth';
import InputField from '../../../components/InputField';
import AuthLayout from '../../../layout/authLayout';
import { updatePassword } from '../../../validation';
import errorReporting from '../../../utils/errorReporting';

const ResetPassword = () => {
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(updatePassword),
    mode: 'onChange',
  });

  const { handleSubmit, formState } = methods;
  const navigation = useNavigate();
  const { token } = useParams();

  const onSubmit = async ({ password }: { password: string }) => {
    try {
      await forgetPassword({
        token: token || '',
        password,
      });
      navigation('/forget-password/success');
    } catch (error) {
      errorReporting.captureException(error, {
        level: 'error',
      });
      if (isAxiosError(error)) {
        console.log('error', error.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (!token) {
      navigation('/login');
    }
  });

  return (
    <AuthLayout>
      <Typography component="h4" fontSize="1.75rem" lineHeight="40px" fontWeight="700">
        Forget Password
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography mt={9} mb={4.5} fontSize="14px" lineHeight="17.07px" fontWeight="bold">
            Enter the new password to be associated with your registered account
          </Typography>
          <Stack gap={3}>
            <InputField label="Enter new password" type="password" name="password" />
            <InputField label="Confirm password" type="password" name="passwordConfirmation" />
          </Stack>
          <Button
            sx={(theme) => ({
              border: `2px solid ${theme.vars.palette.primary[500]}`,
              mt: 8,
              width: '100%',
            })}
            variant="outlined"
            type="submit"
          >
            {formState.isSubmitting ? (
              <CircularProgress variant="solid" color="primary" size="sm" />
            ) : (
              'Reset Password'
            )}
          </Button>
        </form>
      </FormProvider>
    </AuthLayout>
  );
};

export default ResetPassword;
