import { Box, Typography, Stack, CircularProgress } from '@mui/joy';
import useMobileMode from '../../../../hooks/useMobileMode';
import { Colors, FlexAlign, FontSizes } from '../../../../theme';
import TabSwitcher from '../../../../components/TabSwitcher/index';
import { useEffect, useState } from 'react';
import GigPreviewItem from '../../../../components/GigPreviewItem';
import {
  GigBaseItem,
  GigStatus,
  ActiveGigsDateLabels,
  GigPreviewItemContext,
  IPaginator,
  ToastMode,
} from '../../../../types/interfaces';
import ThinGrayDarkLine from '../../../../components/ThinGrayDarkLine';
import { useWindowHeight } from '@react-hook/window-size';
import { DateTime } from 'luxon';
import { invert, uniqBy } from 'lodash';
import { gigHistoryByStatus } from '../../../../api/gigs';
import memoize from 'lodash/memoize';
import LoadMoreButton from '../../../../components/LoadMoreButton';
import { useNavigate, useParams } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import GigPlaceholder from '../../../../assets/images/gig-placeholder.png';
import triggerToast from '../../../../utils/triggerToast';
import useAnalyticsPageView from '../../../../hooks/useAnalyticsPageView';
import errorReporting from '../../../../utils/errorReporting';

const HeaderWithTabsHeight = 180;

const renderCompletedGigs = memoize(({ completedGigs }: { completedGigs: GigBaseItem[] }) => {
  const navigate = useNavigate();
  return completedGigs.map((gig, index) => (
    <Box key={gig.id}>
      <GigPreviewItem
        key={gig.id}
        id={gig.id}
        name={gig.name}
        isExternal={gig.isExternal}
        imageUrl={gig.featuredImage || GigPlaceholder}
        location={gig.location}
        hourlyRate={gig.hourlyRate}
        status={gig.status}
        clientRating={undefined}
        estimatedPrice={gig.estimatedPrice}
        ISO8601DateTimeStart={gig.startDateAndTime}
        ISO8601DateTimeEnd={gig.endDateAndTime}
        bookmarked={gig.bookmarked}
        city={gig.city}
        applicationStatus={gig.applicationStatus}
        componentContext={GigPreviewItemContext.MyGigs}
        handleClick={() => navigate(`/gigs/${gig.id}`)}
        zipCode={gig.zipCode}
        isFulltime={gig.isFulltime}
      />
      {index !== completedGigs.length - 1 && <ThinGrayDarkLine />}
    </Box>
  ));
});

const renderActiveGigs = memoize(
  ({
    activeGigs,
    activeGigsDateLabels,
  }: {
    activeGigs: GigBaseItem[];
    activeGigsDateLabels: ActiveGigsDateLabels;
  }) => {
    const invertedActiveGigsDateLabels = invert(activeGigsDateLabels);
    const navigate = useNavigate();

    return activeGigs.map((gig, index) => (
      <Box key={gig.id}>
        {invertedActiveGigsDateLabels[index] && (
          <Typography sx={{ ...FontSizes.Header2W700, marginTop: '20px', marginBottom: '20px' }}>
            {invertedActiveGigsDateLabels[index]}
          </Typography>
        )}
        <GigPreviewItem
          key={gig.id}
          id={gig.id}
          isExternal={gig.isExternal}
          name={gig.name}
          imageUrl={gig.featuredImage || GigPlaceholder}
          location={gig.location}
          hourlyRate={gig.hourlyRate}
          status={gig.status}
          clientRating={undefined}
          estimatedPrice={gig.estimatedPrice}
          ISO8601DateTimeStart={gig.startDateAndTime}
          ISO8601DateTimeEnd={gig.endDateAndTime}
          bookmarked={gig.bookmarked}
          city={gig.city}
          applicationStatus={gig.applicationStatus}
          componentContext={GigPreviewItemContext.MyGigs}
          handleClick={() => navigate(`/gigs/${gig.id}`)}
          zipCode={gig.zipCode}
          isFulltime={gig.isFulltime}
        />
        {index !== activeGigs.length - 1 && <ThinGrayDarkLine />}
      </Box>
    ));
  },
);

const MyGigs = () => {
  useAnalyticsPageView('MyGigs Tab');

  const [activeGigs, setActiveGigs] = useState<GigBaseItem[]>([]);
  const [completedGigs, setCompletedGigs] = useState<GigBaseItem[]>([]);
  const navigate = useNavigate();

  const mobileMode = useMobileMode();
  const height = useWindowHeight();
  const mobileModeAwareWindowHeight = mobileMode ? height - 55 : height;

  const [activeGigsDateLabels, setActiveGigsDateLabels] = useState<ActiveGigsDateLabels>({});

  const [loading, setLoading] = useState(false);

  const [completedPaginator, setCompletedPaginator] = useState<IPaginator>();
  const [activePaginator, setActivePaginator] = useState<IPaginator>();

  const [currentCompletedPage, setCurrentCompletedPage] = useState(1);
  const [currentActivePage, setCurrentActivePage] = useState(1);

  const [btnCompletedLoading, setBtnCompletedLoading] = useState(false);
  const [btnActiveLoading, setBtnActiveLoading] = useState(false);

  const handleLoadMoreCompleted = () => {
    setCurrentCompletedPage(currentCompletedPage + 1);
  };

  const handleLoadMoreActive = () => {
    setCurrentActivePage(currentActivePage + 1);
  };

  const { section } = useParams();

  useEffect(() => {
    (async () => {
      try {
        activeGigs.length ? setBtnActiveLoading(true) : setLoading(true);
        const res = await gigHistoryByStatus({
          page: currentActivePage,
          status: GigStatus.Assigned,
        });
        const gigsFromResponse = res?.data?.gigs;

        const totalActiveGigs = uniqBy([...activeGigs, ...gigsFromResponse], 'id');

        setActiveGigs(totalActiveGigs);
        setActivePaginator(res?.data?.paginator);

        const tmpActiveGigsDateLabels: ActiveGigsDateLabels = {};

        totalActiveGigs.forEach((gig, index) => {
          const dateLabel = DateTime.fromISO(gig.startDateAndTime).toFormat('MMM dd, yyyy');
          if (tmpActiveGigsDateLabels[dateLabel] === undefined) {
            tmpActiveGigsDateLabels[dateLabel] = index;
          }
        });

        setActiveGigsDateLabels(tmpActiveGigsDateLabels);
      } catch (error: any) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });

        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while fetching your gigs. Please retry or contact support.',
        });
      } finally {
        setLoading(false);
        setBtnActiveLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePage]);

  useEffect(() => {
    (async () => {
      try {
        completedGigs.length ? setBtnCompletedLoading(true) : setLoading(true);
        const res = await gigHistoryByStatus({
          page: currentCompletedPage,
          status: GigStatus.Completed,
        });
        const gigsFromResponse = res?.data?.gigs;
        setCompletedGigs(uniqBy([...completedGigs, ...gigsFromResponse], 'id'));
        setCompletedPaginator(res?.data?.paginator);
      } catch (error: any) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });

        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while fetching your gigs. Please retry or contact support.',
        });
      } finally {
        setLoading(false);
        setBtnCompletedLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompletedPage]);

  const horizontalPadding = mobileMode
    ? { paddingLeft: '16px', paddingRight: '16px' }
    : { paddingLeft: '48px', paddingRight: '48px' };

  return (
    <Stack sx={{ display: 'flex', flex: 1 }}>
      <Stack
        direction="column"
        sx={{
          top: 0,
          backgroundColor: Colors.White,
          width: '100%',
          height: HeaderWithTabsHeight,
        }}
      >
        <Typography
          sx={{
            ...FontSizes.Header1W700,
            color: Colors.Black,
            marginTop: '48px',
            marginBottom: '20px',
            ...horizontalPadding,
          }}
        >
          My Gigs
        </Typography>

        <Box
          sx={{
            ...horizontalPadding,
          }}
        >
          <TabSwitcher
            tabs={['Active', 'Completed']}
            activeTabIndex={['Active', 'Completed'].indexOf(capitalize(section))}
            onTabClick={(index) => {
              navigate(`/dashboard/my-gigs/${['active', 'completed'][index]}`);
            }}
          />
        </Box>
      </Stack>

      <Stack
        direction="column"
        sx={{
          backgroundColor: Colors.GrayLight,
          display: 'flex',
          height: mobileModeAwareWindowHeight - HeaderWithTabsHeight,
          overflowY: 'scroll',
          ...horizontalPadding,
        }}
      >
        {loading ? (
          <Box sx={{ marginTop: '16px', width: '100%', ...FlexAlign.Center }}>
            <CircularProgress size={'sm'} />
          </Box>
        ) : null}
        <Stack
          direction="column"
          sx={{
            visibility: section === 'active' ? undefined : 'hidden',
            height: section === 'active' ? undefined : 0,
          }}
        >
          {renderActiveGigs({ activeGigs, activeGigsDateLabels })}

          <LoadMoreButton
            onClick={handleLoadMoreActive}
            loading={btnActiveLoading}
            next={activePaginator?.next}
          />
        </Stack>

        <Stack
          direction="column"
          sx={{
            visibility: section === 'completed' ? undefined : 'hidden',
            height: section === 'completed' ? undefined : 0,
          }}
        >
          {renderCompletedGigs({ completedGigs })}

          <LoadMoreButton
            onClick={handleLoadMoreCompleted}
            loading={btnCompletedLoading}
            next={completedPaginator?.next}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MyGigs;
