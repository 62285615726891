import { Button, Stack } from '@mui/joy';
import { Colors } from '../../theme';
import React from 'react';
import useMobileMode from '../../hooks/useMobileMode';
import { GigDetailsFooterBlockLabelProps } from '../../types/interfaces';

const GigDetailsFooterBlockLabel = ({ currentMode }: GigDetailsFooterBlockLabelProps) => {
  const mobileMode = useMobileMode();
  const mobileAwareBorderRadius = mobileMode ? '0px' : '16px';
  const mobileAwareHorizontalMargin = mobileMode ? '8px 0 0 0' : '16px 48px 0 48px';

  const modes = {
    Declined: {
      color: Colors.Red,
      label: 'Declined for this Gig',
      disabled: false,
    },
    Completed: {
      color: Colors.Green,
      label: 'Completed',
      disabled: false,
    },
    Cancelled: {
      color: Colors.Red,
      label: 'Cancelled',
      disabled: false,
    },
  };

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.White,
        padding: '16px',
        borderRadius: mobileAwareBorderRadius,
        margin: mobileAwareHorizontalMargin,
      }}
    >
      <Button
        sx={(theme) => ({
          border: `0px`,
          width: '100%',
          color: modes[currentMode].color,
        })}
        type="button"
        variant="outlined"
        disabled={modes[currentMode].disabled}
      >
        {modes[currentMode].label}
      </Button>
    </Stack>
  );
};

export default React.memo(GigDetailsFooterBlockLabel);
