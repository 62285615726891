import { Box, Stack, Typography } from '@mui/joy';
import { DashboardTabItem } from '../../types/interfaces';
import { Colors, FlexAlign, FontSizes } from '../../theme';

const WebNavigatorItem = ({
  label,
  onClick,
  Icon,
  isFocused,
}: DashboardTabItem & { isFocused: boolean }) => {
  return (
    <Stack
      direction="row"
      onClick={() => onClick(label)}
      sx={{
        paddingTop: '24px',
        paddingBottom: '24px',
        ...FlexAlign.JustifyStartAlignCenter,
        paddingLeft: '24px',
        cursor: 'pointer'
      }}
    >
      <Icon color={isFocused ? Colors.Green : Colors.White50} />
      <Box sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '18px' }}>
        <Typography
          sx={{
            color: isFocused ? Colors.Green : Colors.White50,
            ...FontSizes.SmallerW600,
            pointerEvents: 'none',
          }}
        >
          {label}
        </Typography>
      </Box>
    </Stack>
  );
};

export default WebNavigatorItem;
