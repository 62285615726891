import React from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/system';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { ConnectorProps } from '../../types/interfaces';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepper-root`]: {
    paddingRight: 10,
  },
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#82DA9D',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#82DA9D',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#E0DEDA',
    borderTopWidth: 4,
  },
}));

const Connector = ({ steps, activeStep }: ConnectorProps) => {
  return (
    <Stepper activeStep={activeStep} sx={{ gap: '10px' }} connector={<QontoConnector />}>
      {steps.map((label) => (
        <Step key={label} sx={{ paddingInline: '0 10px', display: 'none' }} />
      ))}
    </Stepper>
  );
};

export default Connector;
