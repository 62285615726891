import { Box, Stack } from '@mui/joy';
import React, { ReactNode } from 'react';
import { TextLogoLg } from '../../assets/svg';

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: { xs: 'white', sm: theme.vars.palette.primary[500] },
        height: '100vh',
      })}
      justifyContent={{ xs: 'center', sm: 'start' }}
    >
      <Box sx={{ width: '100vw', display: { xs: 'none', sm: 'block' } }}>
        <TextLogoLg />
      </Box>
      <Stack
        px={{ xs: 2, sm: 11.25 }}
        pt={13}
        pb={9.25}
        mt={'-90px'}
        sx={{
          maxWidth: 338,
          minWidth: 338,
          mx: 'auto',
          position: 'relative',
          backgroundColor: { xs: 'white', sm: '#F5F5F0' },
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default AuthLayout;
