import { Stack, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Colors, FontSizes } from '../../theme';
import HorizontalSpacer from '../HorizontalSpacer';

const SummaryGigLocation = () => {
  const [currentGeolocation, setCurrentGeolocation] = useState<string>('');
  const [error, setError] = useState<string>('');
  const resetError = () => setError('');
  const resetCurrentGeolocation = () => setCurrentGeolocation('');
  const { setValue } = useFormContext();

  useEffect(() => {
    resetCurrentGeolocation();
    resetError();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          var lat = position.coords.latitude;
          var lng = position.coords.longitude;
          setCurrentGeolocation(`Coordinates: ${lat}, ${lng}`);
          setValue('location', `${lat},${lng}`, { shouldValidate: true });
        },
        () => {
          setError(
            'Unable to retrieve your location. Please make sure location services are enabled for this site in your browser settings.',
          );
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
        },
      );
    } else {
      setError(
        'Your browser does not support geolocation which is required to report to this Gig. Please update your browser or use a different one.',
      );
    }
  }, []);

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.White,
        borderRadius: '16px',
        padding: '16px',
        marginTop: '24px',
      }}
    >
      <Stack direction="row">
        <Typography
          sx={{
            color: Colors.Black,
            ...FontSizes.Header4W700,
            marginBottom: '16px',
            marginTop: '4px',
          }}
        >
          Location
        </Typography>
        <HorizontalSpacer />
      </Stack>

      {currentGeolocation ? (
        <Typography sx={{ ...FontSizes.SmallW300 }}>{currentGeolocation}</Typography>
      ) : error ? (
        <Typography sx={{ color: Colors.Red, ...FontSizes.SmallW300 }}>{error}</Typography>
      ) : (
        <Typography sx={{ ...FontSizes.SmallW300 }}>Loading geolocation...</Typography>
      )}
    </Stack>
  );
};

export default SummaryGigLocation;
