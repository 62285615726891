import { useState } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/joy';
import { AppBarProps, MobileProfileNavigationBarProps } from '../../types/interfaces';
import { Colors, FlexAlign, FontSizes } from '../../theme';
import { ProfileMenuItemProps } from '../../types/interfaces';
import { ProfileAvatarIcon } from '../../assets/svg';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import zipcodes from 'zipcodes';
import useMobileMode from '../../hooks/useMobileMode';
import { useWindowHeight } from '@react-hook/window-size';
import Hamburger from '../../assets/images/hamburger.png';
import analytics from '../../utils/analytics';

const handleItemClick = ({
  onClick,
  label,
}: {
  onClick: ProfileMenuItemProps['onClick'];
  label: ProfileMenuItemProps['label'];
}) => {
  onClick(label);
};

const Item = ({
  label,
  onClick,
  Icon,
  isFocused,
}: ProfileMenuItemProps & { isFocused: boolean }) => {
  return (
    <Stack
      direction="row"
      onClick={() => onClick(label)}
      sx={{
        paddingTop: '7px',
        paddingBottom: '7px',
        marginBottom: '21px',
        ...FlexAlign.JustifyStartAlignStart,
        paddingLeft: '24px',
        backgroundColor: isFocused ? Colors.Green : Colors.Black,
      }}
    >
      <Icon color={isFocused ? Colors.Green : Colors.White50} />
      <Box sx={{ marginLeft: '33px' }}>
        <Typography
          sx={{
            color: Colors.White,
            ...FontSizes.BolderCaptionW500,
            pointerEvents: 'none',
          }}
        >
          {label}
        </Typography>
      </Box>
    </Stack>
  );
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - 100%)`,
    marginLeft: `100%`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MobileProfileNavigationBar = ({ navItems, label }: MobileProfileNavigationBarProps) => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const mobileMode = useMobileMode();
  const height = useWindowHeight();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { logout } = useAuth();

  return (
    <>
      <AppBar
        position="absolute"
        open={open}
        sx={{ backgroundColor: Colors.Green, minHeight: 104, flexDirection: 'row', zIndex: '1' }}
      >
        <IconButton
          sx={{
            backgroundColor: Colors.Green,
            display: 'flex',
            alignItems: 'flex-end',
            paddingBottom: '23px',
            paddingLeft: '16px',
            '&:hover': {
              backgroundColor: Colors.Green,
            },
          }}
          onClick={handleDrawerOpen}
        >
          <img src={Hamburger} alt="hamburger" width={'28px'} height={'28px'} />
        </IconButton>

        {label && (
          <Typography
            sx={{
              ...FontSizes.Header2W700,
              color: Colors.Black,
              backgroundColor: Colors.Green,
              marginLeft: '14px',
              display: 'flex',
              alignItems: 'flex-end',
              paddingBottom: '23px',
            }}
          >
            {label}
          </Typography>
        )}
      </AppBar>
      <Drawer
        sx={{
          width: '100%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            height: mobileMode ? height - 55 : height,
            width: '100%',
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Stack
          direction="column"
          sx={{
            backgroundColor: Colors.Black,
            width: '100%',
          }}
        >
          <Box
            sx={{
              flexDirection: 'column',

              ...FlexAlign.JustifyStartAlignStart,
              backgroundColor: Colors.Black,
              marginBottom: '24px',
              paddingLeft: '23px',
              '& img': {
                borderRadius: 100,
                width: 120,
                height: 120,
                border: ' 5px solid #fff',
                objectFit: 'cover',
              },
            }}
          >
            {user?.profileURL ? (
              <img src={user?.profileURL} alt="profile" style={{ objectFit: 'cover' }} />
            ) : (
              <ProfileAvatarIcon />
            )}

            <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: '15px' }}>
              <Typography
                sx={{
                  color: Colors.White,
                  ...FontSizes.Header4W700,
                  pointerEvents: 'none',
                  marginRight: '15px',
                }}
              >
                {user?.firstName} {user?.lastName}
              </Typography>
            </Box>
            <Typography
              sx={{
                color: Colors.White,
                ...FontSizes.BodyLargeW300,
                pointerEvents: 'none',
              }}
            >
              {zipcodes.lookup(user.zipCode)?.city || ''}, {zipcodes.lookup(user.zipCode)?.state}
            </Typography>
          </Box>

          <Stack direction="column" mt={'42px'}>
            {navItems.map((item) => (
              <span onClick={handleDrawerClose}>
                <Item
                  label={item.label}
                  onClick={() => handleItemClick({ label: item.label, onClick: item.onClick })}
                  Icon={item.Icon}
                  key={item.label}
                  isFocused={item?.href === location.pathname}
                />
              </span>
            ))}
          </Stack>

          <Box
            sx={{ marginBottom: '20px' }}
            onClick={() => {
              analytics.track('Logout');
              logout();
            }}
          >
            <Typography
              sx={{
                color: Colors.Red,
                ...FontSizes.Header2W700,
                marginLeft: '24px',
                marginTop: '20px',
                marginBottom: '40px',
              }}
            >
              Logout
            </Typography>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};

export default MobileProfileNavigationBar;
