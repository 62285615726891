import React from 'react';
import { useState } from 'react';
import { isAxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, CircularProgress, IconButton, Stack, Typography } from '@mui/joy';

import { sendSmsVerificationCode } from '../../../api/auth';
import PhoneInput from '../../../components/phoneInput';
import { FormValues, ToastMode } from '../../../types/interfaces';
import { phoneNumberValidation } from '../../../validation';
import { useNavigate } from 'react-router-dom';
import { TIME_INTERVAL_FOR_MESSAGE } from '../../../config/constants';
import CustomAlert from '../../../components/CustomAlert';
import useShowAlert from '../../../hooks/useShowAlert';
import triggerToast from '../../../utils/triggerToast';
import useAnalyticsPageView from '../../../hooks/useAnalyticsPageView';
import analytics from '../../../utils/analytics';
import errorReporting from '../../../utils/errorReporting';

const defaultValues = {
  phoneNumber: '',
};

const Index = ({
  setActiveStep,
}: {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  useAnalyticsPageView('Sign In By Phone');
  const [errorMsg, setErrorMsg] = useState('');
  const { showAlert, setShowAlert } = useShowAlert(TIME_INTERVAL_FOR_MESSAGE);
  const navigate = useNavigate();

  const methods = useForm<FormValues>({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(phoneNumberValidation),
  });
  const { handleSubmit, formState } = methods;

  /**
   * Handles form submit and sends API for verification code.
   * @param data {Object} FormValues
   */
  const onSubmit = async (data: FormValues) => {
    try {
      await sendSmsVerificationCode({ value: data.phoneNumber, verifyAccount: true });
      analytics.track('Sign In By Phone');
      navigate('/verification', { state: { phoneNumber: data.phoneNumber } });
    } catch (error: unknown) {
      errorReporting.captureException(error, {
        level: 'warning',
      });
      if (isAxiosError(error)) {
        setErrorMsg(error.response?.data?.message);
        setShowAlert(true);

        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while sending the SMS verification. Please retry or contact support.',
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack mt="57px" mb={7} gap={1} justifyContent="center">
          <Typography fontSize="14px" lineHeight="17.07px" fontWeight="bold">
            Enter your phone number
          </Typography>
          <PhoneInput name="phoneNumber" />
        </Stack>

        {showAlert && (
          <CustomAlert
            title="Oops!"
            message={errorMsg!}
            startDecorator={<WarningIcon sx={{ mt: '2px', mx: '4px' }} fontSize="small" />}
            variant="soft"
            color="danger"
            endDecorator={
              <IconButton
                onClick={() => {
                  setShowAlert(false);
                }}
                variant="soft"
                size="sm"
                color="danger"
              >
                <CloseRoundedIcon />
              </IconButton>
            }
          />
        )}

        <Stack direction="column" gap="20px">
          <Button
            type="submit"
            sx={(theme) => ({
              backgroundColor: theme.vars.palette.primary[500],
              color: theme.vars.palette.common.white,
              '&.Joy-disabled': {
                color: theme.vars.palette.common.white,
              },
              '&:hover': {
                backgroundColor: theme.vars.palette.primary[500],
                color: theme.vars.palette.common.white,
              },
            })}
            disabled={!formState.isDirty && !formState?.isValid}
            variant="outlined"
          >
            {formState.isSubmitting ? (
              <CircularProgress variant="solid" color="primary" size="sm" />
            ) : (
              'Continue'
            )}
          </Button>
          <Typography textAlign="center" fontSize="14px" lineHeight="17.07px" fontWeight="bold">
            Or
          </Typography>
          <Button
            sx={(theme) => ({
              border: `2px solid ${theme.vars.palette.primary[500]}`,
            })}
            variant="outlined"
            onClick={() => setActiveStep(1)}
          >
            Login with Email
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default Index;
