import { useEffect, useState } from 'react';
import { TIME_INTERVAL_FOR_MESSAGE } from '../config/constants';

const useShowAlert = (time: number = TIME_INTERVAL_FOR_MESSAGE) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 5 seconds set the showAlert value to false
      showAlert && setShowAlert(false);
    }, time);

    return () => {
      clearTimeout(timeId);
    };
  }, [showAlert, time]);
  return { showAlert, setShowAlert };
};

export default useShowAlert;
