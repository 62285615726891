import axios from '.';
import { USER_API_PREFIX } from '../config/constants';
import {
  AddBookmarkPayload,
  AddUserSkills,
  DeleteBookmarkPayload,
  DeleteSkillPayload,
} from '../types/interfaces';

/**
 * This method hits `/me` API.
 * @returns Promise with user object
 */
export const me = async () => {
  return axios.get(`/${USER_API_PREFIX}/me`);
};

/**
 * Receive formData including the profile image
 * @returns Promise
 */
export const onBoarding = async (formData: any) => {
  return axios.post(`/${USER_API_PREFIX}/on-boarding`, formData);
};

export const deleteBookmark = async ({ gigId }: DeleteBookmarkPayload) => {
  return axios.delete(`/${USER_API_PREFIX}/delete-bookmark/${gigId}`);
};

export const addBookmark = async ({ gigId }: AddBookmarkPayload) => {
  return axios.post(`/${USER_API_PREFIX}/add-bookmark`, { gigId });
};

export const getAllBookmarks = async ({ page }: { page: number }) => {
  return axios.get(`/${USER_API_PREFIX}/bookmarks`, {
    params: {
      page,
    },
  });
};

export const deleteSkill = async ({ skillId }: DeleteSkillPayload) => {
  return axios.delete(`/${USER_API_PREFIX}/remove-skill/${skillId}`);
};

export const addUserSkills = async ({ skills }: AddUserSkills) => {
  return axios.post(`/${USER_API_PREFIX}/add-skills`, {
    skills,
  });
};

export const getUserSkills = async () => {
  return axios.get(`/${USER_API_PREFIX}/skills`);
};

export const editProfile = async (formData: any) => {
  return axios.patch(`/${USER_API_PREFIX}/update-profile`, formData);
};
