import { useState, useEffect } from 'react';
import { setAuthToken } from '../api';

function useIsAnonymous() {
  const [isAnonymous, setIsAnonymous] = useState(() => {
    const token = window.localStorage.getItem('token');
    return !token;
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const token = window.localStorage.getItem('token');
        setIsAnonymous(!token);

        if (token) {
          setAuthToken(token);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isAnonymous;
}

export default useIsAnonymous;
