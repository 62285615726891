import axios from '.';
import { PAYMENT_METHODS_API_PREFIX } from '../config/constants';

export const getAllPaymentMethods = async () => {
  return axios.get(`/${PAYMENT_METHODS_API_PREFIX}/`);
};

export const createPaymentMethod = async ({ cashAppId }: { cashAppId: string }) => {
  return axios.post(`/${PAYMENT_METHODS_API_PREFIX}/`, { cashAppId });
};

export const deletePaymentMethod = async ({ paymentMethodId }: { paymentMethodId: string }) => {
  return axios.delete(`/${PAYMENT_METHODS_API_PREFIX}/${paymentMethodId}`);
};

export const updatePaymentMethod = async ({
  paymentMethodId,
  cashAppId,
}: {
  paymentMethodId: string;
  cashAppId: string;
}) => {
  return axios.patch(`/${PAYMENT_METHODS_API_PREFIX}/${paymentMethodId}`, { cashAppId });
};
