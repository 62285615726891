import useMobileMode from '../../hooks/useMobileMode';
import { useNavigate, useParams } from 'react-router-dom';
import { Colors, FlexAlign, FontSizes } from '../../theme';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/joy';
import GenericHeader from '../../components/GenericHeader';
import SummaryGigTime from '../../components/SummaryGigTime';
import SummaryGigWorkPics from '../../components/SummaryGigWorkPics/index';
import VerticalSpacer from '../../components/VerticalSpacer';
import SummaryGigLocation from '../../components/SummaryGigLocation';
import { FormProvider, useForm } from 'react-hook-form';
import { isAxiosError } from 'axios';
import { GigReportFormData } from '../../types/interfaces';
import { addGigReport } from '../../api/gigs';
import { yupResolver } from '@hookform/resolvers/yup';
import { GigReportSchema } from '../../validation';
import { useState } from 'react';
import useAnalyticsPageView from '../../hooks/useAnalyticsPageView';
import analytics from '../../utils/analytics';
import errorReporting from '../../utils/errorReporting';

const GigReport = () => {
  useAnalyticsPageView('Gig Report');
  const mobileMode = useMobileMode();
  const { gigId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const resetError = () => setError('');

  const horizontalPadding = mobileMode ? '0 16px 0 16px' : '0 48px 0 48px';

  const methods = useForm<GigReportFormData>({
    defaultValues: {
      gigId: gigId,
      location: '',
      arrivalDateAndTime: null,
      checkoutDateAndTime: null,
      beforeWorkPic: null,
      afterWorkPic: null,
      beforeWorkComment: '',
      afterWorkComment: '',
    },
    resolver: yupResolver(GigReportSchema),
  });

  const onSubmit = async (values: any) => {
    resetError();
    try {
      await methods.trigger();

      const formData = new FormData() as GigReportFormData;
      formData.append('gigId', values.gigId);
      formData.append('location', values.location);
      formData.append('beforeWorkPicUrl', values.beforeWorkPic, values.beforeWorkPic.name);
      formData.append('afterWorkPicUrl', values.afterWorkPic, values.afterWorkPic.name);
      formData.append('arrivalDateAndTime', values.arrivalDateAndTime);
      formData.append('checkoutDateAndTime', values.checkoutDateAndTime);
      formData.append('beforeWorkComment', values.beforeWorkComment);
      formData.append('afterWorkComment', values.afterWorkComment);

      await addGigReport({ formData });

      navigate('/dashboard/my-gigs/active');

      analytics.track('Report Gig');
    } catch (error) {
      errorReporting.captureException(error, {
        level: 'error',
      });
      if (isAxiosError(error)) {
        console.log(error.response?.data?.message);
        setError(
          'An error occurred while reporting your Gig. Please try again later or contact support.',
        );
      }
    }
  };

  const onError = () => {};

  if (!gigId) {
    return (
      <Stack direction="column" sx={{ backgroundColor: Colors.GrayLight, height: '100vh' }}>
        <GenericHeader goBack={() => navigate(-1)} header={'Gig Report'} />

        <Box sx={{ ...FlexAlign.Center, flex: 1 }}>
          <Typography>Something went wrong, please retry.</Typography>
        </Box>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      sx={{ backgroundColor: Colors.GrayLight, overflowY: 'scroll', height: '100%' }}
    >
      <Box sx={{ top: 0, position: 'fixed', width: '100%', zIndex: 2 }}>
        <GenericHeader goBack={() => navigate(-1)} header={'Gig Report'} />
      </Box>

      <Box sx={{ width: '100%', height: '100px' }} />

      <FormProvider {...methods}>
        <form>
          <Stack
            direction="column"
            sx={{
              padding: horizontalPadding,
            }}
          >
            <SummaryGigLocation />

            <SummaryGigTime />

            <SummaryGigWorkPics
              pictureFieldFormName="beforeWorkPic"
              commentFieldFormName={'beforeWorkComment'}
              title={'Before work pics'}
            />

            <SummaryGigWorkPics
              pictureFieldFormName={'afterWorkPic'}
              commentFieldFormName={'afterWorkComment'}
              title={'After work pics'}
            />

            <VerticalSpacer />

            {error && (
              <Typography sx={{ ...FontSizes.SmallW300, color: Colors.Red, marginTop: '16px' }}>
                {
                  'An error occurred while reporting your Gig. Please try again later or contact support.'
                }
              </Typography>
            )}

            <Button
              sx={(theme) => ({
                border: `2px solid ${theme.vars.palette.primary[500]}`,
                marginBottom: '72px',
                marginTop: '24px',
                width: '100%',
              })}
              type="button"
              variant="outlined"
              onClick={methods.handleSubmit(onSubmit, onError)}
              disabled={methods?.formState?.isSubmitting}
            >
              {methods?.formState?.isSubmitting ? (
                <CircularProgress variant="solid" color="primary" size="sm" />
              ) : (
                'Submit Gig Summary'
              )}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};

export default GigReport;
