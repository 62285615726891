import { Box, Button, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { CheckIconLg } from '../../../assets/svg';
import AuthLayout from '../../../layout/authLayout';

const Success = () => {
  const navigation = useNavigate();
  return (
    <AuthLayout>
      <Box textAlign="center">
        <CheckIconLg />
        <Typography
          mt={5.75}
          sx={{ color: '#6A6A68' }}
          component="h4"
          fontSize="20px"
          lineHeight="32px"
          fontWeight="700"
        >
          New password was updated successfully
        </Typography>
      </Box>

      <Button
        sx={(theme) => ({
          border: `2px solid ${theme.vars.palette.primary[500]}`,
          mt: 8,
        })}
        variant="outlined"
        onClick={() => navigation('/login')}
      >
        Login
      </Button>
    </AuthLayout>
  );
};

export default Success;
