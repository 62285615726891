import { Box, Typography } from '@mui/joy';
import { ONBOARDING_TRAINING_VIDEO_URL } from '../../../config/constants';

import { subTitle } from '..';
import { Colors } from '../../../theme';

const BasicGigTraining = () => {
  return (
    <>
      <Typography my={3} textColor="#6A6A68" {...subTitle}>
        Learn the basics through this training video
      </Typography>
      <Box>
        <iframe
          width={'100%'}
          src={ONBOARDING_TRAINING_VIDEO_URL}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={false}
          title="Frontline Gig Training"
          style={{
            minHeight: '220px ',
            backgroundColor: Colors.GrayDark,
          }}
        />
      </Box>
    </>
  );
};

export default BasicGigTraining;
