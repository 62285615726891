import { Box, Stack, Typography } from '@mui/joy';
import { ProfileMenuItemProps } from '../../types/interfaces';
import { Colors, FlexAlign, FontSizes } from '../../theme';

const WebProfileNavigatorItem = ({
  label,
  onClick,
  Icon,
  isFocused,
}: ProfileMenuItemProps & { isFocused: boolean }) => {
  return (
    <Stack
      direction="row"
      onClick={() => onClick(label)}
      sx={{
        paddingTop: '7px',
        paddingBottom: '7px',
        marginBottom: '21px',
        ...FlexAlign.JustifyStartAlignStart,
        paddingLeft: '24px',
        backgroundColor: isFocused ? Colors.Green : undefined,
        '&:hover': {
          backgroundColor: Colors.Green,
          color: 'white',
          cursor: 'pointer',
        },
      }}
    >
      <Icon color={isFocused ? Colors.Green : Colors.White50} />
      <Box sx={{ marginLeft: '33px' }}>
        <Typography
          sx={{
            color: Colors.White,
            ...FontSizes.BolderCaptionW500,
            pointerEvents: 'none',
          }}
        >
          {label}
        </Typography>
      </Box>
    </Stack>
  );
};

export default WebProfileNavigatorItem;
