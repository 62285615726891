import { Box, IconButton, Stack, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import ProfileEditComponent from '../../../../../../components/ProfileEdit';
import useMobileMode from '../../../../../../hooks/useMobileMode';
import { Colors, FontSizes } from '../../../../../../theme';
import { ProfileMenuProps } from '../../../../../../types/interfaces';
import { BackProfileIcon } from '../../../../../../assets/svg';
import useAnalyticsPageView from '../../../../../../hooks/useAnalyticsPageView';

const ProfileEdit = ({ label }: ProfileMenuProps) => {
  useAnalyticsPageView('Profile Tab - Edit Profile Tab');
  const mobileMode = useMobileMode();
  const navigate = useNavigate();

  const horizontalPadding = {
    paddingLeft: '48px',
    paddingRight: '48px',
    paddingTop: '48px',
    paddingBottom: '16px',
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: Colors.GrayLight,
      }}
    >
      {mobileMode && (
        <Box
          sx={{
            ...horizontalPadding,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: Colors.White,
          }}
        >
          <IconButton
            sx={{
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              justifyContent: 'start',
              padding: 0,
            }}
            onClick={() => navigate('/dashboard/profile')}
          >
            <BackProfileIcon />
          </IconButton>
          <Typography sx={{ ...FontSizes.BoldestCaptionW700 }}>{label}</Typography>
        </Box>
      )}
      <Stack
        direction="column"
        sx={{
          backgroundColor: Colors.GrayLight,
          width: '100%',
          marginBottom: '40px',
        }}
      >
        <ProfileEditComponent />
      </Stack>
    </Box>
  );
};

export default ProfileEdit;
