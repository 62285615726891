import { Box, Stack, Typography } from '@mui/joy';
import { GigFilterBodyHeaderProps } from '../../types/interfaces';
import { Colors, FontSizes } from '../../theme';
import { ChevronIcon } from '../../assets/svg';

const GigFilterBodyHeader = ({
  filterName,
  containerStyle,
  onBack,
  onReset,
}: GigFilterBodyHeaderProps) => {
  return (
    <Stack sx={{ ...styles.container, ...containerStyle }} direction="row">
      <Box sx={styles.chevronContainer} onClick={onBack}>
        <ChevronIcon />
      </Box>
      <Typography sx={styles.filterName}>{filterName}</Typography>
      <Box sx={styles.resetTextContainer} onClick={onReset}>
        <Typography sx={styles.resetText}>Reset</Typography>
      </Box>
    </Stack>
  );
};

export default GigFilterBodyHeader;

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '20px',
  },
  chevronContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'rotate(180deg)',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingTop: '4px',
    paddingBottom: '4px',
    marginLeft: '-8px',
  },
  filterName: {
    ...FontSizes.BoldestCaptionW700,
    marginLeft: '20px',
  },
  resetTextContainer: {
    marginLeft: 'auto',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingRight: '8px',
    paddingLeft: '8px',
  },
  resetText: {
    ...FontSizes.ButtonSmallW700,
    color: Colors.Red,
  },
};
