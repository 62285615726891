import { Stack } from '@mui/joy';
import { useEffect, useState } from 'react';
import FeedSection from './FeedSection';
import UnlockMoreGigsSection from './UnlockMoreGigsSection/intex';
import { me } from '../../../../api/user';
import { useWindowHeight } from '@react-hook/window-size';
import useMobileMode from '../../../../hooks/useMobileMode';
import { useParams, useNavigate } from 'react-router-dom';

const Home = () => {
  const { section } = useParams();
  const navigate = useNavigate();

  const height = useWindowHeight();

  const isMobileMode = useMobileMode();

  const [isPaymentMethodAdded, setIsPaymentMethodAdded] = useState(false);
  const [isBasicGigTrainingCompleted, setIsBasicGigTrainingCompleted] = useState(false);

  useEffect(() => {
    (async () => {
      const myself = await me();

      setIsPaymentMethodAdded(myself?.data?.user?.isPaymentMethodAdded);
      setIsBasicGigTrainingCompleted(myself?.data?.user?.isBasicGigTrainingCompleted);
    })();
  }, []);

  return (
    <Stack
      direction="column"
      sx={{
        display: 'flex',
        width: '100%',
        height: isMobileMode ? height - 55 : height,
        overflowY: 'scroll',
      }}
    >
      {section === 'feed' && (
        <FeedSection
          onUnlockMoreGigsClick={() => navigate('/dashboard/home/unlock-more-gigs')}
          isPaymentMethodAdded={isPaymentMethodAdded}
          isBasicGigTrainingCompleted={isBasicGigTrainingCompleted}
        />
      )}

      {section === 'unlock-more-gigs' && (
        <UnlockMoreGigsSection
          goBack={() => navigate('/dashboard/home/feed')}
          isPaymentMethodAdded={isPaymentMethodAdded}
          isBasicGigTrainingCompleted={isBasicGigTrainingCompleted}
        />
      )}
    </Stack>
  );
};

export default Home;
