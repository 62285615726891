import React from 'react';
import { useState } from 'react';
import { IconButton, Link, Typography } from '@mui/joy';
import AuthLayout from '../../layout/authLayout';
import SignInEmail from './SignInEmail';
import SignInPhone from './SignInPhone';
import { BackIcon } from '../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { backBtnStyles } from '../../config/styleConstants';

const SignIn = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();

  const signInComponent = [<SignInPhone setActiveStep={setActiveStep} />, <SignInEmail />];

  const handleBack = () => {
    setActiveStep(0);
  };
  return (
    <AuthLayout>
      {activeStep !== 0 && (
        <IconButton sx={backBtnStyles} onClick={handleBack}>
          <BackIcon />
        </IconButton>
      )}
      <Typography component="h4" fontSize="1.75rem" lineHeight="40px" fontWeight="700">
        Sign In
      </Typography>

      {signInComponent[activeStep]}
      <Typography mt={3} component="p" fontSize="1rem" textAlign="center">
        Don't have an account? <Link onClick={() => navigate('/signup')}>Sign Up</Link>
      </Typography>
    </AuthLayout>
  );
};

export default SignIn;
