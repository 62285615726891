import { Box, Button, Stack, styled, Typography } from '@mui/joy';
import { useAutocomplete, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { Search } from '../../assets/svg';
import { Colors, FontSizes } from '../../theme';
import { Close } from '../../assets/svg/index';
import {
  ActionableSearchInputProps,
  ActionableSearchInputSubmitMode,
  AutoCompleteOptionsPickerMode,
} from '../../types/interfaces';
import { difference } from 'lodash';
import { THIS_FIELD_CANNOT_BE_EMPTY } from '../../config/errorMessages';

const StyledUl = styled('ul')`
  background-color: #ededed;
  list-style: none;
  border-radius: 8px;
  max-height: 142px;
  overflow-y: scroll;
`;

const StyledLi = styled('li')`
  background-color: #ededed;
  width: 80%;

  &:first-of-type {
    padding-top: 12px;
  }

  padding-top: 4px;
  padding-bottom: 4px;

  &:last-of-type {
    padding-bottom: 12px;
  }

  &:hover {
    background-color: #fff;
  }
`;

const StyledTextField = styled(TextField)(() => ({
  [`& .MuiInputBase-input`]: {
    '::placeholder': {
      color: Colors.AlmostBlack,
      opacity: 1,
    },
  },
}));

const ActionableSearchInput = ({
  onClick,
  buttonLabel,
  submitMode,
  placeholder,
  validateOnSubmit,
  autoCompleteOptions = [],
  autoCompleteSelectedOptions = [],
  autoCompleteOptionsPickerMode = AutoCompleteOptionsPickerMode.Bottom,
}: ActionableSearchInputProps) => {
  const [text, setText] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showCleanButton, setShowCleanButton] = useState<boolean>(false);

  const isAutoCompleteEnabled = !!autoCompleteOptions.length;

  const handleClick = () => {
    // validation
    if (text.length === 0) {
      setError(THIS_FIELD_CANNOT_BE_EMPTY);
      return;
    }

    const detectedErrorMessage = validateOnSubmit(text);

    if (detectedErrorMessage !== null) {
      setError(detectedErrorMessage);
      return;
    }

    onClick(text);

    if (submitMode === ActionableSearchInputSubmitMode.AutoCleanOnSubmit) {
      setText('');
    } else if (submitMode === ActionableSearchInputSubmitMode.ManualCleanOnSubmit) {
      setShowCleanButton(true);
    }
  };

  const handleCleanUpClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setText('');
    setShowCleanButton(false);
  }, []);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      id: 'autocomplete',
      value: text,
      onChange: (event, selectedOption) => {
        setText(selectedOption as string);
      },
      options: autoCompleteOptions,
      isOptionEqualToValue: (option, value) => {
        return option === value || value === '';
      },
      filterOptions: (options, state) => {
        return difference(options, autoCompleteSelectedOptions);
      },
    });

  const autoCompleteInputProps = getInputProps() as React.InputHTMLAttributes<HTMLInputElement> & {
    ref: any;
  };

  const getRootPropsConditionally = () => {
    if (isAutoCompleteEnabled) {
      return getRootProps();
    } else {
      return {};
    }
  };

  const resetError = () => {
    if (error.length) {
      setError('');
    }
  };

  const renderAutoCompletePicker = () => {
    return isAutoCompleteEnabled && groupedOptions.length ? (
      <StyledUl {...getListboxProps()}>
        {(groupedOptions as string[]).map((option, index) => (
          <StyledLi {...getOptionProps({ option, index })} key={option}>
            <Typography sx={{ ...FontSizes.BodyLargeW300 }}>{option}</Typography>
          </StyledLi>
        ))}
      </StyledUl>
    ) : null;
  };

  return (
    <Box
      {...getRootPropsConditionally()}
      sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
    >
      {isAutoCompleteEnabled && autoCompleteOptionsPickerMode === AutoCompleteOptionsPickerMode.Top
        ? renderAutoCompletePicker()
        : null}

      <Stack
        direction="row"
        style={{
          border: `1.5px solid ${error ? Colors.Red : Colors.Blue}`,
        }}
        sx={{
          backgroundColor: Colors.White,
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '48px',
          paddingRight: '2px',
        }}
      >
        <Box sx={{ marginLeft: '12px', marginRight: '12px' }}>
          <Search />
        </Box>

        {isAutoCompleteEnabled ? (
          <StyledTextField
            {...getInputProps()}
            value={autoCompleteInputProps.value}
            onChange={(e) => {
              (autoCompleteInputProps.onChange as React.ChangeEventHandler<HTMLInputElement>)(
                e as any,
              );

              setText(e.target.value);

              resetError();
            }}
            onFocus={(e) => {
              (autoCompleteInputProps.onFocus as React.FocusEventHandler<HTMLInputElement>)(
                e as any,
              );
              resetError();
            }}
            type={'text'}
            placeholder={placeholder}
            variant="standard"
            error={error?.length > 0}
            label={error}
            InputProps={{
              disableUnderline: true,
              style: {
                color: Colors.Black,
                ...FontSizes.MediumW600,
              },
            }}
            style={{
              display: 'flex',
              flex: 1,
            }}
            size={'medium'}
            color={'primary'}
          />
        ) : (
          <StyledTextField
            value={text}
            onChange={(e) => {
              if (error?.length > 0) {
                setError('');
              }

              setText(e.target.value);
            }}
            onFocus={resetError}
            type={'text'}
            placeholder={placeholder}
            variant="standard"
            error={error?.length > 0}
            label={error}
            autoComplete="off"
            InputProps={{
              disableUnderline: true,
              style: {
                color: Colors.Black,
                ...FontSizes.MediumW600,
              },
            }}
            style={{
              display: 'flex',
              flex: 1,
            }}
            size={'medium'}
            color={'primary'}
          />
        )}

        {showCleanButton ? (
          text?.length ? (
            <Box
              onClick={handleCleanUpClick}
              sx={{
                width: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Close color={Colors.Black} />
            </Box>
          ) : null
        ) : (
          <Button
            style={{
              minHeight: '32px',
              height: '32px',
              marginRight: '6.5px',
            }}
            sx={{
              color: Colors.White,
              borderRadius: '4px',
              backgroundColor: Colors.Blue,
              marginLeft: '8px',
            }}
            onClick={handleClick}
          >
            {buttonLabel}
          </Button>
        )}
      </Stack>

      {isAutoCompleteEnabled &&
      autoCompleteOptionsPickerMode === AutoCompleteOptionsPickerMode.Bottom
        ? renderAutoCompletePicker()
        : null}
    </Box>
  );
};

export default ActionableSearchInput;
