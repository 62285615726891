import { Stack, Typography } from '@mui/joy';
import React from 'react';
import InputField from '../../../components/InputField';

const StepSix = () => {
  return (
    <>
      <Typography mt={3} component="h4" fontSize="1.75rem" lineHeight="40px" fontWeight="700">
        Cool. Enter your Zip Code.
      </Typography>
      <Stack mt="27px" mb="46px" gap={2} justifyContent="center">
        <InputField label="Enter your ZipCode" name="zipCode" type="number" />
      </Stack>
    </>
  );
};

export default StepSix;
