import { SubmitButtonProps } from '../../types/interfaces';
import { Colors } from '../../theme';
import { Button } from '@mui/material';
import { CircularProgress } from '@mui/joy';

const SubmitButton = ({ label, isLoading, onClick, customButtonStyle = {} }: SubmitButtonProps) => {
  return (
    <Button
      type="submit"
      sx={{ ...styles.button, ...customButtonStyle }}
      disabled={isLoading}
      variant="contained"
      onClick={onClick}
    >
      {isLoading ? <CircularProgress variant="solid" color="primary" size="sm" /> : label}
    </Button>
  );
};

export default SubmitButton;

const styles = {
  button: {
    backgroundColor: Colors.Blue,
    color: Colors.White,
    '&.Joy-disabled': {
      color: Colors.White,
    },
    marginTop: '16px',
    borderRadius: 0,
    fontWeight: 700,
    fontSize: '15px',
    textTransform: 'none',
  },
};
