import React from 'react';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { me } from '../api/user';
import { Loader } from '../assets/svg';
import { AuthContextType, User } from '../types/interfaces';
import errorReporting from '../utils/errorReporting';

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Logout user and remove token from storage
   */
  const logout = () => {
    window.localStorage.removeItem('token');
    setUser(undefined);
  };

  const refetch = async () => {
    try {
      setLoading(true);
      const res = await me();
      setUser({
        ...res?.data?.user,
        token: window.localStorage.getItem('token'),
      });
    } catch (error) {
      console.error(error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const refetchWithoutLoadingUI = async () => {
    try {
      const res = await me();
      setUser({
        ...res?.data?.user,
        token: window.localStorage.getItem('token'),
      });
    } catch (error) {
      console.error(error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      setUser(null);
    }
  };

  // Make the provider update only when it should
  const memoedUser = useMemo(
    () => ({
      user,
      logout,
      setUser,
      refetch,
      refetchWithoutLoadingUI,
    }),
    [user],
  );

  useEffect(() => {
    if (window.localStorage.token) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.localStorage.token]);

  return (
    <AuthContext.Provider value={memoedUser as AuthContextType}>
      {loading ? <Loader /> : children}
    </AuthContext.Provider>
  );
};

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
