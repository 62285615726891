import axios from '.';
import { City } from '../types/interfaces';

export const addCity = async ({ name }: { name: City['name'] }) => {
  return axios.put<{ name: City['name'] }>(`/available-cities`, { name });
};

export const deleteCity = async ({ id }: { id: City['id'] }) => {
  return axios.delete(`/available-cities/${id}`);
};

export const getCities = async () => {
  return axios.get('/available-cities');
};
