import { Stack } from '@mui/joy';
import ProfileMyViewComponent from '../../../../../../components/ProfileView';
import { Colors } from '../../../../../../theme';
import { ProfileMenuProps } from '../../../../../../types/interfaces';
import { useWindowHeight } from '@react-hook/window-size';
import useMobileMode from '../../../../../../hooks/useMobileMode';
import useAnalyticsPageView from '../../../../../../hooks/useAnalyticsPageView';

const ProfileView = ({ label }: ProfileMenuProps) => {
  useAnalyticsPageView('Profile Tab - View Profile Tab');
  const height = useWindowHeight();
  const mobileMode = useMobileMode();

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.GrayLight,
        width: '100%',
        height: mobileMode ? height - 55 : height,
        overflowY: 'scroll',
      }}
    >
      <ProfileMyViewComponent />
    </Stack>
  );
};

export default ProfileView;
