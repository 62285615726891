import { Box, CircularProgress, Stack, Typography } from '@mui/joy';
import { StepProps } from '../../types/interfaces';
import { CheckDone, CheckNotDone, ChevronIcon } from '../../assets/svg';
import { useCallback } from 'react';
import { Colors } from '../../theme';
import HorizontalSpacer from '../HorizontalSpacer';
import { Img } from 'react-image';
import { FontSizes } from '../../theme/index';

const Step = ({
  onClick,
  completed,
  headline,
  title,
  description,
  timeToCompletionInMinutes,
  pictures,
}: StepProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      onClick();
    },
    [onClick],
  );

  let viewablePictures = pictures;
  if (pictures !== undefined && pictures.length > 8) {
    viewablePictures = pictures?.slice(0, 8);
  }

  return (
    <Stack
      onClick={handleClick}
      direction="row"
      sx={{ backgroundColor: 'white', borderRadius: '16px', padding: '20px' }}
    >
      <Box>{completed ? <CheckDone /> : <CheckNotDone />}</Box>

      <Stack sx={{ marginLeft: '14px', marginRight: '14px' }}>
        <Typography
          sx={{
            color: Colors.Blue,
            ...FontSizes.Header4W700,
            marginBottom: '2px',
          }}
        >
          {headline}
        </Typography>

        {viewablePictures?.length ? (
          <Stack direction="row" sx={{ width: '100%' }}>
            {viewablePictures.map((picture, index) => (
              <Img
                key={picture}
                src={picture}
                width={26}
                height={26}
                style={{
                  borderRadius: '13px',
                  border: '1px solid white',
                  position: 'relative',
                  right: `${index * 7}px`,
                  objectFit: 'cover',
                }}
                loader={<CircularProgress size={'sm'} />}
              />
            ))}
            {pictures !== undefined && pictures?.length > 8 ? (
              <Box
                sx={{
                  width: '26px',
                  height: '26px',
                  borderRadius: '13px',
                  backgroundColor: Colors.GrayLight,
                  border: '1px solid white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  right: `${8 * 7}px`,
                }}
              >
                <Typography sx={{ ...FontSizes.SmallW300, color: Colors.Blue }}>{`+${
                  pictures.length - 8
                }`}</Typography>
              </Box>
            ) : null}
          </Stack>
        ) : (
          <>
            <Typography
              sx={{
                color: Colors.Black,
                ...FontSizes.Header3W700,
                marginBottom: '8px',
              }}
            >
              {title}
            </Typography>
            {description !== undefined ? (
              <Typography
                sx={{
                  ...FontSizes.SmallW300,
                  color: Colors.Black,
                  marginBottom: '8px',
                }}
              >
                {description}
              </Typography>
            ) : null}
            {timeToCompletionInMinutes !== undefined ? (
              <Typography
                sx={{
                  color: Colors.AlmostBlack,
                  ...FontSizes.SmallW300,
                }}
              >{`Time to completion: ${timeToCompletionInMinutes} min`}</Typography>
            ) : null}
          </>
        )}
      </Stack>

      <HorizontalSpacer />

      <Box>
        <ChevronIcon />
      </Box>
    </Stack>
  );
};

export default Step;
