import { Button, Stack, Typography } from '@mui/joy';
import { Colors, FontSizes } from '../../theme';
import React, { useCallback, useState } from 'react';
import useMobileMode from '../../hooks/useMobileMode';
import { GigDetailsFooterBlockAppliedGigProps, ToastMode } from '../../types/interfaces';
import { deleteGigApplication } from '../../api/gigs';
import triggerToast from '../../utils/triggerToast';
import analytics from '../../utils/analytics';
import errorReporting from '../../utils/errorReporting';

const GigDetailsFooterBlockAppliedGig = ({
  gigId,
  setGig,
}: GigDetailsFooterBlockAppliedGigProps) => {
  const mobileMode = useMobileMode();
  const mobileAwareBorderRadius = mobileMode ? '0px' : '16px';
  const mobileAwareHorizontalMargin = mobileMode ? '8px 0 0 0' : '16px 48px 0 48px';

  const [isLoading, setIsLoading] = useState(false);

  const onDeleteApplicationClick = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const response = await deleteGigApplication({
        gigId: gigId as string,
      });

      setGig(response.data.updatedGig);

      analytics.track('Cancel Gig Application');
    } catch (error: any) {
      errorReporting.captureException(error, {
        level: 'error',
      });
      console.error(error);
      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while cancelling your application. Please retry or contact support.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, gigId, setGig]);

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.White,
        padding: '16px',
        borderRadius: mobileAwareBorderRadius,
        margin: mobileAwareHorizontalMargin,
      }}
    >
      <Typography sx={{ ...FontSizes.MediumW600, marginBottom: '16px' }}>Applied</Typography>
      <Button
        sx={(theme) => ({
          border: `2px solid ${theme.vars.palette.danger[500]}`,
          marginBottom: '24px',
          width: '100%',
          color: theme.vars.palette.danger[500],
        })}
        type="button"
        variant="outlined"
        loading={isLoading}
        onClick={onDeleteApplicationClick}
      >
        Cancel Application
      </Button>
    </Stack>
  );
};

export default React.memo(GigDetailsFooterBlockAppliedGig);
