import { Stack, Typography, Box } from '@mui/joy';
import { useCallback } from 'react';
import { ArrowLeft } from '../../assets/svg';
import { Colors, FlexAlign, FontSizes } from '../../theme';
import { UnlockMoreGigsHeaderProps } from '../../types/interfaces';
import useMobileMode from '../../hooks/useMobileMode';
import { useWindowWidth } from '@react-hook/window-size';
import { WEB_NAVIGATION_BAR_WIDTH } from '../../config/constants';

const Bar = ({
  numberOfCompletedSteps,
  innerWidth,
  containerWidth,
}: {
  numberOfCompletedSteps: number;
  innerWidth: number | string;
  containerWidth: number | string;
}) => {
  const mobileMode = useMobileMode();

  return (
    <Box sx={{ ...FlexAlign.Center, width: containerWidth }}>
      <Stack direction="row" sx={{ width: innerWidth }} spacing={'2px'}>
        {['1', '2', '3'].map((item, index) => (
          <Box
            key={item}
            sx={{
              display: 'flex',
              flex: 1,
              height: 8,
              backgroundColor: mobileMode ? Colors.White : Colors.Green,
              opacity: numberOfCompletedSteps > index ? 1 : 0.5,
            }}
          />
        ))}
      </Stack>
    </Box>
  );
};

const UnlockMoreGigsHeader = ({
  goBack,
  backgroundColor,
  numberOfCompletedSteps,
}: UnlockMoreGigsHeaderProps) => {
  const mobileMode = useMobileMode();

  const handleGoBackClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      goBack();
    },
    [goBack],
  );

  const width = useWindowWidth();

  return (
    <Stack
      sx={{
        backgroundColor,
        height: '80px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingBottom: '16px',
        width,
        position: 'fixed',
      }}
    >
      <Stack
        direction="row"
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '24px',
          marginLeft: '24px',
        }}
      >
        <Box sx={{ height: '30px', width: '30px' }} onClick={handleGoBackClick}>
          <ArrowLeft />
        </Box>
        <Typography
          sx={{
            display: 'flex',
            flex: 1,
            color: '#000000',
            ...FontSizes.Header2W700,
            marginBottom: '8px',
            paddingRight: '20px',
          }}
        >
          Unlock more Gigs
        </Typography>
      </Stack>

      <Bar
        numberOfCompletedSteps={numberOfCompletedSteps}
        containerWidth={mobileMode ? '100%' : width - WEB_NAVIGATION_BAR_WIDTH}
        innerWidth={mobileMode ? width - 48 : width - 228}
      />
    </Stack>
  );
};

export default UnlockMoreGigsHeader;
