import React from 'react';
import { useState } from 'react';
import { Stack } from '@mui/system';
import { isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, IconButton, Typography } from '@mui/joy';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import InputField from '../../components/InputField';
import AuthLayout from '../../layout/authLayout';
import { sendSmsVerificationCode } from '../../api/auth';
import PhoneInput from '../../components/phoneInput';
import { forgotPasswordWithEmail, forgotPasswordWithPhone } from '../../validation';
import { BackIcon } from '../../assets/svg';
import { TIME_INTERVAL_FOR_MESSAGE } from '../../config/constants';
import CustomAlert from '../../components/CustomAlert';
import useShowAlert from '../../hooks/useShowAlert';
import { backBtnStyles } from '../../config/styleConstants';
import useAnalyticsPageView from '../../hooks/useAnalyticsPageView';
import errorReporting from '../../utils/errorReporting';

export const ForgetForm = ({
  continueWithEmail,
  setContinueWithEmail,
}: {
  continueWithEmail: boolean;
  setContinueWithEmail: Function;
}) => {
  const { formState, reset } = useFormContext();

  return (
    <>
      <Typography mt={9} mb={4.5} fontSize="14px" lineHeight="17.07px" fontWeight="bold">
        Enter the {continueWithEmail ? 'Email' : 'Phone'} via which you were registered
      </Typography>
      {continueWithEmail ? (
        <InputField label="Type here" name="value" />
      ) : (
        <PhoneInput name="value" />
      )}

      <Stack direction="column" gap="20px">
        <Button
          sx={(theme) => ({
            backgroundColor: theme.vars.palette.primary[500],
            color: theme.vars.palette.common.white,
            mt: 5,
            '&:hover': {
              backgroundColor: theme.vars.palette.primary[500],
              color: theme.vars.palette.common.white,
            },
          })}
          type="submit"
          variant="outlined"
        >
          {formState.isSubmitting ? (
            <CircularProgress variant="solid" color="primary" size="sm" />
          ) : (
            'Restore Password'
          )}
        </Button>
        <Typography textAlign="center" fontSize="14px" lineHeight="17.07px" fontWeight="bold">
          Or
        </Typography>
        <Button
          sx={(theme) => ({
            border: `2px solid ${theme.vars.palette.primary[500]}`,
            width: '100%',
          })}
          onClick={() => {
            reset({});
            setContinueWithEmail(!continueWithEmail);
          }}
          variant="outlined"
        >
          Continue with {!continueWithEmail ? 'Email' : 'Phone'}
        </Button>
      </Stack>
    </>
  );
};

const Index = () => {
  useAnalyticsPageView('Forget Password');
  const [continueWithEmail, setContinueWithEmail] = useState(true);
  const [message, setMessage] = useState<string>('');
  const { showAlert, setShowAlert } = useShowAlert(TIME_INTERVAL_FOR_MESSAGE);
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      value: '',
    },
    resolver: yupResolver(continueWithEmail ? forgotPasswordWithEmail : forgotPasswordWithPhone),
    mode: 'onChange',
  });

  const { handleSubmit, setError, reset } = methods;
  const navigate = useNavigate();

  /**
   * This method sends the link or code to email or number respectively.
   * @param {value:string} with value (phone or email)
   * @returns Promise
   */
  const onSubmit = async (data: { value: string }) => {
    try {
      const res = await sendSmsVerificationCode({
        value: data.value,
        verifyAccount: true,
      });
      if (continueWithEmail) {
        setMessage(res?.data?.message);
        setShowAlert(true);
      } else {
        navigate('/forget-password/verify', {
          state: {
            phoneNumber: data.value,
          },
        });
      }
      reset({});
    } catch (error) {
      errorReporting.captureException(error, {
        level: 'error',
      });
      if (isAxiosError(error)) {
        setError('value', { type: 'custom', message: error.response?.data?.message });
      }
    }
  };

  return (
    <AuthLayout>
      <IconButton sx={backBtnStyles} onClick={() => navigate('/login')}>
        <BackIcon />
      </IconButton>
      <Typography component="h4" fontSize="1.75rem" lineHeight="40px" fontWeight="700">
        Forget Password
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {showAlert && (
            <CustomAlert
              message={message!}
              color="success"
              startDecorator={<CheckCircleIcon fontSize="small" />}
            />
          )}
          <ForgetForm
            continueWithEmail={continueWithEmail}
            setContinueWithEmail={setContinueWithEmail}
          />
        </form>
      </FormProvider>
    </AuthLayout>
  );
};

export default Index;
