import useAuth from '../../hooks/useAuth';
import { ProtectedLayoutProps } from '../../types/interfaces';
import Onboarding from '../../pages/Onboarding';
import isTokenValid from '../../utils/isTokenValid';
import { Box, CircularProgress, Stack, Typography } from '@mui/joy';
import { FlexAlign } from '../../theme';
import { ReportProblem } from '@mui/icons-material';

/**
 * This method is an check to verify if the user is logged in.
 * @param {React.ReactNode} children
 * @returns JSX.Element
 */
export const ProtectedLayout = ({ children }: ProtectedLayoutProps) => {
  const { user } = useAuth();
  const tokenValid = isTokenValid();

  if (!tokenValid) {
    window.location.href = '/';
    return null;
  }

  if (user && user.isOnBoardingComplete === true) {
    return <>{children}</>;
  }

  if (user && user.isOnBoardingComplete === false) {
    return <Onboarding />;
  }

  if (user === null) {
    return (
      <Stack direction="column" sx={{ ...FlexAlign.Center, flex: 1, padding: '80px' }}>
        <ReportProblem sx={{ fontSize: '80px' }} />
        <Typography sx={{ textAlign: 'center', marginTop: '16px' }}>
          There has been an error while reaching the server out.
        </Typography>
        <Typography sx={{ textAlign: 'center', marginBottom: '16px', marginTop: '16px' }}>
          Please reload the page.
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          If the page does not reload, please try later or contact support.
        </Typography>
      </Stack>
    );
  }

  return (
    <Box sx={{ ...FlexAlign.Center, flex: 1, height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
};

export default ProtectedLayout;
