import { Stack, Typography } from '@mui/joy';
import { GigDetailsTravelTipsProps } from '../../types/interfaces';
import { Colors, FontSizes } from '../../theme';
import React from 'react';
import useMobileMode from '../../hooks/useMobileMode';

const GigDetailsTravelTips = ({ travelTips }: GigDetailsTravelTipsProps) => {
  const mobileMode = useMobileMode();
  const mobileAwareBorderRadius = mobileMode ? '0px' : '16px';
  const mobileAwareHorizontalMargin = mobileMode ? '8px 0 0 0' : '16px 48px 0 48px';

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.White,
        padding: '16px',
        borderRadius: mobileAwareBorderRadius,
        margin: mobileAwareHorizontalMargin,
      }}
    >
      <Typography
        sx={{
          color: Colors.Black,
          ...FontSizes.Header4W700,
          marginBottom: '12px',
          marginTop: '4px',
        }}
      >
        Travel tips
      </Typography>
      <Typography
        sx={{
          color: Colors.Black,
          ...FontSizes.BodyRegularW300,
        }}
      >
        {travelTips}
      </Typography>
    </Stack>
  );
};

export default React.memo(GigDetailsTravelTips);
