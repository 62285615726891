import { Checkbox, Link, Stack, Typography } from '@mui/joy';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  PRIVACY_AND_DATA_POLICY_DOCUMENT_LINK,
  TERMS_AND_CONDITIONS_DOCUMENT_LINK,
} from '../../../config/constants';

const StepOne = () => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const { control } = useFormContext();

  const handleHover = () => {
    setIsHover(!isHover);
  };

  return (
    <>
      <Typography component="h4" fontSize="1.75rem" lineHeight="40px" fontWeight="700">
        Kindly review our{' '}
        <Link underline="hover" target="_blank" href={PRIVACY_AND_DATA_POLICY_DOCUMENT_LINK}>
          Privacy Policy
        </Link>
        ,{' '}
        <Link
          href={TERMS_AND_CONDITIONS_DOCUMENT_LINK}
          target="_blank"
          sx={{ textDecoration: `${isHover && 'underline'}` }}
          onMouseOver={handleHover}
          onMouseLeave={handleHover}
        >
          Terms{' '}
        </Link>{' '}
        <Link
          sx={{ textDecoration: `${isHover && 'underline'}` }}
          onMouseOver={handleHover}
          onMouseLeave={handleHover}
        >
          of Use
        </Link>{' '}
        and{' '}
        <Link underline="hover" href={PRIVACY_AND_DATA_POLICY_DOCUMENT_LINK} target="_blank">
          Data Policy
        </Link>{' '}
        to begin the Frontline Gigs platform
      </Typography>

      <Stack mt={10.75}>
        <Controller
          name="termsAndConditions"
          control={control}
          render={({ field }) => {
            return (
              <Checkbox
                {...field}
                checked={field.value}
                label="I accept the terms and conditions"
              />
            );
          }}
        />
      </Stack>
    </>
  );
};

export default StepOne;
