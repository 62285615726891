import toast from 'react-hot-toast';
import { ServerError, ToastMode } from '../types/interfaces';

const triggerToast = ({
  mode,
  error,
  fallbackErrorMessage,
}: {
  mode: ToastMode;
  error: ServerError;
  fallbackErrorMessage?: string;
}) => {
  setTimeout(toast.dismiss, 1000);
  if (mode === ToastMode.Error) {
    toast.error(error?.response?.data?.message || (fallbackErrorMessage as string), {
      duration: 1000,
    });
  }
  if (mode === ToastMode.Success) {
    toast.success('Success!', { duration: 1000 });
  }
  if (mode === ToastMode.WellDone) {
    toast('Well Done!', {
      icon: '👏',
      duration: 1000,
    });
  }
};

export default triggerToast;
