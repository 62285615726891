import React from 'react';
import { Alert, Stack, Typography } from '@mui/joy';
import { CustomAlertProps } from '../../types/interfaces';

const CustomAlert = ({ message, title, ...rest }: CustomAlertProps) => {
  return (
    <Alert
      variant="soft"
      sx={{
        gap: 1,
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        '& .JoyAlert-endDecorator': { top: 10, right: 10, position: 'absolute' },
      }}
      {...rest}
    >
      <Stack direction="column" sx={{ '& p': { wordBreak: 'break-word' } }}>
        {title && (
          <Typography fontWeight="lg" mt={0.25}>
            {title}
          </Typography>
        )}
        <Typography fontSize="sm" sx={{ opacity: 0.8 }}>
          {message}
        </Typography>
      </Stack>
    </Alert>
  );
};

export default CustomAlert;
