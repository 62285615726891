import { Stack, Typography } from '@mui/joy';
import React from 'react';
import InputField from '../../../components/InputField';

const StepTwo = () => {
  return (
    <>
      <Typography mt={3} component="h4" fontSize="1.75rem" lineHeight="40px" fontWeight="700">
        What is your full name?
      </Typography>
      <Stack mt="20px" gap={2}>
        <InputField label="First Name" name="firstName" />
        <InputField label="Middle Name" name="middleName" />
        <InputField label="Last Name" name="lastName" />
      </Stack>
    </>
  );
};

export default StepTwo;
