import React from 'react';
import { useEffect, useState } from 'react';
import { isAxiosError } from 'axios';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, CircularProgress, IconButton, Link, Typography } from '@mui/joy';

import AuthLayout from '../../layout/authLayout';
import { login, sendSmsVerificationCode } from '../../api/auth';
import { setAuthToken } from '../../api';
import useAuth from '../../hooks/useAuth';
import {
  DEFAULT_TIMEOUT_SECONDS,
  TIME_INTERVAL_FOR_MESSAGE,
  TIME_INTERVAL_FOR_SECONDS,
} from '../../config/constants';
import CustomAlert from '../../components/CustomAlert';
import useShowAlert from '../../hooks/useShowAlert';
import errorReporting from '../../utils/errorReporting';

const Verification = () => {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');

  const { showAlert, setShowAlert } = useShowAlert(TIME_INTERVAL_FOR_MESSAGE);
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const location = useLocation();
  const { state } = location;
  const { setUser } = useAuth();
  const [seconds, setSeconds] = useState<number>(DEFAULT_TIMEOUT_SECONDS);

  /**
   * Method that sends the call to `send-sms-verification-code` API
   * @returns Promise
   */
  const resendSmsVerificationCode = async () => {
    try {
      setLoading(true);
      await sendSmsVerificationCode({ value: `${state?.phoneNumber}`, verifyAccount: true });
      setSeconds(DEFAULT_TIMEOUT_SECONDS);
      if (location.pathname !== '/verification') {
        navigate('/verification', { state: `${state?.phoneNumber}` });
      }
    } catch (error: unknown) {
      errorReporting.captureException(error, {
        level: 'error',
      });
      if (isAxiosError(error)) {
        setErrorMsg(error.response?.data?.message);
        setShowAlert(true);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * This method calls login API
   * @returns Promise
   */
  const handleContinue = async () => {
    try {
      setWaiting(true);
      const res = await login({
        value: state?.phoneNumber,
        code: otp,
      });

      window.localStorage.setItem('token', res?.data?.token);
      setAuthToken(res?.data?.token);
      if (res?.data?.token) {
        setUser({
          token: res?.data?.token || '',
        });
      }
      navigate('/dashboard/home/feed');
    } catch (error) {
      errorReporting.captureException(error, {
        level: 'error',
      });
      if (isAxiosError(error)) {
        setErrorMsg(error.response?.data?.message);
        setShowAlert(true);
      }
    } finally {
      setWaiting(false);
    }
  };

  /**
   * Return to login route if there is no phone
   * number in router state.
   */
  useEffect(() => {
    if (!state?.phoneNumber) {
      navigate('/login');
    }
  }, [navigate, state?.phoneNumber]);

  useEffect(() => {
    const myInterval = setTimeout(() => {
      if (seconds && seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, TIME_INTERVAL_FOR_SECONDS);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <AuthLayout>
      <Typography mt={3} component="h4" fontSize="1.75rem" lineHeight="40px" fontWeight="700">
        Enter your code
      </Typography>
      <Typography
        mt="12px"
        sx={{ color: '#888EA1' }}
        component="p"
        fontSize="1rem"
        lineHeight="24px"
        fontWeight="300"
      >
        Enter the code we sent to {state?.phoneNumber || '--'}
      </Typography>

      <Link
        onClick={() => navigate('/login')}
        mt="5px"
        component="p"
        fontSize="1rem"
        lineHeight="24px"
        fontWeight="300"
      >
        Change number
      </Link>
      <Box mt={6}>
        <OtpInput
          isInputNum
          value={otp}
          onChange={(value: string) => {
            setOtp(value);
          }}
          inputStyle="OtpInput"
          containerStyle="OtpInputContainer"
          focusStyle={{
            boxShadow: '0px 0px 16px rgba(24, 73, 245, 0.15)',
            backgroundColor: '#fff',
            outline: 'none',
          }}
          numInputs={6}
        />
      </Box>
      <Link
        mt="20px"
        mx="auto"
        mb="50px"
        fontSize="1rem"
        lineHeight="24px"
        fontWeight="700"
        disabled={waiting || loading || !!seconds}
        onClick={resendSmsVerificationCode}
        justifyContent="center"
        sx={{
          '&.Joy-disabled': {
            color: 'rgba(var(--joy-palette-primary-mainChannel) / 1)',
          },
        }}
      >
        {loading ? 'Sending...' : `Request New Code ${seconds ? `(${seconds})s` : ''}`}
      </Link>
      {showAlert && (
        <CustomAlert
          title="Oops!"
          message={errorMsg!}
          startDecorator={<WarningIcon sx={{ mt: '2px', mx: '4px' }} fontSize="small" />}
          variant="soft"
          color="danger"
          endDecorator={
            <IconButton
              onClick={() => {
                setShowAlert(false);
              }}
              variant="soft"
              size="sm"
              color="danger"
            >
              <CloseRoundedIcon />
            </IconButton>
          }
        />
      )}

      <Button
        onClick={handleContinue}
        sx={(theme) => ({
          border: `2px solid ${theme.vars.palette.primary[500]}`,
          display: 'block',
          width: '100%',
        })}
        disabled={waiting || loading || otp.length !== 6}
        variant="outlined"
      >
        {waiting ? <CircularProgress variant="solid" color="primary" size="sm" /> : 'Continue'}
      </Button>
    </AuthLayout>
  );
};

export default Verification;
