import { Box, Stack, Typography } from '@mui/joy';
import { TabSwitcherProps } from '../../types/interfaces';
import { Colors } from '../../theme';
import { useCallback, useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import useMobileMode from '../../hooks/useMobileMode';

const TabSwitcher = ({ tabs, activeTabIndex, onTabClick }: TabSwitcherProps) => {
  const tabContainerRef = useRef<HTMLDivElement>(null);

  const [selectedTabBarWidth, setSelectedTabBarWidth] = useState<string>(`10px`);

  const handleTabClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, nextTabIndex: number) => {
      onTabClick(nextTabIndex);
    },
    [onTabClick],
  );

  const mobileMode = useMobileMode();

  useEffect(() => {
    function handleResize() {
      if (tabContainerRef.current) {
        setSelectedTabBarWidth(`${tabContainerRef.current.offsetWidth / tabs.length}px`);
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileMode]);

  return (
    <Stack
      ref={tabContainerRef}
      sx={{
        backgroundColor: Colors.GrayLight,
        borderRadius: '16px',
        height: '48px',
        marginBottom: '20px',
        width: '100%',
      }}
    >
      <motion.div
        initial={{
          x: 0,
        }}
        animate={{
          x: (parseInt(selectedTabBarWidth) + 1) * activeTabIndex,
        }}
        transition={{ duration: 0.2 }}
      >
        <Box
          sx={{
            borderRadius: '16px',
            height: '48px',
            width: selectedTabBarWidth,
            backgroundColor: Colors.Green,
            position: 'absolute',
          }}
        />
      </motion.div>

      <Stack
        direction={'row'}
        sx={{
          borderRadius: '16px',
          height: '48px',
          display: 'flex',
          zIndex: 1,
          width: '100%',
        }}
      >
        {tabs.map((tab, index) => (
          <Box
            key={tab}
            onClick={(event) => handleTabClick(event, index)}
            sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <Typography
              sx={{
                pointerEvents: 'none',
                color: activeTabIndex === index ? Colors.White : Colors.Green,
                fontSize: '15px',
                lineHeight: '22px',
                fontWeight: 700,
              }}
            >
              {tab}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default TabSwitcher;
