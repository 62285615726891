import { Box, Button, FormHelperText, Stack } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { UploadIcon } from '../../../../assets/svg';
import UploadButton from '../../../../components/UploadButton';
import { rectangleStyle, reUploadBtn } from '../../../../config/styleConstants';
import { FlexAlign } from '../../../../theme';

const FeaturedImage = () => {
  const [preview, setPreview] = useState<string>();
  const { getValues, setValue, watch, formState } = useFormContext();
  const { featuredImage } = getValues();

  useEffect(() => {
    let objectUrl = '';
    if (featuredImage) {
      objectUrl = URL.createObjectURL(featuredImage);
      setPreview(objectUrl);
    }
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [featuredImage]);

  watch();

  return (
    <>
      {preview ? (
        <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
          <img
            src={preview as string}
            width={rectangleStyle.width}
            height={rectangleStyle.height}
            style={{ ...rectangleStyle, objectFit: 'cover', objectPosition: 'top' }}
            alt="featured"
          />
          <Button
            sx={{ ...reUploadBtn }}
            onClick={() => {
              setPreview('');
              setValue('featuredImage', undefined, { shouldValidate: true });
            }}
          >
            Re-upload
          </Button>
        </Stack>
      ) : (
        <Box sx={{ width: '100%', ...FlexAlign.Center }}>
          <UploadButton
            variant="rectangle"
            name="featuredImage"
            buttonText="Upload file"
            containerStyles={{ m: 0 }}
            icon={<UploadIcon />}
            formKey="featuredImage"
          />
        </Box>
      )}

      <Stack justifyContent="center" alignItems="center">
        <FormHelperText
          sx={{
            color: 'var(--joy-palette-error-main)',
            mt: 2,
            justifyContent: 'circle',
          }}
        >
          {formState?.errors?.featuredImage?.message
            ? `${formState?.errors?.featuredImage?.message}`
            : ''}
        </FormHelperText>
      </Stack>
    </>
  );
};

export default FeaturedImage;
