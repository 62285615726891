import { Box } from '@mui/joy';
import { Button } from '@mui/material';

import { FlexAlign } from '../../theme';
import { LoadMoreButtonProps } from '../../types/interfaces';

const LoadMoreButton = ({ next, onClick, loading }: LoadMoreButtonProps) => {
  if (next === null || next === undefined) return null;

  return (
    <Box
      sx={{
        marginTop: '20px',
        marginBottom: '20px',
        width: '100%',
        ...FlexAlign.Center,
      }}
    >
      <Button
        variant="outlined"
        onClick={onClick}
        sx={{
          textTransform: 'none',
        }}
      >
        {loading ? 'Loading...' : 'Load more'}
      </Button>
    </Box>
  );
};

export default LoadMoreButton;
