import jwt from 'jwt-decode';

const isTokenValid = () => {
  const token = window.localStorage.getItem('token');
  if (token === null) return false;

  const decodedToken = jwt<{ exp: number }>(token as string);
  if (!decodedToken) return false;

  const currentTime = Math.round(new Date().getTime() / 1000);
  const tokenExpired = decodedToken.exp < currentTime;

  if (tokenExpired) {
    window.localStorage.removeItem('token');
    return false;
  }

  return true;
};

export default isTokenValid;
