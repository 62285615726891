import { Box, Radio, Stack } from '@mui/joy';
import { City, GigLocationFilterBodyProps } from '../../types/interfaces';
import GigFilterBodyHeader from '../GigFilterBodyHeader';
import SubmitButton from '../SubmitButton';
import { MenuItem, Select, TextField } from '@mui/material';
import { Colors } from '../../theme';
import { useEffect, useState } from 'react';
import { getCities } from '../../api/cities';
import errorReporting from '../../utils/errorReporting';

const GigLocationFilterBody = ({
  filterName,
  filterContent,
  setFilterContent,
  onBack,
  onReset,
  containerStyle,
}: GigLocationFilterBodyProps) => {
  const [tmpFilterContent, setTmpFilterContent] = useState(filterContent);
  const [availableCities, setAvailableCities] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getCities();
        setAvailableCities(res?.data?.cities.map((city: City) => city.name));
      } catch (error) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });
      }
    })();
  }, []);

  useEffect(() => {
    setTmpFilterContent(filterContent);
  }, [filterContent]);

  return (
    <Stack sx={{ ...styles.container, ...containerStyle }}>
      <GigFilterBodyHeader
        filterName={filterName}
        onBack={onBack}
        onReset={() => {
          onReset();
          onBack();
        }}
      />

      <Box
        sx={styles.radioButtonContainer}
        onClick={() => {
          setTmpFilterContent((prevState) => ({ ...prevState, mode: 'city' }));
        }}
      >
        <Radio
          value="city"
          label="By City"
          checked={tmpFilterContent.mode === 'city'}
          sx={styles.radioText}
        />
      </Box>

      <Box sx={styles.inputPlaceholderContainer}>
        {tmpFilterContent.mode === 'city' && (
          <Box sx={styles.cityInputContainer}>
            <Select
              placeholder="Select the city"
              sx={styles.cityInput}
              value={tmpFilterContent.city ? tmpFilterContent.city : '---'}
              variant="standard"
              onChange={(event) => {
                event.stopPropagation();
                const selectedCity = event.target.value;

                setTmpFilterContent((prevState) => ({
                  ...prevState,
                  city: selectedCity === '' ? null : selectedCity,
                }));
              }}
              label="City"
            >
              <MenuItem value={'---'}>---</MenuItem>
              {availableCities.map((city) => (
                <MenuItem data-skip-outside-click-callback value={city} key={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>

      <Box
        sx={styles.radioButtonContainer}
        onClick={() => {
          setTmpFilterContent((prevState) => ({ ...prevState, mode: 'zipcode' }));
        }}
      >
        <Radio
          value="zipCode"
          label="By ZipCode"
          checked={tmpFilterContent.mode === 'zipcode'}
          sx={styles.radioText}
        />
      </Box>

      <Box sx={styles.inputPlaceholderContainer}>
        {tmpFilterContent.mode === 'zipcode' && (
          <Box sx={styles.zipCodeInputContainer}>
            <TextField
              type="text"
              placeholder="Enter zipcode"
              variant="standard"
              sx={styles.zipCodeInput}
              InputProps={{ style: styles.zipCodeInputText }}
              value={tmpFilterContent.zipCode}
              onChange={(event) => {
                setTmpFilterContent((prevState) => ({
                  ...prevState,
                  zipCode: event.target.value,
                }));
              }}
            />
          </Box>
        )}
      </Box>

      <SubmitButton
        label="Apply"
        onClick={() => {
          setFilterContent(tmpFilterContent);
          onBack();
        }}
        isLoading={false}
      />
    </Stack>
  );
};

export default GigLocationFilterBody;

const styles = {
  zipCodeInputText: {
    fontSize: '16px',
    lineHeight: '18px',
    weight: 400,
    color: Colors.AlmostBlack,
    fontFamily: 'Montserrat, sans-serif',
  },
  radioText: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    fontWeight: 300,
  },
  zipCodeInput: {
    width: '100%',
    marginTop: '4px',
    marginBottom: '4px',
  },
  cityInput: {
    width: '100%',
    marginTop: '8px',
    marginBottom: '8px',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    lineHeight: '18px',
    weight: 400,
    color: Colors.AlmostBlack,
  },
  zipCodeInputContainer: {
    width: '100%',
    minHeight: '20px',
  },
  cityInputContainer: {
    width: '100%',
    minHeight: '20px',
  },
  inputPlaceholderContainer: {
    width: '100%',
    minHeight: '24px',
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  radioButtonContainer: {
    marginTop: '8px',
    marginBottom: '8px',
  },
};
