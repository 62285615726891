import { Box, Button, Stack, Typography } from '@mui/joy';
import { useCallback, useState } from 'react';
import HorizontalSpacer from '../../../../../components/HorizontalSpacer';
import { Colors, FlexAlign, FontSizes } from '../../../../../theme';
import {
  GigApplication,
  GigApplicationStatus,
  User,
  AdminGigBaseItem,
  ToastMode,
} from '../../../../../types/interfaces';
import { adminEditApplication } from '../../../../../api/gigs';
import triggerToast from '../../../../../utils/triggerToast';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import analytics from '../../../../../utils/analytics';
import errorReporting from '../../../../../utils/errorReporting';

const EditButton = ({
  onClick,
  label,
}: {
  onClick: () => void;
  label: 'Accept' | 'Decline' | 'Make Pending';
}) => {
  return (
    <Button
      onClick={onClick}
      sx={{ backgroundColor: Colors.Blue, borderRadius: '8px', marginRight: '10px' }}
    >
      {label}
    </Button>
  );
};

const ApplicationItem = ({
  workerId,
  firstName,
  lastName,
  profileURL,
  status,
  gigId,
  upToDateGig,
  setUpToDateGig,
}: {
  workerId: User['id'];
  firstName: User['firstName'];
  lastName: User['lastName'];
  profileURL: User['profileURL'];
  status: GigApplication['status'];
  gigId: GigApplication['gigId'];
  upToDateGig: AdminGigBaseItem;
  setUpToDateGig: React.Dispatch<React.SetStateAction<AdminGigBaseItem>>;
}) => {
  const [upToDateStatus, setUpToDateStatus] = useState(status);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [onDialogProceedCallback, setOnDialogProceedCallback] = useState<() => void>(() => null);
  const resetOnDialogProceedCallback = () => setOnDialogProceedCallback(() => null);

  const handleCloseDialog = () => {
    resetOnDialogProceedCallback();
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const editApplication = useCallback(
    async ({ status }: { status: GigApplicationStatus }) => {
      try {
        const res = await adminEditApplication({ status, gigId, workerId });

        setUpToDateStatus(res.data.applicationStatus);
        setUpToDateGig(() => ({
          ...upToDateGig,
          status: res.data.gigStatus,
          applications: res.data.applications,
        }));

        return res.data.status;
      } catch (error: any) {
        console.error(error);

        errorReporting.captureException(error, {
          level: 'error',
        });

        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while editing this application. Please retry or contact support.',
        });
      }
    },
    [gigId, setUpToDateGig, upToDateGig, workerId],
  );

  let statusColor;

  if (upToDateStatus === GigApplicationStatus.Accepted) statusColor = '#90ee00';
  if (upToDateStatus === GigApplicationStatus.Declined) statusColor = '#ff4a44';
  if (upToDateStatus === GigApplicationStatus.Pending) statusColor = '#F7A913';

  const MakePending = () => (
    <EditButton
      onClick={() => {
        handleOpenDialog();

        setOnDialogProceedCallback(() => () => {
          editApplication({ status: GigApplicationStatus.Pending });
        });

        analytics.track('Admin - Gig Application Made Pending');
      }}
      label={'Make Pending'}
    />
  );

  const Accept = () => (
    <EditButton
      onClick={() => {
        handleOpenDialog();

        setOnDialogProceedCallback(() => () => {
          editApplication({ status: GigApplicationStatus.Accepted });
        });

        analytics.track('Admin - Gig Application Accepted');
      }}
      label={'Accept'}
    />
  );

  const Decline = () => (
    <EditButton
      onClick={() => {
        handleOpenDialog();

        setOnDialogProceedCallback(() => () => {
          editApplication({ status: GigApplicationStatus.Declined });
        });

        analytics.track('Admin - Gig Application Declined');
      }}
      label={'Decline'}
    />
  );

  return (
    <Stack
      direction="row"
      sx={{
        ...FlexAlign.JustifyStartAlignCenter,
        borderTop: `1px solid ${Colors.GrayDark}`,
        width: '100%',
      }}
    >
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm status change'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to confirm the status change?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={() => {
              onDialogProceedCallback();
              handleCloseDialog();
            }}
            autoFocus
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <img
        src={profileURL}
        alt={`${firstName} ${lastName}`}
        style={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          objectFit: 'cover',
          marginRight: '12px',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      />
      <Typography sx={{ ...FontSizes.Header2W500 }}>{`${firstName} ${lastName}`}</Typography>
      <HorizontalSpacer />
      <Box
        sx={{
          backgroundColor: statusColor,
          color: 'white',
          borderRadius: '8px',
          margin: '8px',
        }}
      >
        <Typography
          sx={{
            color: 'white',
            margin: '4px',
          }}
        >
          {upToDateStatus}
        </Typography>
      </Box>
      {upToDateStatus === GigApplicationStatus.Pending && (
        <>
          <Accept />
          <Decline />
        </>
      )}

      {upToDateStatus === GigApplicationStatus.Accepted && (
        <>
          <MakePending />
          <Decline />
        </>
      )}

      {upToDateStatus === GigApplicationStatus.Declined && (
        <>
          <MakePending />
          <Accept />
        </>
      )}
    </Stack>
  );
};

export default ApplicationItem;
