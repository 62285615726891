import React from 'react';
import { Radio, Typography } from '@mui/joy';
import { subTitle } from '..';
import RadioGroupController from '../../../components/RadioGroupController';

const SmartPhone = () => {
  return (
    <>
      <Typography mt={3} textColor="#6A6A68" {...subTitle}>
        We have a few questions to help us serve you better
      </Typography>
      <Typography mt={3} fontSize={28} lineHeight="40px" fontWeight="bold">
        Do you have <br /> a smartphone with a<br /> working camera?
      </Typography>
      <RadioGroupController name="hasWorkingCamera" sx={{ mt: 3 }}>
        <Radio value="true" label="Yes" />
        <Radio value="false" label="No" />
      </RadioGroupController>
    </>
  );
};

export default SmartPhone;
