import React from 'react';
import { Button, Stack } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { TextLogo } from '../assets/svg';
import useAnalyticsPageView from '../hooks/useAnalyticsPageView';

const Home = () => {
  useAnalyticsPageView('Start');
  const navigation = useNavigate();

  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.primary[500],
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <Stack
        sx={{
          maxWidth: 345,
        }}
      >
        <TextLogo />
        <Stack mt={7} gap={2.5}>
          <Button
            variant="solid"
            onClick={() => navigation('signup')}
            sx={(theme) => ({
              backgroundColor: theme.vars.palette.common.white,
              color: theme.vars.palette.common.black,
              '&:hover': {
                backgroundColor: theme.vars.palette.common.white,
                color: theme.vars.palette.common.black,
              },
            })}
          >
            Sign Up
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigation('login')}
            sx={(theme) => ({
              backgroundColor: 'transparent',
              color: theme.vars.palette.common.white,
              border: `2px solid ${theme.vars.palette.common.white}`,
              '&:hover': {
                backgroundColor: theme.vars.palette.common.white,
                color: theme.vars.palette.common.black,
                border: `none`,
              },
            })}
          >
            Login
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Home;
