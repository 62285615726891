import { Box, Stack } from '@mui/joy';
import ProfileMySkillsComponent from '../../../../../../components/ProfileMySkills';
import useAnalyticsPageView from '../../../../../../hooks/useAnalyticsPageView';
import useMobileMode from '../../../../../../hooks/useMobileMode';
import { Colors } from '../../../../../../theme';
import { ProfileMenuProps } from '../../../../../../types/interfaces';

const ProfileMySkills = ({ label }: ProfileMenuProps) => {
  useAnalyticsPageView('Profile Tab - My Skills Tab');

  const mobileMode = useMobileMode();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: mobileMode ? '104px' : '0px',
      }}
    >
      <Stack
        direction="column"
        sx={{
          backgroundColor: Colors.GrayLight,
          width: '100%',
        }}
      >
        <ProfileMySkillsComponent />
      </Stack>
    </Box>
  );
};

export default ProfileMySkills;
