import { Box } from '@mui/joy';
import { Colors } from '../../theme';

const BottomLine = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '0.5px',
        backgroundColor: Colors.GrayDark,
      }}
    />
  );
};

export default BottomLine;
